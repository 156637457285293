import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import BlankLayout from '../flynk.app.web.core.components/layouts/BlankLayout'
import HeaderContainer from '../containers/HeaderContainer'
import FooterContainer from '../containers/FooterContainer'
import { Theme } from '../constants'

const Wrapper = styled.div`
  min-height: 100vh;
  background-color: ${Theme.Colors.Light};
  position: relative;
  display: flex;
  flex-direction: column;
`

const ContentWrapper = styled.div`
  flex-grow: 1;
`

const MaintenanceLayout = ({ children }) => (
  <BlankLayout>
    <Wrapper>
      <HeaderContainer title="Maintenance" showCityDropdown={false} />
      <ContentWrapper>
        {children}
      </ContentWrapper>
      <FooterContainer hasStickyNav={false} />
    </Wrapper>
  </BlankLayout>
)

MaintenanceLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
}

MaintenanceLayout.defaultProps = {
  children: null,
}

export default MaintenanceLayout
