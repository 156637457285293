import React from 'react'
import { Redirect, Route, withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { PropTypes as MobxPropTypes } from 'mobx-react'
import DocTitle from '../components/DocTitle'

const PrivateRoute = ({
  component: Component,
  docTitle,
  isAccessDenied,
  isAuthenticated,
  layout: Layout,
  accessDeniedPage: AccessDeniedPage,
  location: { pathname },
  ...rest
}) => {
  if (isAuthenticated) {
    if (isAccessDenied) {
      return (
        <DocTitle title="403 - Permission Denied">
          <AccessDeniedPage />
        </DocTitle>
      )
    }

    return (
      <Route
        {...rest}
        render={props => (
          <DocTitle title={docTitle}>
            <Layout>
              <Component {...props} />
            </Layout>
          </DocTitle>
        )}
      />
    )
  }

  return (
    <Redirect
      exact
      to={{
        pathname: '/login',
        state: {
          from: {
            pathname,
          },
        },
      }}
    />
  )
}

PrivateRoute.propTypes = {
  component: MobxPropTypes.objectOrObservableObject.isRequired,
  docTitle: PropTypes.string.isRequired,
  isAccessDenied: PropTypes.bool.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  layout: MobxPropTypes.objectOrObservableObject.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
  accessDeniedPage: PropTypes.func.isRequired,
}

export default withRouter(PrivateRoute)
