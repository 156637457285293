import { CURRENCY_CODES, CURRENCY_SYMBOLS } from '../constants'

export const getCurrencySymbolByCode = (code) => {
  const foundEntry = Object.entries(CURRENCY_CODES).find(entry => entry[1] === code)

  if (foundEntry) {
    const [key] = foundEntry

    return CURRENCY_SYMBOLS[key]
  }
  return undefined
}
