export function isEmptyObject(obj) {
  return obj && Object.keys(obj).length === 0 && obj.constructor === Object
}

export function getRandomId() {
  return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
}

export function convertToByteArray(input) {
  const sliceSize = 512
  const bytes = []

  for (let offset = 0; offset < input.length; offset += sliceSize) {
    const slice = input.slice(offset, offset + sliceSize)

    const byteNumbers = new Array(slice.length)

    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i)
    }

    const byteArray = new Uint8Array(byteNumbers)

    bytes.push(byteArray)
  }

  return bytes
}
