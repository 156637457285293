import { action, computed, observable, runInAction } from 'mobx'
import { persist } from 'mobx-persist'
import get from 'lodash/get'
import moment from 'moment'
import { Modal, notification } from 'antd'
import resettableMixin from 'flynk.app.web.core.data/stores/resettableMixin'
import {
  ALERT_TIMER,
  DEFAULT_PAGINATION,
  RESPONSE_TYPES,
  USER_STATUSES,
} from 'flynk.app.web.core.data/constants'
import { getNationalNumber } from 'flynk.app.web.core.components/helpers'
import { getAllStatuses, getCustomerRowBookingStatus, getPayloadForAddingBooking, getProspectStatuses, getRequestForUpdatingBooking } from '../../helpers/booking'
import { formatName } from '../../helpers/user'
import { BookingStatus, CeremonyMinHourByDistance, InitialDistance, InitialHourBooking, MOMENT_DATE_FORMAT } from '../../constants'
import { formatCitiesByRoleStatus } from '../../helpers/common'

const BookingStatuses = getAllStatuses()
const [prospectRowBookingStatusIds, customerRowBookingStatusIds] =
  [getProspectStatuses(), getCustomerRowBookingStatus()]
    .map(statuses => statuses.map(status => status.id))

@resettableMixin
class ProspectStore {
  @persist('list') @observable prospects = []
  @persist('list') @observable selectedProspects = []
  @persist('list') @observable salesManagers = []
  @persist('list') @observable tempSalesManagers = []
  @observable pagination = DEFAULT_PAGINATION
  @observable getProspectsLoading = false
  @observable getBookingsByPersonIdLoading = false
  @observable deleteProspectLoading = false
  @observable updateBookingLoading = false
  @observable updateInvoiceLoading = false
  @observable assignModalVisible = false
  @observable salesManagerModalVisible = false
  @observable updateActiveStatusesModalVisible = false
  @observable assignSalesManagerLoading = false
  @observable replaceSalesManagerLoading = false
  @observable updateActiveStatusLoading = false
  @persist('object') @observable booking = {}
  @observable getBookingByIdLoading = false
  @persist('list') @observable eventGroups = []
  @persist('list') @observable packages = []
  @persist('list') @observable ceremonyPerformances = []
  @persist('list') @observable receptionPerformances = []
  @persist('list') @observable performers = []

  @computed get selectedProspect() {
    let selected = {}
    this.prospects.forEach((prospect) => {
      const bookings = get(prospect, 'bookings', [])
      if (bookings && bookings.length > 0) {
        const booking = bookings.find(b => b.id === this.booking.id)
        if (booking) {
          selected = prospect
        }
      }
    })

    return selected
  }

  @computed get selectedProspectIds() {
    return this.selectedProspects.map(prospect => prospect.id)
  }

  @computed get expandedProspectIds() {
    return this.prospects
      .filter(prospect => prospect.expanded)
      .map(prospect => prospect.id)
  }

  @computed get activeSalesManagers() {
    return this.salesManagers.filter(saleManager => saleManager.active)
  }

  @computed get selectedSalesManager() {
    return this.activeSalesManagers.find(saleManager => saleManager.selected) || {}
  }

  @computed get selectedOldSalesManager() {
    return this.salesManagers.find(saleManager => saleManager.isOld) || {}
  }

  @computed get selectedNewSalesManager() {
    return this.activeSalesManagers.find(saleManager => saleManager.isNew) || {}
  }

  @computed get diffAllSalesManagers() {
    const diffs = []
    this.tempSalesManagers.forEach((salesManager, index) => {
      if (salesManager.active !== this.salesManagers[index].active) {
        diffs.push(salesManager)
      }
    })

    return diffs
  }

  @computed get isBookingManagementAllowed() {
    return !!((this.rootStore.profileStore.isAdmin) ||
      (this.rootStore.profileStore.isSalesManager &&
        this.rootStore.profileStore.userId === this.booking?.customer?.assingeeUserId)
    )
  }

  constructor(args) {
    this.rootStore = args.rootStore
    this.rootAPI = args.rootAPI
  }

  // Prospects
  @action.bound
  async getProspects(data = {
    skip: 0,
    take: this.pagination.pageSize,
  }) {
    if (this.rootStore.cityStore.selectedCityIds.length > 0) {
      this.getProspectsLoading = true

      try {
        const res = await this.rootAPI.prospectAPI.getProspects({
          ...data,
          cityIds: JSON.stringify(this.rootStore.cityStore.selectedCityIds),
          status: JSON.stringify(prospectRowBookingStatusIds),
        })

        if (res.payload && res.payload.items) {
          runInAction(() => {
            this.prospects = res.payload.items.map(item => ({
              ...item,
              bookings: item.bookings || [],
              expanded: false,
            }))
            this.setPagination({
              ...this.pagination,
              current: res.payload.page,
              pageSize: res.payload.pageSize,
              total: res.payload.totalItems,
            })
          })
        }
      } catch (err) {
        this.rootStore.errorsStore.addError(err)
      } finally {
        this.getProspectsLoading = false
      }
    }
  }

  @observable isAddProspectModalOpened = false;
  @action.bound
  async toggleAddProspectModalOpened() {
    this.isAddProspectModalOpened = !this.isAddProspectModalOpened
  }

  @observable isCreateBookingModalOpened = false;
  @action.bound
  toggleCreateBookingModalOpened() {
    this.isCreateBookingModalOpened = !this.isCreateBookingModalOpened
  }

  @observable isUpdateBookingModalOpened = false;
  @action.bound
  toggleUpdateBookingModalOpened() {
    this.isUpdateBookingModalOpened = !this.isUpdateBookingModalOpened
  }

  @observable addProspectLoading = false;

  @action.bound
  async addProspect(data,
    successMessage = {
      message: 'Success!',
      description: 'Prospect has been created successfully!',
      placement: 'bottomRight',
    }) {
    this.addProspectLoading = true
    let res = null
    try {
      const storeData = {
        selectedCityId: this.rootStore.cityStore.selectedCityId,
        ceremonyTypeId: this.rootStore.bookingAdminStore.ceremonyTypeId,
        receptionTypeId: this.rootStore.bookingAdminStore.receptionTypeId,
      }

      const requestData = getPayloadForAddingBooking(data, storeData)

      res = await this.rootAPI.bookingAPI.createBookingAdmin(requestData)
      if (res) {
        notification.success(successMessage)
      }
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    } finally {
      this.addProspectLoading = false
    }

    return res
  }

  @action.bound
  setExpandStatus = (id, expanded) => {
    this.prospects = this.prospects.map(prospect => ({
      ...prospect,
      expanded: prospect.id === id ? expanded : prospect.expanded,
    }))
  }

  @action.bound
  setPagination = (pagination) => {
    this.pagination = pagination
  }

  @action.bound
  clearProspects() {
    this.prospects = []
  }

  @action.bound
  async getBookingsByPersonId(id) {
    this.getBookingsByPersonIdLoading = true

    try {
      const res = await this.rootAPI.prospectAPI.getBookingsByPersonId(id, {
        status: JSON.stringify(customerRowBookingStatusIds),
        cityId: this.rootStore.cityStore.selectedCityId,
      })

      runInAction(() => {
        this.prospects = this.prospects.map((prospect) => {
          if (prospect.id === id) {
            return {
              ...prospect,
              bookings: res.payload,
            }
          }

          return prospect
        })
      })
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    } finally {
      this.getBookingsByPersonIdLoading = false
    }
  }

  @action.bound
  async getBookingWithPlaceholderStatusByPersonId(id) {
    const response = await this.rootAPI.prospectAPI.getBookingsByPersonId(id, {
      status: JSON.stringify([BookingStatus.PlaceHolder.id]),
      cityId: this.rootStore.cityStore.selectedCityId,
    })

    return (response?.payload || [])
      .find(booking => booking.status === BookingStatus.PlaceHolder.id)
  }

  @action.bound
  async createBooking(formValues, customerRequest) {
    let request = { ...formValues, ...customerRequest }
    const { id: customerId } = customerRequest
    const placeHolderBooking = await this.getBookingWithPlaceholderStatusByPersonId(customerId)
    // To restrict trash in database because of placeholder booking.
    // When we add new booking, we will update with available placeholder booking
    if (placeHolderBooking) {
      request = getRequestForUpdatingBooking(formValues, placeHolderBooking)
      await this.updateBooking(
        request,
        {
          invoice: false,
          availability: false,
          reloadBooking: false,
          reloadBookingList: false,
        },
        {
          type: 'notification',
          success: 'Booking has been created successfully!',
          fail: '',
        },
        placeHolderBooking,
      )
      return this.rootAPI.bookingAdminAPI.getBookingById(placeHolderBooking.id)
    }

    return this.addProspect(request, {
      message: 'Success!',
      description: 'Booking has been created successfully!',
      placement: 'bottomRight',
    })
  }

  @action.bound
  async getBookingById(id) {
    this.getBookingByIdLoading = true

    try {
      const res = await this.rootAPI.bookingAdminAPI.getBookingById(id)

      runInAction(() => {
        this.booking = res.payload
      })
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    } finally {
      this.getBookingByIdLoading = false
    }
  }

  @action.bound
  async updateBooking(
    data, options = {
      invoice: false,
      availability: false,
      reloadBooking: true,
      reloadBookingList: true,
    }, message = {
      type: 'alert',
      success: 'The booking has been updated successfully!',
      fail: '',
    },
    bookingData,
    force = false,
  ) {
    let success = false
    this.updateBookingLoading = true
    let sendData = { ...data }
    if (data.eventDateInfo && data.eventDateInfo.eventDate) {
      sendData = {
        ...data,
        eventDateInfo: {
          ...data.eventDateInfo,
          eventDate: `${moment(data.eventDateInfo.eventDate)
            .format(MOMENT_DATE_FORMAT)}`,
        },
      }
    }

    const booking = bookingData || this.booking

    try {
      const res = await this.rootAPI.bookingAdminAPI.updateBooking(
        booking.id,
        sendData,
        force,
      )

      if (res.type === RESPONSE_TYPES.WARNING) {
        Modal.confirm({
          title: res.message,
          okText: 'Yes',
          okType: 'danger',
          cancelText: 'No',
          onCancel: async () => {
            runInAction(() => {
              this.booking = booking
            })
          },
          onOk: async () => {
            await this.updateBooking(data, options, message, bookingData, true)
          },
        })
        return
      }

      if (res) {
        const tasks = []
        if (sendData?.customerInfos?.[0]) {
          const { email, mobile, mobileRegion, name } = sendData.customerInfos[0]
          this.selectedProspect.email = email
          this.selectedProspect.mobile = getNationalNumber(mobile, mobileRegion)?.replace(/\D/g, '') ||
            this.selectedProspect.mobile
          this.selectedProspect.name = name

          this.prospects = this.prospects.map((item) => {
            if (item.id === this.selectedProspect.id) {
              return this.selectedProspect
            }
            return item
          })
        }
        if (options.reloadBookingList) {
          tasks.push(this.getBookingsByPersonId(this.selectedProspect.id))
        }

        if (options.reloadBooking) {
          tasks.push(this.getBookingById(booking.id))
        }

        await Promise.all(tasks)

        const msg = message.success
        if (message.type === 'notification') {
          notification.success({
            message: 'Success!',
            description: msg,
            placement: 'bottomRight',
          })
        } else {
          this.rootStore.alertStore.success({
            title: msg,
            timer: ALERT_TIMER,
          })
        }
        success = true
      }
    } catch (err) {
      const error = message && message.fail ? { description: message.fail } : err
      this.rootStore.errorsStore.addError(error)
    } finally {
      this.updateBookingLoading = false
    }

    return success
  }

  @action.bound
  async updateBookingStatus(booking, status, prospect) {
    let isSuccess = false

    const oldBookingStatus = BookingStatuses.find(s => s.id === Number(booking.status)) || {}
    const newBookingStatus = BookingStatuses.find(s => s.id === Number(status)) || {}
    try {
      const res = await this.rootAPI.bookingAdminAPI.updateBookingStatus(booking.id, status)

      if (res) {
        if (
          prospect &&
          prospect.id &&
          prospect.bookings &&
          prospect.bookings.length > 0
        ) {
          const selectedProspect = { ...prospect }
          selectedProspect.bookings = selectedProspect.bookings
            .map(b => ({
              ...b,
              status: b.id === booking.id ? Number(status) : b.status,
            }))
            .filter(b => !((b.id === booking.id &&
              !prospectRowBookingStatusIds.includes(Number(status)))))

          this.prospects = this.prospects
            .map(p => (p.id === selectedProspect.id ? selectedProspect : p))
            .filter(p =>
              !(p.id === selectedProspect.id && selectedProspect.bookings.length === 0))
        } else if (this.selectedProspect.id) {
          this.prospects = this.prospects
            .map((p) => {
              if (p.id === this.selectedProspect.id) {
                const bookings = p.bookings
                  .map(b => ({
                    ...b,
                    status: b.id === booking.id ? Number(status) : b.status,
                  }))
                  .filter(b =>
                    !((b.id === booking.id &&
                      !prospectRowBookingStatusIds.includes(Number(status)))))

                return {
                  ...p,
                  bookings,
                }
              }

              return p
            })
            .filter(p =>
              !(p.id === this.selectedProspect.id && p.bookings.length === 0))
        }

        if (this.booking.id === booking.id) {
          if (prospectRowBookingStatusIds.includes(Number(status))) {
            this.setBooking({
              ...this.booking,
              status: Number(status),
            })
          } else {
            this.clearBooking()
          }
        }

        isSuccess = true
        notification.success({
          message: 'Success!',
          description: `Updated booking status from ${oldBookingStatus.name} to ${newBookingStatus.name}.`,
          placement: 'bottomRight',
        })

        if (this.prospects.length === 0) {
          const { current, pageSize } = this.pagination
          const take = pageSize
          const skip = (current - 1) * pageSize
          await this.getProspects({
            skip,
            take,
          })
        }
      }
    } catch (err) {
      notification.error({
        message: 'Failed!',
        description: `${(err && err.description) || 'Update status failed. Please try again.'}`,
        placement: 'bottomRight',
      })
    }

    return { isSuccess }
  }

  @action.bound
  setBooking(booking) {
    this.booking = booking
  }

  @action.bound
  clearBooking() {
    this.booking = {}
  }

  // Reassign & replace sales manager
  @action.bound
  selectProspects(prospects) {
    this.selectedProspects = prospects
  }

  @action.bound
  clearSelectedProspects() {
    this.selectedProspects = []
  }

  @action.bound
  async getSalesManagersByCityId() {
    const { selectedCityId } = this.rootStore.cityStore

    if (selectedCityId) {
      try {
        const res = await this.rootAPI.prospectAPI
          .getSalesManagersByCityId(
            selectedCityId,
            USER_STATUSES.Disabled,
          )

        if (res && res.payload) {
          runInAction(() => {
            this.salesManagers = res.payload.map((salesManager) => {
              const status = get(salesManager, 'roles[0].status', USER_STATUSES.Error)
              return {
                ...salesManager.user,
                status,
                active: status === USER_STATUSES.Enabled,
              }
            })
          })
        }
      } catch (err) {
        this.rootStore.errorsStore.addError(err)
      }
    }
  }

  @action.bound
  selectSalesManager = (id) => {
    this.salesManagers = this.salesManagers.map(salesManager => ({
      ...salesManager,
      selected: salesManager.id === id,
    }))
  }

  @action.bound
  deselectSalesManager = () => {
    this.salesManagers = this.salesManagers.map(salesManager => ({
      ...salesManager,
      selected: false,
    }))
  }

  @action.bound
  selectOldSalesManager = (id) => {
    this.salesManagers = this.salesManagers.map(salesManager => ({
      ...salesManager,
      isOld: salesManager.id === id,
    }))
  }

  @action.bound
  selectNewSalesManager = (id) => {
    this.salesManagers = this.salesManagers.map(salesManager => ({
      ...salesManager,
      isNew: salesManager.id === id,
    }))
  }

  @action.bound
  deselectOldNewSalesManager = () => {
    this.salesManagers = this.salesManagers.map(salesManager => ({
      ...salesManager,
      isOld: false,
      isNew: false,
    }))
  }

  @action.bound
  async assignSalesManager() {
    this.assignSalesManagerLoading = true

    try {
      if (this.selectedProspectIds.length > 0) {
        const res = await this.rootAPI.prospectAPI.assignSalesManager(
          this.selectedSalesManager.id,
          { customerIds: this.selectedProspectIds },
        )

        if (res) {
          runInAction(() => {
            this.rootStore.alertStore.success({
              title: 'The sales manager has been assigned successfully!',
              timer: ALERT_TIMER,
            })
            this.prospects = this.prospects.map(prospect => ({
              ...prospect,
              expanded: false,
            }))
            this.selectedProspects = []
            this.deselectSalesManager()
            this.clearBooking()
            this.toggleAssignModal({ visible: false })
          })
        }
      }
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    } finally {
      this.assignSalesManagerLoading = false
    }
  }

  @action.bound
  async replaceSalesManager() {
    this.replaceSalesManagerLoading = true

    try {
      const res = await this.rootAPI.prospectAPI.replaceSalesManager(
        this.selectedOldSalesManager.id,
        this.selectedNewSalesManager.id,
      )

      if (res) {
        runInAction(() => {
          this.rootStore.alertStore.success({
            title: 'Sales managers have been replaced successfully!',
            timer: ALERT_TIMER,
          })
          this.prospects = this.prospects.map(prospect => ({
            ...prospect,
            expanded: false,
          }))
          this.selectedProspects = []
          this.deselectSalesManager()
          this.clearBooking()
          this.toggleSalesManagerModal({ visible: false })
        })
      }
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    } finally {
      this.replaceSalesManagerLoading = false
    }
  }

  @action.bound
  toggleAssignModal = ({ visible }) => {
    this.assignModalVisible = visible
    if (this.selectedSalesManager.id) {
      this.deselectSalesManager()
    }
  }

  @action.bound
  toggleSalesManagerModal = ({ visible }) => {
    this.salesManagerModalVisible = visible
    if (this.selectedOldSalesManager.id || this.selectedNewSalesManager.id) {
      this.deselectOldNewSalesManager()
    }
  }

  @action.bound
  async assignSalesManagerForBookingDetail(salesManager, customer) {
    this.assignSalesManagerForBookingDetailLoading = true

    try {
      if (customer.id) {
        const res = await this.rootAPI.prospectAPI.assignSalesManager(
          salesManager.id,
          { customerIds: [customer.id] },
        )

        if (res) {
          await this.getBookingById(this.booking.id)
          notification.success({
            message: 'Success!',
            description: `Assigned customer "${formatName(customer)}" for sales manager "${formatName(salesManager)}"!`,
            placement: 'bottomRight',
          })
        }
      }
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    } finally {
      this.assignSalesManagerForBookingDetailLoading = false
    }
  }

  // Update Active Statuses
  @action.bound
  toggleUpdateActiveStatusesModal = ({ visible }) => {
    this.updateActiveStatusesModalVisible = visible
    if (this.rootStore.profileStore.isSalesManager) {
      const { currentRole } = this.rootStore.profileStore
      const {
        cities,
        setSaleManagerRoleStatusCities,
      } = this.rootStore.cityStore
      setSaleManagerRoleStatusCities(formatCitiesByRoleStatus(currentRole, cities))
    } else {
      this.tempSalesManagers = [...this.salesManagers]
    }
  }

  @action.bound
  updateTempActiveStatus = (id, active) => {
    const {
      setSaleManagerRoleStatusCities,
      saleManagerRoleStatusCities,
    } = this.rootStore.cityStore
    if (this.rootStore.profileStore.isSalesManager) {
      const cities = saleManagerRoleStatusCities.map(city => ({
        ...city,
        isRoleActive: city.id === id ? active : city.isRoleActive,
      }))
      setSaleManagerRoleStatusCities(cities)
    } else {
      this.tempSalesManagers = this.tempSalesManagers.map(salesManager => ({
        ...salesManager,
        active: salesManager.id === id ? active : salesManager.active,
      }))
    }
  }

  @action.bound
  async updateActiveStatus() {
    this.updateActiveStatusLoading = true

    try {
      let promises
      const {
        cityStore,
        profileStore,
      } = this.rootStore
      const { saleManagerRoleStatusCitiesToUpdate } = cityStore
      const {
        profile,
      } = profileStore

      if (saleManagerRoleStatusCitiesToUpdate.length > 0) {
        promises = saleManagerRoleStatusCitiesToUpdate.reduce((sum, city) => {
          const cityId = cityStore.selectedCityId
          const { isRoleActive } = city
          const { user: { id: userId } } = profile
          const orgId = profileStore.currentOrganisationId
          const data = {
            cityId,
            userId,
            orgId,
          }

          if (isRoleActive) {
            sum.push(this.rootAPI.prospectAPI.enableSalesManager(data))
          } else {
            sum.push(this.rootAPI.prospectAPI.disableSalesManager(data))
          }
          return sum
        }, [])
      } else {
        promises = this.diffAllSalesManagers.reduce((sum, salesManager) => {
          const { selectedCityId: cityId } = cityStore
          const { id: userId } = salesManager
          const { currentOrganisationId: orgId } = profileStore

          const data = {
            cityId,
            userId,
            orgId,
          }
          if (salesManager.active) {
            sum.push(this.rootAPI.prospectAPI.enableSalesManager(data))
          } else {
            sum.push(this.rootAPI.prospectAPI.disableSalesManager(data))
          }
          return sum
        }, [])
      }

      if (promises.length > 0) {
        await Promise.all(promises)
        if (saleManagerRoleStatusCitiesToUpdate.length > 0) {
          await profileStore.getContext()
        } else {
          await this.getSalesManagersByCityId()
        }

        runInAction(() => {
          this.rootStore.alertStore.success({
            title: 'The active status has been updated successfully!',
            timer: ALERT_TIMER,
          })
          if (profileStore.isSalesManager) {
            const { saleManagerRoleStatusCities } = cityStore
            if (saleManagerRoleStatusCities.length > 0) {
              cityStore.setCities(saleManagerRoleStatusCities)
            }
          } else {
            this.salesManagers = [...this.tempSalesManagers]
          }
          this.toggleUpdateActiveStatusesModal({ visible: false })
        })
      }
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    } finally {
      this.updateActiveStatusLoading = false
    }
  }

  // Packages
  @action.bound
  async getPackages() {
    try {
      const eventGroupRes = await this.rootAPI.bookingAPI.getEventGroups({
        cityId: this.rootStore.cityStore.selectedCityId,
      })

      if (eventGroupRes && eventGroupRes.payload) {
        this.eventGroups = eventGroupRes.payload.sort((a, b) => a.order - b.order)

        const res = await this.rootAPI.bookingAPI.getAvailablePackages({
          cityId: this.rootStore.cityStore.selectedCityId,
        })

        if (res && res.payload) {
          this.packages = res.payload.map(pack => ({
            ...pack,
            group: this.eventGroups.find(group => group.id === pack.groupId) || { name: '' },
          }))
        }
      }
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    }
  }

  @action.bound
  async getPerformanceTypes() {
    try {
      if (this.performanceTypes.length === 0) {
        const performanceTypesResponse = await this.rootAPI.performanceAPI
          .getPerformanceTypes()

        if (performanceTypesResponse && performanceTypesResponse.payload) {
          this.performanceTypes = performanceTypesResponse.payload
        }
      }
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    }
  }

  @observable getPerformancesLoading = false
  @action.bound
  async getPerformances() {
    this.getPerformancesLoading = true
    try {
      const promises = [
        this.rootAPI.bookingAPI.getPerformances({
          performanceTypeId: this.rootStore.bookingAdminStore.ceremonyTypeId,
          cityId: this.rootStore.cityStore.selectedCityId,
          duration: 1,
        }, []),
        this.rootAPI.bookingAPI.getPerformances({
          performanceTypeId: this.rootStore.bookingAdminStore.receptionTypeId,
          cityId: this.rootStore.cityStore.selectedCityId,
          duration: 5,
        }, []),
      ]

      const [ceremonyRes, receptionRes] = await Promise.all(promises)

      runInAction(() => {
        this.ceremonyPerformances = ceremonyRes.payload
        this.receptionPerformances = receptionRes.payload
      })
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    } finally {
      this.getPerformancesLoading = false
    }
  }

  // Performers
  @action.bound
  async getPerformers() {
    try {
      const res = await this.rootAPI.bookingAdminAPI
        .getPerformers({
          bookingId: this.booking.id,
          skip: 0,
          take: 100,
        })
      if (res && res.payload && res.payload.items && res.payload.items.length >= 0) {
        const sortedRes = res.payload.items.sort((a, b) => a.order - b.order)

        this.performers = [
          {
            id: 'choose-for-me',
            photo: '/images/choose-for-me.jpg',
            subject: {
              firstname: 'Choose For Me',
            },
          },
          ...sortedRes,
        ]
      }
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    }
  }

  @action.bound
  async getPerformersByBookingId(id = this.booking.id) {
    this.getPerformersByBookingIdLoading = true
    try {
      const res = await this.rootAPI.performerAPI.getPerformersByBookingId(id)

      if (res && res.payload) {
        this.bookingPerformers = res.payload
      }
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    } finally {
      this.getPerformersByBookingIdLoading = false
    }
  }

  // Performers
  @observable performersWithoutBookingId = []
  @observable getPerformersWithoutBookingIdLoading = false
  @action.bound
  async getPerformersWithoutBookingId() {
    this.getPerformersWithoutBookingIdLoading = true
    try {
      const res = await this.rootAPI.bookingAPI
        .getPerformers({
          cityId: this.rootStore.cityStore.selectedCityId,
        })
      if (res?.payload?.length) {
        const sortedRes = res.payload.sort((a, b) => a.order - b.order)
        this.performersWithoutBookingId = sortedRes
      }
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    } finally {
      this.getPerformersWithoutBookingIdLoading = false
    }
  }

  @action.bound
  clearBookingPerformers() {
    this.bookingPerformers = []
  }

  // Helpers
  @action.bound
  getCeremonyVenue(venues) {
    const ceremonyVenue = venues.find(venue => venue.performanceTypeId === this.ceremonyTypeId)
    return ceremonyVenue || venues[0]
  }

  @action.bound
  getReceptionVenue(venues) {
    const receptionVenue = venues.find(venue => venue.performanceTypeId === this.receptionTypeId)
    return receptionVenue || venues[1]
  }

  @action.bound
  getFirstVenue(venues) {
    const ceremonyVenue = this.getCeremonyVenue(venues)
    const receptionVenue = this.getReceptionVenue(venues)

    if (ceremonyVenue.formattedAddress) {
      return ceremonyVenue
    }

    if (receptionVenue.formattedAddress) {
      return receptionVenue
    }

    return {}
  }

  @action.bound
  getLastVenue(venues) {
    const ceremonyVenue = this.getCeremonyVenue(venues)
    const receptionVenue = this.getReceptionVenue(venues)

    if (receptionVenue.formattedAddress) {
      return receptionVenue
    }

    if (ceremonyVenue.formattedAddress) {
      return ceremonyVenue
    }

    return {}
  }

  @observable ceremonyBookingMinHour = InitialHourBooking.ceremony.min
  @action.bound
  checkCeremonyBookingMinDuration() {
    try {
      this.ceremonyBookingMinHour = CeremonyMinHourByDistance.findLast(item =>
        this.ceremonyBookingDistance > item.minDistance).minHour
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    }
  }

  @action.bound
  resetCeremonyBookingMinDuration() {
    this.ceremonyBookingMinHour = InitialHourBooking.ceremony.min
  }

  @observable ceremonyBookingDistance = InitialDistance
  @action.bound
  setCeremonyBookingDistance(distance) {
    this.ceremonyBookingDistance = distance
  }

  @observable calculateCeremonyBookingAddressDistanceLoading = false
  @action.bound
  async calculateCeremonyBookingAddressDistance(location) {
    this.calculateCeremonyBookingAddressDistanceLoading = true
    try {
      if (location) {
        const { payload } = await this.rootAPI.venuesAPI.getVenuesDistance(
          this.rootStore.cityStore.selectedCityId,
          location,
        )

        if (payload) {
          this.ceremonyBookingDistance = payload
        }
      }
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    } finally {
      this.calculateCeremonyBookingAddressDistanceLoading = false
    }
  }

  @observable receptionBookingMinHour = InitialHourBooking.reception.min
  @action.bound
  resetReceptionBookingMinDuration() {
    this.receptionBookingMinHour = InitialHourBooking.reception.min
  }

  @action.bound
  clearBookingDistanceData() {
    this.setCeremonyBookingDistance(InitialDistance)
    this.resetCeremonyBookingMinDuration()
    this.resetReceptionBookingMinDuration()
  }
}

export default ProspectStore
