import { observable, action, runInAction } from 'mobx'
import { persist } from 'mobx-persist'
import resettableMixin from '../resettableMixin'

@resettableMixin
class ClientAppShortlistStore {
  @persist('list') @observable clientApps = []
  @persist('list') @observable shortlistClientApps = []
  @persist('object') @observable clientApp = {}

  constructor(args) {
    this.rootStore = args.rootStore
    this.rootAPI = args.rootAPI
  }

  @action.bound
  async getShortlistClientApps() {
    try {
      const res = await this.rootAPI.clientAppShortlistAPI.getClientAppShortlists()
      runInAction(() => {
        this.shortlistClientApps = res.payload
      })
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    }
  }

  @action.bound
  clearShortlistClientApps() {
    this.shortlistClientApps = []
  }

  @action
  async addShortlistClientApp(data) {
    try {
      await this.rootAPI.clientAppShortlistAPI.addClientAppShortlist(data)
      await this.getShortlistClientApps()
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    }
  }

  @action.bound
  async deleteShortlistClientApp(id) {
    try {
      await this.rootAPI.clientAppShortlistAPI.deleteClientAppShortlist(id)
      const index = this.shortlistClientApps.findIndex(item => item.id === id)
      if (index >= 0) {
        runInAction(() => {
          this.shortlistClientApps.splice(index, 1)
        })
      }
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    }
  }
}

export default ClientAppShortlistStore
