export const CURRENCY_CODES = {
  Undefined: 0, // Not part of ISO 4217. Represents an undefined/uninitiated state
  XXX: 999,  // No currency
  AED: 784,  //United Arab Emirates dirham
  AFN: 971,  //Afghan afghani
  ALL: 8,  //Albanian lek
  AMD: 51,  //Armenian dram
  ANG: 532,  //Netherlands Antillean guilder
  AOA: 973,  //Angolan kwanza
  ARS: 32,  //Argentine peso
  AUD: 36,  //Australian dollar
  AWG: 533,  //Aruban florin
  AZN: 944,  //Azerbaijani manat
  BAM: 977,  //Bosnia and Herzegovina convertible mark
  BBD: 52,  //Barbados dollar
  BDT: 50,  //Bangladeshi taka
  BGN: 975,  //Bulgarian lev
  BHD: 48,  //Bahraini dinar
  BIF: 108,  //Burundian franc
  BMD: 60,  //Bermudian dollar
  BND: 96,  //Brunei dollar
  BOB: 68,  //Boliviano
  BOV: 984,  //Bolivian Mvdol (funds code)
  BRL: 986,  //Brazilian real
  BSD: 44,  //Bahamian dollar
  BTN: 64,  //Bhutanese ngultrum
  BWP: 72,  //Botswana pula
  BYN: 933,  //Belarusian ruble
  BZD: 84,  //Belize dollar
  CAD: 124,  //Canadian dollar
  CDF: 976,  //Congolese franc
  CHE: 947,  //WIR euro (complementary currency)
  CHF: 756,  //Swiss franc
  CHW: 948,  //WIR franc (complementary currency)
  CLF: 990,  //Unidad de Fomento (funds code)
  CLP: 152,  //Chilean peso
  CNY: 156,  //Chinese yuan[8]
  COP: 170,  //Colombian peso
  COU: 970,  //Unidad de Valor Real (UVR) (funds code)[9]
  CRC: 188,  //Costa Rican colon
  CUC: 931,  //Cuban convertible peso
  CUP: 192,  //Cuban peso
  CVE: 132,  //Cape Verdean escudo
  CZK: 203,  //Czech koruna
  DJF: 262,  //Djiboutian franc
  DKK: 208,  //Danish krone
  DOP: 214,  //Dominican peso
  DZD: 12,  //Algerian dinar
  EGP: 818,  //Egyptian pound
  ERN: 232,  //Eritrean nakfa
  ETB: 230,  //Ethiopian birr
  EUR: 978,  //Euro
  FJD: 242,  //Fiji dollar
  FKP: 238,  //Falkland Islands pound
  GBP: 826,  //Pound sterling
  GEL: 981,  //Georgian lari
  GHS: 936,  //Ghanaian cedi
  GIP: 292,  //Gibraltar pound
  GMD: 270,  //Gambian dalasi
  GNF: 324,  //Guinean franc
  GTQ: 320,  //Guatemalan quetzal
  GYD: 328,  //Guyanese dollar
  HKD: 344,  //Hong Kong dollar
  HNL: 340,  //Honduran lempira
  HRK: 191,  //Croatian kuna
  HTG: 332,  //Haitian gourde
  HUF: 348,  //Hungarian forint
  IDR: 360,  //Indonesian rupiah
  ILS: 376,  //Israeli new shekel
  INR: 356,  //Indian rupee
  IQD: 368,  //Iraqi dinar
  IRR: 364,  //Iranian rial
  ISK: 352,  //Icelandic króna
  JMD: 388,  //Jamaican dollar
  JOD: 400,  //Jordanian dinar
  JPY: 392,  //Japanese yen
  KES: 404,  //Kenyan shilling
  KGS: 417,  //Kyrgyzstani som
  KHR: 116,  //Cambodian riel
  KMF: 174,  //Comoro franc
  KPW: 408,  //North Korean won
  KRW: 410,  //South Korean won
  KWD: 414,  //Kuwaiti dinar
  KYD: 136,  //Cayman Islands dollar
  KZT: 398,  //Kazakhstani tenge
  LAK: 418,  //Lao kip
  LBP: 422,  //Lebanese pound
  LKR: 144,  //Sri Lankan rupee
  LRD: 430,  //Liberian dollar
  LSL: 426,  //Lesotho loti
  LYD: 434,  //Libyan dinar
  MAD: 504,  //Moroccan dirham
  MDL: 498,  //Moldovan leu
  MGA: 969,  //Malagasy ariary
  MKD: 807,  //Macedonian denar
  MMK: 104,  //Myanmar kyat
  MNT: 496,  //Mongolian tögrög
  MOP: 446,  //Macanese pataca
  MRU: 929,  //Mauritanian ouguiya
  MUR: 480,  //Mauritian rupee
  MVR: 462,  //Maldivian rufiyaa
  MWK: 454,  //Malawian kwacha
  MXN: 484,  //Mexican peso
  MXV: 979,  //Mexican Unidad de Inversion (UDI) (funds code)
  MYR: 458,  //Malaysian ringgit
  MZN: 943,  //Mozambican metical
  NAD: 516,  //Namibian dollar
  NGN: 566,  //Nigerian naira
  NIO: 558,  //Nicaraguan córdoba
  NOK: 578,  //Norwegian krone
  NPR: 524,  //Nepalese rupee
  NZD: 554,  //New Zealand dollar
  OMR: 512,  //Omani rial
  PAB: 590,  //Panamanian balboa
  PEN: 604,  //Peruvian sol
  PGK: 598,  //Papua New Guinean kina
  PHP: 608,  //Philippine peso[12]
  PKR: 586,  //Pakistani rupee
  PLN: 985,  //Polish złoty
  PYG: 600,  //Paraguayan guaraní
  QAR: 634,  //Qatari riyal
  RON: 946,  //Romanian leu
  RSD: 941,  //Serbian dinar
  RUB: 643,  //Russian ruble
  RWF: 646,  //Rwandan franc
  SAR: 682,  //Saudi riyal
  SBD: 90,  //Solomon Islands dollar
  SCR: 690,  //Seychelles rupee
  SDG: 938,  //Sudanese pound
  SEK: 752,  //Swedish krona/kronor
  SGD: 702,  //Singapore dollar
  SHP: 654,  //Saint Helena pound
  SLL: 694,  //Sierra Leonean leone
  SOS: 706,  //Somali shilling
  SRD: 968,  //Surinamese dollar
  SSP: 728,  //South Sudanese pound
  STN: 930,  //São Tomé and Príncipe dobra
  SVC: 222,  //Salvadoran colón
  SYP: 760,  //Syrian pound
  SZL: 748,  //Swazi lilangeni
  THB: 764,  //Thai baht
  TJS: 972,  //Tajikistani somoni
  TMT: 934,  //Turkmenistan manat
  TND: 788,  //Tunisian dinar
  TOP: 776,  //Tongan paʻanga
  TRY: 949,  //Turkish lira
  TTD: 780,  //Trinidad and Tobago dollar
  TWD: 901,  //New Taiwan dollar
  TZS: 834,  //Tanzanian shilling
  UAH: 980,  //Ukrainian hryvnia
  UGX: 800,  //Ugandan shilling
  USD: 840,  //United States dollar
  USN: 997,  //United States dollar (next day) (funds code)
  UYI: 940,  //Uruguay Peso en Unidades Indexadas (URUIURUI) (funds code)
  UYU: 858,  //Uruguayan peso
  UYW: 927,  //Unidad previsional[14]
  UZS: 860,  //Uzbekistan som
  VES: 928,  //Venezuelan bolívar soberano[12]
  VND: 704,  //Vietnamese đồng
  VUV: 548,  //Vanuatu vatu
  WST: 882,  //Samoan tala
  XAF: 950,  //CFA franc BEAC
  XAG: 961,  //Silver (one troy ounce)
  XAU: 959,  //Gold (one troy ounce)
  XBA: 955,  //European Composite Unit (EURCO) (bond market unit)
  XBB: 956,  //European Monetary Unit (E.M.U.-6) (bond market unit)
  XBC: 957,  //European Unit of Account 9 (E.U.A.-9) (bond market unit)
  XBD: 958,  //European Unit of Account 17 (E.U.A.-17) (bond market unit)
  XCD: 951,  //East Caribbean dollar
  XDR: 960,  //Special drawing rights
  XOF: 952,  //CFA franc BCEAO
  XPD: 964,  //Palladium (one troy ounce)
  XPF: 953,  //CFP franc (franc Pacifique)
  XPT: 962,  //Platinum (one troy ounce)
  XSU: 994,  //SUCRE
  XTS: 963,  //Code reserved for testing
  XUA: 965,  //ADB Unit of Account
  YER: 886,  //Yemeni rial
  ZAR: 710,  //South African rand
  ZMW: 967,  //Zambian kwacha
  ZWL: 932,	//Zimbabwean dollar
}

export const CURRENCY_SYMBOLS = {
  AED: 'د.إ.‏',
  AFN: '؋',
  ALL: 'Lekë',
  AMD: '֏',
  ANG: 'NAf.',
  AOA: 'Kz',
  ARS: '$',
  AUD: '$',
  AWG: 'Afl.',
  AZN: '₼',
  BAM: 'КМ',
  BBD: '$',
  BDT: '৳',
  BGN: 'лв.',
  BHD: 'د.ب.‏',
  BIF: 'FBu',
  BMD: '$',
  BND: '$',
  BOB: 'Bs',
  BOV: 'BOV',
  BRL: 'R$',
  BSD: '$',
  BTN: 'Nu.',
  BWP: 'P',
  BYN: 'Br',
  BZD: '$',
  CAD: '$',
  CDF: 'FC',
  CHE: 'CHE',
  CHF: 'CHF',
  CHW: 'CHW',
  CLF: 'CLF',
  CLP: '$',
  CNY: '¥',
  COP: '$',
  COU: 'COU',
  CRC: '₡',
  CUC: 'CUC',
  CUP: '$',
  CVE: '​',
  CZK: 'Kč',
  DJF: 'Fdj',
  DKK: 'kr.',
  DOP: 'RD$',
  DZD: 'د.ج.‏',
  EGP: 'ج.م.‏',
  ERN: 'Nfk',
  ETB: 'ብር',
  EUR: '€',
  FJD: '$',
  FKP: '£',
  GBP: '£',
  GEL: '₾',
  GHS: 'GH₵',
  GIP: '£',
  GMD: 'D',
  GNF: 'FG',
  GTQ: 'Q',
  GYD: '$',
  HKD: 'HK$',
  HNL: 'L',
  HRK: 'HRK',
  HTG: 'G',
  HUF: 'Ft',
  IDR: 'Rp',
  ILS: '₪',
  INR: '₹',
  IQD: 'د.ع.‏',
  IRR: 'IRR',
  ISK: 'ISK',
  JMD: '$',
  JOD: 'د.أ.‏',
  JPY: '￥',
  KES: 'Ksh',
  KGS: 'сом',
  KHR: '៛',
  KMF: 'CF',
  KPW: 'KPW',
  KRW: '₩',
  KWD: 'د.ك.‏',
  KYD: '$',
  KZT: '₸',
  LAK: '₭',
  LBP: 'ل.ل.‏',
  LKR: 'රු.',
  LRD: '$',
  LSL: 'LSL',
  LYD: 'د.ل.‏',
  MAD: 'د.م.‏',
  MDL: 'L',
  MGA: 'Ar',
  MKD: 'ден',
  MMK: 'K',
  MNT: '₮',
  MOP: 'MOP$',
  // MRU: undefined,
  MUR: 'Rs',
  // MVR: undefined,
  MWK: 'MK',
  MXN: '$',
  MXV: 'MXV',
  MYR: 'RM',
  MZN: 'MTn',
  NAD: '$',
  NGN: '₦',
  NIO: 'C$',
  NOK: 'kr',
  NPR: 'नेरू',
  NZD: '$',
  OMR: 'ر.ع.‏',
  PAB: 'B\\/.',
  PEN: 'S\\/',
  PGK: 'K',
  PHP: '₱',
  PKR: 'Rs',
  PLN: 'zł',
  PYG: 'Gs.',
  QAR: 'ر.ق.‏',
  RON: 'RON',
  RSD: 'RSD',
  RUB: '₽',
  RWF: 'RF',
  SAR: 'ر.س.‏',
  SBD: '$',
  SCR: 'SR',
  SDG: 'ج.س.',
  SEK: 'kr',
  SGD: '$',
  SHP: '£',
  SLL: 'Le',
  SOS: 'S',
  SRD: '$',
  SSP: '£',
  STN: 'STN',
  // SVC: undefined,
  SYP: 'ل.س.‏',
  SZL: 'E',
  THB: 'THB',
  TJS: 'сом.',
  // TMT: undefined,
  TND: 'د.ت.‏',
  TOP: 'T$',
  TRY: '₺',
  TTD: '$',
  TWD: '$',
  TZS: 'TSh',
  UAH: '₴',
  UGX: 'USh',
  USD: '$',
  USN: 'USN',
  UYI: 'UYI',
  UYU: '$',
  // UYW: undefined,
  UZS: 'сўм',
  // VES: undefined,
  VND: '₫',
  VUV: 'VT',
  WST: 'WS$',
  XAF: 'FCFA',
  // XAG: undefined,
  // XAU: undefined,
  // XBA: undefined,
  // XBB: undefined,
  // XBC: undefined,
  // XBD: undefined,
  XCD: '$',
  // XDR: undefined,
  XOF: 'CFA',
  // XPD: undefined,
  XPF: 'FCFP',
  // XPT: undefined,
  // XSU: undefined,
  // XTS: undefined,
  // XUA: undefined,
  // XXX: undefined,
  YER: 'ر.ي.‏',
  ZAR: 'R',
  ZMW: 'K',
  ZWL: 'ZWL$',
}

export const CURRENCY_CODES_DISPLAY = {
  8: 'ALL',
  12: 'DZD',
  32: 'ARS',
  36: 'AUD',
  44: 'BSD',
  48: 'BHD',
  50: 'BDT',
  51: 'AMD',
  52: 'BBD',
  60: 'BMD',
  64: 'BTN',
  68: 'BOB',
  72: 'BWP',
  84: 'BZD',
  90: 'SBD',
  96: 'BND',
  104: 'MMK',
  108: 'BIF',
  116: 'KHR',
  124: 'CAD',
  132: 'CVE',
  136: 'KYD',
  144: 'LKR',
  152: 'CLP',
  156: 'CNY',
  170: 'COP',
  174: 'KMF',
  188: 'CRC',
  191: 'HRK',
  192: 'CUP',
  203: 'CZK',
  208: 'DKK',
  214: 'DOP',
  222: 'SVC',
  230: 'ETB',
  232: 'ERN',
  238: 'FKP',
  242: 'FJD',
  262: 'DJF',
  270: 'GMD',
  292: 'GIP',
  320: 'GTQ',
  324: 'GNF',
  328: 'GYD',
  332: 'HTG',
  340: 'HNL',
  344: 'HKD',
  348: 'HUF',
  352: 'ISK',
  356: 'INR',
  360: 'IDR',
  364: 'IRR',
  368: 'IQD',
  376: 'ILS',
  388: 'JMD',
  392: 'JPY',
  398: 'KZT',
  400: 'JOD',
  404: 'KES',
  408: 'KPW',
  410: 'KRW',
  414: 'KWD',
  417: 'KGS',
  418: 'LAK',
  422: 'LBP',
  426: 'LSL',
  430: 'LRD',
  434: 'LYD',
  446: 'MOP',
  454: 'MWK',
  458: 'MYR',
  462: 'MVR',
  480: 'MUR',
  484: 'MXN',
  496: 'MNT',
  498: 'MDL',
  504: 'MAD',
  512: 'OMR',
  516: 'NAD',
  524: 'NPR',
  532: 'ANG',
  533: 'AWG',
  548: 'VUV',
  554: 'NZD',
  558: 'NIO',
  566: 'NGN',
  578: 'NOK',
  586: 'PKR',
  590: 'PAB',
  598: 'PGK',
  600: 'PYG',
  604: 'PEN',
  608: 'PHP',
  634: 'QAR',
  643: 'RUB',
  646: 'RWF',
  654: 'SHP',
  682: 'SAR',
  690: 'SCR',
  694: 'SLL',
  702: 'SGD',
  704: 'VND',
  706: 'SOS',
  710: 'ZAR',
  728: 'SSP',
  748: 'SZL',
  752: 'SEK',
  756: 'CHF',
  760: 'SYP',
  764: 'THB',
  776: 'TOP',
  780: 'TTD',
  784: 'AED',
  788: 'TND',
  800: 'UGX',
  807: 'MKD',
  818: 'EGP',
  826: 'GBP',
  834: 'TZS',
  840: 'USD',
  858: 'UYU',
  860: 'UZS',
  882: 'WST',
  886: 'YER',
  901: 'TWD',
  927: 'UYW',
  928: 'VES',
  929: 'MRU',
  930: 'STN',
  931: 'CUC',
  932: 'ZWL',
  933: 'BYN',
  934: 'TMT',
  936: 'GHS',
  938: 'SDG',
  940: 'UYI',
  941: 'RSD',
  943: 'MZN',
  944: 'AZN',
  946: 'RON',
  947: 'CHE',
  948: 'CHW',
  949: 'TRY',
  950: 'XAF',
  951: 'XCD',
  952: 'XOF',
  953: 'XPF',
  955: 'XBA',
  956: 'XBB',
  957: 'XBC',
  958: 'XBD',
  959: 'XAU',
  960: 'XDR',
  961: 'XAG',
  962: 'XPT',
  963: 'XTS',
  964: 'XPD',
  965: 'XUA',
  967: 'ZMW',
  968: 'SRD',
  969: 'MGA',
  970: 'COU',
  971: 'AFN',
  972: 'TJS',
  973: 'AOA',
  975: 'BGN',
  976: 'CDF',
  977: 'BAM',
  978: 'EUR',
  979: 'MXV',
  980: 'UAH',
  981: 'GEL',
  984: 'BOV',
  985: 'PLN',
  986: 'BRL',
  990: 'CLF',
  994: 'XSU',
  997: 'USN',
  999: 'XXX',
}

export const CURRENCY_SYMBOLS_FULL = {
  'AED': 'د.إ.‏',
  'AFN': '؋',
  'ALL': 'Lekë',
  'AMD': '֏',
  'ANG': 'NAf.',
  'AOA': 'Kz',
  'ARS': '$',
  'AUD': '$',
  'AWG': 'Afl.',
  'AZN': '₼',
  'BAM': 'КМ',
  'BBD': '$',
  'BDT': '৳',
  'BGN': 'лв.',
  'BHD': 'د.ب.‏',
  'BIF': 'FBu',
  'BMD': '$',
  'BND': '$',
  'BOB': 'Bs',
  'BOV': 'BOV',
  'BRL': 'R$',
  'BSD': '$',
  'BTN': 'Nu.',
  'BWP': 'P',
  'BYN': 'Br',
  'BZD': '$',
  'CAD': '$',
  'CDF': 'FC',
  'CHE': 'CHE',
  'CHF': 'CHF',
  'CHW': 'CHW',
  'CLF': 'CLF',
  'CLP': '$',
  'CNY': '¥',
  'COP': '$',
  'COU': 'COU',
  'CRC': '₡',
  'CUC': 'CUC',
  'CUP': '$',
  'CVE': '​',
  'CZK': 'Kč',
  'DJF': 'Fdj',
  'DKK': 'kr.',
  'DOP': 'RD$',
  'DZD': 'د.ج.‏',
  'EGP': 'ج.م.‏',
  'ERN': 'Nfk',
  'ETB': 'ብር',
  'EUR': '€',
  'FJD': '$',
  'FKP': '£',
  'GBP': '£',
  'GEL': '₾',
  'GHS': 'GH₵',
  'GIP': '£',
  'GMD': 'D',
  'GNF': 'FG',
  'GTQ': 'Q',
  'GYD': '$',
  'HKD': 'HK$',
  'HNL': 'L',
  'HRK': 'HRK',
  'HTG': 'G',
  'HUF': 'Ft',
  'IDR': 'Rp',
  'ILS': '₪',
  'INR': '₹',
  'IQD': 'د.ع.‏',
  'IRR': 'IRR',
  'ISK': 'ISK',
  'JMD': '$',
  'JOD': 'د.أ.‏',
  'JPY': '￥',
  'KES': 'Ksh',
  'KGS': 'сом',
  'KHR': '៛',
  'KMF': 'CF',
  'KPW': 'KPW',
  'KRW': '₩',
  'KWD': 'د.ك.‏',
  'KYD': '$',
  'KZT': '₸',
  'LAK': '₭',
  'LBP': 'ل.ل.‏',
  'LKR': 'රු.',
  'LRD': '$',
  'LSL': 'LSL',
  'LYD': 'د.ل.‏',
  'MAD': 'د.م.‏',
  'MDL': 'L',
  'MGA': 'Ar',
  'MKD': 'ден',
  'MMK': 'K',
  'MNT': '₮',
  'MOP': 'MOP$',
  'MUR': 'Rs',
  'MWK': 'MK',
  'MXN': '$',
  'MXV': 'MXV',
  'MYR': 'RM',
  'MZN': 'MTn',
  'NAD': '$',
  'NGN': '₦',
  'NIO': 'C$',
  'NOK': 'kr',
  'NPR': 'नेरू',
  'NZD': '$',
  'OMR': 'ر.ع.‏',
  'PAB': 'B\\/.',
  'PEN': 'S\\/',
  'PGK': 'K',
  'PHP': '₱',
  'PKR': 'Rs',
  'PLN': 'zł',
  'PYG': 'Gs.',
  'QAR': 'ر.ق.‏',
  'RON': 'RON',
  'RSD': 'RSD',
  'RUB': '₽',
  'RWF': 'RF',
  'SAR': 'ر.س.‏',
  'SBD': '$',
  'SCR': 'SR',
  'SDG': 'ج.س.',
  'SEK': 'kr',
  'SGD': '$',
  'SHP': '£',
  'SLL': 'Le',
  'SOS': 'S',
  'SRD': '$',
  'SSP': '£',
  'STN': 'STN',
  'SYP': 'ل.س.‏',
  'SZL': 'E',
  'THB': 'THB',
  'TJS': 'сом.',
  'TND': 'د.ت.‏',
  'TOP': 'T$',
  'TRY': '₺',
  'TTD': '$',
  'TWD': '$',
  'TZS': 'TSh',
  'UAH': '₴',
  'UGX': 'USh',
  'USD': '$',
  'USN': 'USN',
  'UYI': 'UYI',
  'UYU': '$',
  'UZS': 'сўм',
  'VND': '₫',
  'VUV': 'VT',
  'WST': 'WS$',
  'XAF': 'FCFA',
  'XCD': '$',
  'XOF': 'CFA',
  'XPF': 'FCFP',
  'YER': 'ر.ي.‏',
  'ZAR': 'R',
  'ZMW': 'K',
  'ZWL': 'ZWL$'
}
