export const TASK_PRIORITY_TYPES = {
  0: 'Normal',
  1: 'Important',
  2: 'Urgent',
  3: 'Critical',
}

export const TASK_STATUS_TYPES = {
  0: 'Undefined',

  // Open group is between 1000-1999
  1000: 'Open',
  1001: 'On Hold',
  1002: 'Blocked',

  // In progress Group is 2000 - 2999
  2000: 'In Progress',
  2001: 'Assessed',
  2002: 'Pending Approval',
  2003: 'In Review',

  // Closed group is greater than 3000
  // - means the issue no longer requires attention and will not appear in normal query results
  3000: 'Closed',
  3001: 'Completed',
  3002: 'Not Approved',
  3003: 'Cancelled',
  3004: 'Aborted',
  3005: 'Archived',
}

export const TASK_SUBJECT_TYPES = {
  0: 'Other',
  1: 'Enquiry',
  2: 'Change Lease Details',
  3: 'Maintenance Request',
  4: 'Rent Review',
  5: 'Pay Invoice',
}
