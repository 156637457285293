import axios from 'axios'
import qs from 'query-string'

export default class AuthAPI {
  constructor(args) {
    this.request = args.request
    this.rootStore = args.rootStore
  }

  login = ({ username, password }) => {
    const { configStore } = this.rootStore

    const data = qs.stringify({
      client_id: configStore.clientId,
      grant_type: configStore.grantTypePassword,
      username,
      password,
    })

    return axios
      .post('/Connect/Token', data, {
        baseURL: configStore.authEndpoint,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
  }

  register = data => this.request.post('/Users/Register', data)

  getContext = () => this.request.get('/Users/CurrentContext')

  refreshToken = (refreshToken) => {
    const { configStore } = this.rootStore

    const data = qs.stringify({
      client_id: configStore.clientId,
      grant_type: configStore.grantTypeRefreshToken,
      refresh_token: refreshToken,
    })

    return axios
      .post('/Connect/Token', data, {
        baseURL: configStore.authEndpoint,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
  }

  requestResetPassword = data =>
    this.request.post('/Users/RequestResetPassword', data)

  resetPassword = data => this.request.post('/Users/ResetPassword', data)

  requestForgottenUsername = data =>
    this.request.post('/Users/ForgotUsername', data)
}
