import { observable, action, runInAction } from 'mobx'
import { persist } from 'mobx-persist'
import { ALERT_TIMER } from '../../constants'
import resettableMixin from '../resettableMixin'

@resettableMixin
class SupplierStore {
  @persist('list') @observable suppliers = []
  @persist('object') @observable supplier = {}

  constructor(args) {
    this.rootStore = args.rootStore
    this.rootAPI = args.rootAPI
  }

  @action.bound
  async getSuppliers() {
    try {
      const res = await this.rootAPI.supplierAPI.getSuppliers()
      runInAction(() => {
        this.suppliers = res.payload
      })
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    }
  }

  @action.bound
  clearSuppliers() {
    this.suppliers = []
  }

  @action.bound
  async getSupplierById(id) {
    try {
      const res = await this.rootAPI.supplierAPI.getSupplierById(id)
      if (res.payload) {
        runInAction(() => {
          this.supplier = res.payload
        })
      }
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    }
  }

  @action.bound
  async addSupplier(data) {
    try {
      await this.rootAPI.supplierAPI.addSupplier(data)
      this.rootStore.alertStore.success({
        title: 'The supplier has been added successfully!',
        timer: ALERT_TIMER,
      })
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    }
  }

  @action.bound
  async updateSupplier(data) {
    try {
      await this.rootAPI.supplierAPI.updateSupplier(data)
      this.rootStore.alertStore.success({
        title: 'The supplier has been updated successfully!',
        timer: ALERT_TIMER,
      })
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    }
  }

  @action.bound
  clearSupplier() {
    this.supplier = {}
  }
}

export default SupplierStore
