export default class UserAPI {
  constructor(args) {
    this.request = args.request
  }

  getUsers = params => this.request.get('/Users', { params })

  getUserById = id => this.request.get(`/User/${id}`)

  addUser = data => this.request.post('/Users/Register', data)

  addNewOrgAndAdmin = data => this.request.post('/Users/RegisterNewOrgAndAdmin', data)

  updateUser = data => this.request.put('/Users', data)

  deleteUser = id => this.request.del(`/User/${id}`)

  getOnboardUser = id => this.request.get(`/User/${id}/OnboardingStatus`)

  resendValidation = linkId => this.request.get(`/Users/ResendValidation/${linkId}`)

  validateEmail = data => this.request.get('/Users/ValidateEmail', {
    params: data,
  })

  validateMobile = data => this.request.get('/Users/ValidateMobile', {
    params: data,
  })

  changePassword = data => this.request.put('/Users/ChangePassword', data)

  sendRegistrationInvite = data => this.request.post('/Users/SendRegistrationInvite', data)

  loadRoleInvite = data => this.request.get('/Users/RoleInvite', {
    params: data,
  })

  completeRegistrationInvite = data => this.request.post('/Users/CompleteRegistrationInvite', data)

  completeRoleInvite = data => this.request.post('/Users/AcceptRole', data, {
    params: {
      key: data.InviteKey,
    },
  })

  inviteUser = data => this.request.post('/Users/InviteUser', data)

  fetchInvites = (orgId, params) => this.request.get(`/Organisation/${orgId}/Invites`, { params })

  fetchRegistrationInvites = data => this.request.get('/Users/RegistrationInvites', { data })

  loadRegistrationInvite = key => this.request.get('/Users/LoadRegistrationInvite', { params: { key } })

  acceptRoleInvite = key => this.request.post(`/Users/AcceptRole?key=${key}`)

  resendUserInvite = id => this.request.post(`/Users/Roles/Invite/${id}/Resend`)

  resendUserRegistrationInvite = id => this.request.post(`/Users/Registration/Invite/${id}/Resend`)

  cancelUserInvite = id => this.request.post(`/Users/Roles/Invite/${id}/Cancel`)

  cancelUserRegistrationInvite = id => this.request.post(`/Users/Registration/Invite/${id}/Cancel`)
}
