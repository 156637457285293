import { action, observable, runInAction } from 'mobx'
import { persist } from 'mobx-persist'
import { ALERT_TIMER, DEFAULT_PAGINATION, SetDefaultPagination } from '../../constants'

import resettableMixin from '../resettableMixin'

@resettableMixin
class UserStore {
  @persist('list') @observable users = []
  @persist('object') @observable user = {}
  @observable pagination = DEFAULT_PAGINATION
  @observable getUsersLoading = false
  @observable addUserLoading = false
  @observable updateUserLoading = false
  @observable deleteUserLoading = false
  @observable validateEmailLoading = false
  @observable validateEmailError = {}
  @persist @observable emailValidated = false
  @observable validateMobileLoading = false
  @observable validateMobileError = {}
  @persist @observable mobileValidated = false
  @observable changePasswordLoading = false
  @observable sendRegistrationInviteLoading = false
  @observable userInvitationModalVisible = false
  @observable loadRegistrationInviteLoading = false
  @observable completeRegistrationInviteLoading = false
  @observable loadRoleInviteLoading = false
  @observable completeRoleInviteLoading = false
  @observable registrationInfo = {}
  @observable addNewOrgAndAdminLoading = false
  @observable inviteNewOrgAdminLoading = false
  @observable getUserByIdLoading = false

  constructor(args) {
    this.rootStore = args.rootStore
    this.rootAPI = args.rootAPI
  }

  @action.bound
  async getUsers(params = { skip: 0, take: this.pagination.pageSize }) {
    const { userAPI } = this.rootAPI
    const { errorsStore, profileStore } = this.rootStore

    this.getUsersLoading = true

    try {
      const { payload } = await userAPI.getUsers({
        ...params,
        orgId: profileStore.currentOrganisationId,
      })

      if (payload?.items) {
        runInAction(() => {
          this.users = payload.items
          this.pagination = SetDefaultPagination(this.pagination, { payload })

          const { history } = this.rootStore.routingStore
          history.push({
            search: `?skip=${params.skip}&take=${payload.pageSize}`,
          })
        })
      }
    } catch (err) {
      errorsStore.addError(err)
    } finally {
      this.getUsersLoading = false
    }
  }

  @action.bound
  setPagination = (pagination) => {
    this.pagination = pagination
  }

  @action.bound
  clearUsers() {
    this.users = []
    this.setPagination(DEFAULT_PAGINATION)
  }

  @action.bound
  async getUserById(id) {
    this.getUserByIdLoading = true

    try {
      const res = await this.rootAPI.userAPI.getUserById(id)
      if (res.payload) {
        runInAction(() => {
          this.user = res.payload
        })
      }
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    } finally {
      this.getUserByIdLoading = false
    }
  }

  @action.bound
  async addUser(data) {
    let success = false
    this.addUserLoading = true
    try {
      await this.rootAPI.userAPI.addUser(data)
      this.rootStore.alertStore.success({
        title: 'The user has been added successfully!',
        timer: ALERT_TIMER,
      })
      success = true
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    } finally {
      this.addUserLoading = false
    }

    return success
  }

  @action.bound
  async addNewOrgAndAdmin(data) {
    let success = false
    this.addNewOrgAndAdminLoading = true
    try {
      const res = await this.rootAPI.userAPI.addNewOrgAndAdmin(data)
      if (res && res.payload) {
        this.rootStore.alertStore.success({
          title: 'The user has been added successfully!',
          timer: ALERT_TIMER,
        })
        success = true
      }
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    } finally {
      this.addNewOrgAndAdminLoading = false
    }

    return success
  }

  @action.bound
  async inviteNewOrgAdmin(data) {
    let success = false
    this.inviteNewOrgAdminLoading = true
    try {
      const res = await this.rootAPI.userAPI.sendRegistrationInvite(data)
      if (res) {
        if (res.type === 0) {
          this.rootStore.errorsStore.addError({
            description: res.message,
          })
        } else {
          this.rootStore.alertStore.success({
            title: 'Invitation has been sent!',
            timer: ALERT_TIMER,
          })
          success = true
        }
      }
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    } finally {
      this.inviteNewOrgAdminLoading = false
    }

    return success
  }

  @action.bound
  async updateUser(data) {
    let success = false
    this.updateUserLoading = true
    try {
      await this.rootAPI.userAPI.updateUser(data)
      await this.getUserById(data.id)
      this.rootStore.alertStore.success({
        title: 'The user has been updated successfully!',
        timer: ALERT_TIMER,
      })
      success = true
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    } finally {
      this.updateUserLoading = false
    }

    return success
  }

  @action.bound
  async deleteUser(id) {
    this.deleteUserLoading = true
    let success = false
    try {
      await this.rootAPI.userAPI.deleteUser(id)

      runInAction(() => {
        this.users = this.users.filter(user => user.id !== id)
        this.rootStore.alertStore.success({
          title: 'The user has been deleted successfully!',
          timer: ALERT_TIMER,
        })
        success = true
      })
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    } finally {
      this.deleteUserLoading = false
    }
    return success
  }

  @action.bound
  clearUser() {
    this.user = {}
  }

  // Validation
  @action.bound
  async validateEmail(params) {
    this.validateEmailLoading = true
    try {
      await this.rootAPI.userAPI.validateEmail(params)

      runInAction(() => {
        this.emailValidated = true
      })
    } catch (err) {
      this.validateEmailError = err
    } finally {
      this.validateEmailLoading = false
    }
  }

  @action.bound
  async validateMobile(params) {
    this.validateMobileLoading = true
    try {
      await this.rootAPI.userAPI.validateMobile(params)

      runInAction(() => {
        this.mobileValidated = true
      })
    } catch (err) {
      this.validateMobileError = err
    } finally {
      this.validateMobileLoading = false
    }
  }

  // Change password
  @action.bound
  async changePassword(data, sucessCallback, failureCallback) {
    this.changePasswordLoading = true
    try {
      await this.rootAPI.userAPI.changePassword(data)
      this.rootStore.alertStore.success({
        title: 'The password has been changed successfully!',
        timer: ALERT_TIMER,
      })
      if (typeof sucessCallback === 'function') {
        sucessCallback()
      } else if (Array.isArray(sucessCallback)) {
        sucessCallback.forEach(callback => callback())
      }
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
      if (typeof failureCallback === 'function') {
        failureCallback()
      } else if (Array.isArray(failureCallback)) {
        failureCallback.forEach(callback => callback())
      }
    } finally {
      this.changePasswordLoading = false
    }
  }

  // Invite User
  @action.bound
  async sendRegistrationInvite(data) {
    let success = false
    this.sendRegistrationInviteLoading = true

    try {
      await this.rootAPI.userAPI.sendRegistrationInvite(data)

      runInAction(() => {
        this.rootStore.alertStore.success({
          title: 'Invitation has been sent!',
          timer: ALERT_TIMER,
        })
        this.toggleUserInvitationModal({ visible: false })
        success = true
      })
    } catch (err) {
      if (err.code === 1) {
        if (data.orgRoleType) {
          err.description = 'This User already has an Admin role in this organisation.'
        } else if (data.businessUnitRoleType) {
          err.description = 'This User already has a Manager role in this business unit.'
        } else if (data.storeRoleType) {
          err.description = 'This User already has a Manager role in this store.'
        } else {
          err.description = 'This User already has a Member role in this organisation.'
        }
      }
      this.rootStore.errorsStore.addError(err)
    } finally {
      this.sendRegistrationInviteLoading = false
    }

    return success
  }

  @action.bound
  async loadRegistrationInvite(data) {
    this.loadRegistrationInviteLoading = true
    try {
      const res = await this.rootAPI.userAPI.loadRegistrationInvite(data)

      if (res.payload) {
        const resRegistrationInfo = {
          ...res.payload,
          headOfficeAddress: res.payload.headOfficeAddress ?
            [
              res.payload.headOfficeAddress.line1,
              res.payload.headOfficeAddress.city,
              res.payload.headOfficeAddress.state,
              res.payload.headOfficeAddress.code,
            ]
              .filter(el => el)
              .join(', ') :
            '',
          postalAddress: res.payload.postalAddress ?
            [
              res.payload.postalAddress.line1,
              res.payload.postalAddress.city,
              res.payload.postalAddress.state,
              res.payload.postalAddress.code,
            ]
              .filter(el => el)
              .join(', ') :
            '',
          headOfficeAddressRawData: res.payload.headOfficeAddress,
          postalAddressRawData: res.payload.postalAddress,
        }

        runInAction(() => {
          this.registrationInfo = { ...resRegistrationInfo }
        })
      }
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    } finally {
      this.loadRegistrationInviteLoading = false
    }
  }

  @action.bound
  async completeRegistrationInvite(data) {
    this.completeRegistrationInviteLoading = true
    try {
      await this.rootAPI.userAPI.completeRegistrationInvite(data)
      await this.rootStore.authStore.login({
        username: data.userName,
        password: data.password,
      })
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    } finally {
      this.completeRegistrationInviteLoading = false
    }
  }

  @action.bound
  async loadRoleInvite(data) {
    this.loadRoleInviteLoading = true
    try {
      const res = await this.rootAPI.userAPI.loadRoleInvite(data)

      if (res.payload) {
        runInAction(() => {
          this.registrationInfo = res.payload
        })
      }
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    } finally {
      this.loadRoleInviteLoading = false
    }
  }

  @action.bound
  async completeRoleInvite(data) {
    this.completeRoleInviteLoading = true
    try {
      await this.rootAPI.userAPI.completeRoleInvite(data)
      this.rootStore.alertStore.success({
        title: 'Invitation accepted!',
        timer: ALERT_TIMER,
      })
      this.rootStore.routingStore.push('/login')
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    } finally {
      this.completeRoleInviteLoading = false
    }
  }

  @action.bound
  toggleUserInvitationModal({ visible }) {
    this.userInvitationModalVisible = visible
  }
}

export default UserStore
