import { useEffect, useState } from 'react'
import { BREAK_POINTS_NUMBERS } from '../constants'

export const useWindowSize = (breakPoint = BREAK_POINTS_NUMBERS.md) => {
  const [isNarrow, setIsNarrow] = useState(window.innerWidth <= breakPoint)
  const [windowInnerHeight, setWindowInnerHeight] = useState(window.innerHeight)
  const [windowInnerWidth, setWindowInnerWidth] = useState(window.innerWidth)

  useEffect(() => {
    const handleResize = () => {
      setIsNarrow(window.innerWidth <= breakPoint)
      setWindowInnerHeight(window.innerHeight)
      setWindowInnerWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return { isNarrow, windowInnerHeight, windowInnerWidth }
}

export const useForceUpdate = () => {
  const [value, setState] = useState(true)
  return () => setState(!value)
}
