import { action, observable, runInAction } from 'mobx'
import { persist } from 'mobx-persist'
import get from 'lodash/get'
import { ALERT_TIMER, DEFAULT_PAGINATION, SetDefaultPagination } from '../../constants'

import resettableMixin from '../resettableMixin'

@resettableMixin
class OrganisationStore {
  @persist('list') @observable organisations = []
  @persist('object') @observable organisation = {}
  @persist('list') @observable members = []
  @observable member = {}
  @persist('list') @observable organisationInvites = []
  @observable organisationInviteData = {}
  @persist('object') @observable pagination = DEFAULT_PAGINATION
  @persist('object') @observable membersPagination = DEFAULT_PAGINATION
  @persist('object') @observable organisationInvitesPagination = DEFAULT_PAGINATION
  @persist('object') @observable bankAccount = {}
  @observable getOrganisationsLoading = false
  @observable getOrganisationByIdLoading = false
  @observable updateDetailsOrganisationLoading = false
  @observable getOrganisationMembersByIdLoading = false
  @observable getOrganisationMemberByIdLoading = false
  @observable getBankAccountLoading = false
  @observable updateBankAccountLoading = false
  @observable sendOrganisationInviteLoading = false
  @observable organisationInvitesLoading = false
  @observable resendOrganisationInviteLoadingId = null
  @observable cancelOrganisationInviteLoadingId = null
  @observable organisationInviteDataLoading = false
  @observable acceptOrganisationInviteLoading = false
  @observable acceptOrganisationInviteAndAdminLoading = false

  constructor(args) {
    this.rootStore = args.rootStore
    this.rootAPI = args.rootAPI
  }

  @action.bound
  async getOrganisations(params = { skip: 0, take: this.pagination.pageSize }) {
    this.getOrganisationsLoading = true
    try {
      const res = await this.rootAPI.organisationAPI.getOrganisations(params)

      const { payload } = res

      if (payload && payload.items) {
        runInAction(() => {
          this.organisations = payload.items
          this.pagination = SetDefaultPagination(this.pagination, res)
        })
      }
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    } finally {
      this.getOrganisationsLoading = false
    }
  }

  @action.bound
  setPagination(pagination) {
    this.pagination = pagination
  }

  @action.bound
  clearOrganisations() {
    this.organisations = []
  }

  @action.bound
  async getOrganisationById(orgId) {
    this.getOrganisationByIdLoading = true

    try {
      const res = await this.rootAPI.organisationAPI.getOrganisationById(orgId)

      if (res && res.payload) {
        const resOrganisation = {
          ...res.payload,
          headOfficeAddress: res.payload.headOfficeAddress ? [res.payload.headOfficeAddress.line1,
            res.payload.headOfficeAddress.city,
            res.payload.headOfficeAddress.state,
            res.payload.headOfficeAddress.code]
            .filter(el => el)
            .join(', ') :
            '',
          postalAddress: res.payload.postalAddress ? [res.payload.postalAddress.line1,
            res.payload.postalAddress.city,
            res.payload.postalAddress.state,
            res.payload.postalAddress.code]
            .filter(el => el)
            .join(', ') :
            '',
          headOfficeAddressRawData: res.payload.headOfficeAddress,
          postalAddressRawData: res.payload.postalAddress,
        }
        runInAction(() => {
          this.organisation = {
            ...resOrganisation,
            logo: get(res.payload, 'logo.thumbnails[0]') || get(res.payload, 'logo.original') || {},
          }
        })
      }
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    } finally {
      this.getOrganisationByIdLoading = false
    }
  }

  @action.bound
  clearOrganisation() {
    this.organisation = {}
  }

  @action.bound
  async addOrganisation(data) {
    try {
      await this.rootAPI.organisationAPI.addOrganisation(data)
      this.rootStore.alertStore.success({
        title: 'The organisation has been added successfully!',
        timer: ALERT_TIMER,
      })
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    }
  }

  @action.bound
  async uploadLogo(data) {
    const res = await this.rootAPI.organisationAPI.getLogoPresignedUrl(this.organisation.id, data)

    if (res && res.payload) {
      const { file, contentType } = data

      await this.rootAPI.organisationAPI.uploadLogo(res.payload.signedUrl, file, contentType)
    }
  }

  @action.bound
  async updateDetailsOrganisation(data, redirectLink) {
    const { organisationAPI } = this.rootAPI
    const { errorsStore, profileStore, routingStore } = this.rootStore

    this.updateDetailsOrganisationLoading = true

    try {
      const organisation = { ...this.organisation }
      const file = data.logo
      if (file) {
        await this.uploadLogo({
          file,
          contentType: file.type,
          objectName: file.name,
        })
      }
      await organisationAPI.updateDetailsOrganisation(data)

      runInAction(() => {
        const updatedOrganisation = { ...organisation, ...data }

        this.organisation = updatedOrganisation
        this.organisations = this.organisations
          .map(org => (org.id === data.id ? updatedOrganisation : org))
        profileStore.setCurrentOrganisation(updatedOrganisation)

        this.rootStore.alertStore.success({
          title: 'The organisation has been updated successfully!',
          timer: ALERT_TIMER,
        })
      })

      routingStore.push(redirectLink || `/organisations/${profileStore.currentOrganisationId}`)
    } catch (error) {
      errorsStore.addError(error)
    } finally {
      this.updateDetailsOrganisationLoading = false
    }
  }

  @action.bound
  async getOrganisationMembersById(
    orgId,
    roleId,
    params = { skip: 0, take: this.membersPagination.pageSize },
  ) {
    this.getOrganisationMembersByIdLoading = true

    try {
      const { payload } = await this.rootAPI.organisationAPI
        .getOrganisationMembersById(orgId, roleId, params)

      if (payload?.items) {
        runInAction(() => {
          this.members = payload.items
          this.membersPagination =
            SetDefaultPagination(this.membersPagination, { payload })

          const { history } = this.rootStore.routingStore
          history.push({
            search: `?skip=${params.skip}&take=${payload.pageSize}`,
          })
        })
      }
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    } finally {
      this.getOrganisationMembersByIdLoading = false
    }
  }

  @action.bound
  clearMembers() {
    this.members = []
    this.membersPagination = DEFAULT_PAGINATION
  }

  @action.bound
  async getOrganisationMemberById(userId) {
    this.getOrganisationMemberByIdLoading = true

    try {
      const res = await this.rootAPI.organisationAPI.getOrganisationMemberById(userId)

      runInAction(() => {
        this.member = res.payload
      })
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    } finally {
      this.getOrganisationMemberByIdLoading = false
    }
  }

  @action.bound
  async deleteOrganisationMemberById(userId) {
    try {
      await this.rootAPI.organisationAPI.deleteOrganisationMemberById({
        userId,
        orgId: this.rootStore.profileStore.currentOrganisationId,
      })

      runInAction(() => {
        this.members = this.members.filter(member => member.userId !== userId)
        this.rootStore.alertStore.success({
          title: 'The user has been deleted successfully!',
          timer: ALERT_TIMER,
        })
      })
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    }
  }

  @action.bound
  clearMember() {
    this.member = {}
  }

  @action.bound
  setOrganisations(data) {
    this.organisations = data
  }

  // Bank Account
  @action.bound
  async getBankAccount(orgId) {
    this.getBankAccountLoading = true

    try {
      const res = await this.rootAPI.organisationAPI.getBankAccount(orgId)

      if (res && res.payload) {
        this.bankAccount = res.payload
      }
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    } finally {
      this.getBankAccountLoading = false
    }
  }

  @action.bound
  async updateBankAccount(orgId, data) {
    this.updateBankAccountLoading = true

    try {
      const res = await this.rootAPI.organisationAPI.updateBankAccount(orgId, data)

      if (res && res.payload) {
        this.bankAccount = { ...this.bankAccount, ...data }
        this.rootStore.alertStore.success({
          title: 'The bank account has been saved successfully!',
          timer: ALERT_TIMER,
        })
      }
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    } finally {
      this.updateBankAccountLoading = false
    }
  }

  @action.bound
  async sendOrganisationInvite(data) {
    const { organisationAPI } = this.rootAPI
    const { errorsStore, alertStore, routingStore } = this.rootStore

    this.sendOrganisationInviteLoading = true

    try {
      await organisationAPI.sendOrganisationInvite(data)

      alertStore.success({
        title: 'The invite sent successfully!',
        timer: ALERT_TIMER,
      })
      routingStore.push('/organisations')
    } catch (error) {
      errorsStore.addError(error)
    } finally {
      this.sendOrganisationInviteLoading = false
    }
  }

  @action.bound
  async getOrganisationInvites(
    params = { skip: 0, take: this.organisationInvitesPagination.pageSize },
  ) {
    const { organisationAPI } = this.rootAPI
    const { errorsStore } = this.rootStore

    this.organisationInvitesLoading = true

    try {
      const response = await organisationAPI.getOrganisationInvites(params)

      this.organisationInvites = response.payload.items
      this.organisationInvitesPagination = SetDefaultPagination(
        this.organisationInvitesPagination, response,
      )
    } catch (error) {
      errorsStore.addError(error)
    } finally {
      this.organisationInvitesLoading = false
    }
  }

  @action.bound
  async resendOrganisationInvite(id) {
    const { organisationAPI } = this.rootAPI
    const { errorsStore, alertStore } = this.rootStore

    this.resendOrganisationInviteLoadingId = id

    try {
      await organisationAPI.resendOrganisationInvite(id)

      alertStore.success({
        title: 'The invite successfully resent!',
        timer: ALERT_TIMER,
      })
    } catch (error) {
      errorsStore.addError(error)
    } finally {
      this.resendOrganisationInviteLoadingId = null
    }
  }

  @action.bound
  async cancelOrganisationInvite(id) {
    const { organisationAPI } = this.rootAPI
    const { errorsStore, alertStore } = this.rootStore

    this.cancelOrganisationInviteLoadingId = id

    try {
      await organisationAPI.cancelOrganisationInvite(id)

      alertStore.success({
        title: 'The invite successfully cancelled!',
        timer: ALERT_TIMER,
      })

      this.getOrganisationInvites()
      this.organisationInvites = this.organisationInvites.filter(org => org.id !== id)
    } catch (error) {
      errorsStore.addError(error)
    } finally {
      this.cancelOrganisationInviteLoadingId = null
    }
  }

  @action.bound
  async getOrganisationInviteData(key) {
    const { organisationAPI } = this.rootAPI
    const { errorsStore, routingStore } = this.rootStore

    this.organisationInviteDataLoading = true

    try {
      const { payload } = await organisationAPI.getOrganisationInviteData(key)

      this.organisationInviteData = payload
    } catch (error) {
      errorsStore.addError(error)
      routingStore.push('/')
    } finally {
      this.organisationInviteDataLoading = false
    }
  }

  @action.bound
  async acceptOrganisationInvite(data) {
    const { organisationAPI } = this.rootAPI
    const { errorsStore, alertStore, routingStore } = this.rootStore

    this.acceptOrganisationInviteLoading = true

    try {
      await organisationAPI.acceptOrganisationInvite(data)

      alertStore.success({
        title: 'The invite successfully accepted!',
        timer: ALERT_TIMER,
      })

      routingStore.push('/')
    } catch (error) {
      errorsStore.addError(error)
    } finally {
      this.acceptOrganisationInviteLoading = false
    }
  }

  @action.bound
  async acceptOrganisationAndAdminInvite(data) {
    const { organisationAPI } = this.rootAPI
    const { errorsStore, alertStore, authStore, routingStore } = this.rootStore

    this.acceptOrganisationInviteAndAdminLoading = true

    try {
      await organisationAPI.acceptOrganisationAndAdminInvite(data)

      authStore.login({
        username: data.username,
        password: data.password,
      })

      alertStore.success({
        title: 'The invite successfully accepted!',
        timer: ALERT_TIMER,
      })

      routingStore.push('/')
    } catch (error) {
      errorsStore.addError(error)
    } finally {
      this.acceptOrganisationInviteAndAdminLoading = false
    }
  }
}

export default OrganisationStore
