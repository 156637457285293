import { action, observable } from 'mobx'
import { persist } from 'mobx-persist'
import { notification } from 'antd'

export default class PreferencesStore {
  /* PreferenceList */

  constructor(args) {
    this.rootStore = args.rootStore
    this.rootAPI = args.rootAPI
  }

  skillMusiciansMappings = {}
  currentMusicianPreferenceId
  @observable currentSkillId
  @persist('list') @observable musicians = []
  @persist('list') @observable currentMusicianPreferences = []
  @observable musicianPreferencesLoading = false

  @action.bound
  async selectSkill(skillId) {
    if (this.currentSkillId === skillId) {
      return
    }
    await this.getMusiciansOfSkill(skillId)
    this.currentSkillId = skillId
    this.musicians = this.skillMusiciansMappings[this.currentSkillId] || []

    this.currentMusicianPreferenceId = null
    await this.getMusicianPreference()
  }

  @action.bound
  async getMusiciansOfSkill(skillId, data = {
    skip: 0,
    take: 10000,
  }) {
    if (skillId in this.skillMusiciansMappings) {
      return
    }

    this.musicianPreferencesLoading = true

    try {
      const currentCityId = this.rootStore.cityStore.selectedCityId

      const res = await this.rootAPI.bookingAdminAPI
        .getCityMusiciansBySkill(currentCityId, skillId, data)
      if (res && res.payload) {
        this.skillMusiciansMappings[skillId] = res.payload.items || []
      }
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    } finally {
      this.musicianPreferencesLoading = false
    }
  }

  @action.bound
  async getMusicianPreference() {
    this.musicianPreferencesLoading = true

    try {
      let res
      if (this.currentMusicianPreferenceId) {
        res = await this.rootAPI.userAPI.getMusicianPreference(this.currentMusicianPreferenceId)
        this.currentMusicianPreferences = (res.payload.musicians || [])
          .filter(pre => this.musicians.some(mus => mus.id === pre.id))
        return
      }

      const userId = this.rootStore.profileStore.profile.user.id
      const data = {
        cityId: this.rootStore.cityStore.selectedCityId,
        skillId: this.currentSkillId,
      }

      try {
        res = await this.rootAPI.userAPI.getMusicianPreferences(userId, data)
        this.currentMusicianPreferenceId = res.payload.id
        this.currentMusicianPreferences = (res.payload.musicians || [])
          .filter(pre => this.musicians.some(mus => mus.id === pre.id))
      } catch (err) {
        if (err.type === -1) {
          this.currentMusicianPreferenceId = null
          this.currentMusicianPreferences = []
          return
        }
        throw err
      }
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    } finally {
      this.musicianPreferencesLoading = false
    }
  }

  @action.bound
  async reorderMusicianPreference(musicianIds) {
    let isSuccess = false
    this.musicianPreferencesLoading = true
    try {
      const data = {
        cityId: this.rootStore.cityStore.selectedCityId,
        skillId: this.currentSkillId,
        musicianIds,
      }
      const res = await this.rootAPI.userAPI
        .updateMusicianPreference(this.currentMusicianPreferenceId, data)
      if (res) {
        await this.getMusicianPreference()
        notification.success({
          message: 'Success!',
          description: 'Musicians have been reordered successfully!',
          placement: 'bottomRight',
          duration: 2,
        })
        isSuccess = true
      }
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    } finally {
      this.musicianPreferencesLoading = false
    }

    return isSuccess
  }

  @action.bound
  async toggleMusicianSelection(musicianId) {
    this.musicianPreferencesLoading = true
    try {
      let musicianIds = this.currentMusicianPreferences.map(mus => mus.id)
      const indexOf = musicianIds.findIndex(id => id === musicianId)
      if (indexOf === -1) {
        musicianIds.push(musicianId)
      } else {
        musicianIds = musicianIds.filter(id => id !== musicianId)
      }

      const data = {
        cityId: this.rootStore.cityStore.selectedCityId,
        skillId: this.currentSkillId,
        musicianIds,
      }

      let res
      if (this.currentMusicianPreferenceId) {
        res = await this.rootAPI.userAPI
          .updateMusicianPreference(this.currentMusicianPreferenceId, data)
      } else {
        const userId = this.rootStore.profileStore.profile.user.id
        res = await this.rootAPI.userAPI
          .addMusicianPreferences(userId, [data])
        if (res && res.payload && res.payload.length > 0) {
          this.currentMusicianPreferenceId = res.payload[0].id
        }
      }
      // reload MusicianPreferences List
      if (res) {
        await this.getMusicianPreference()
      }
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    }
    this.musicianPreferencesLoading = false
  }

  @action.bound
  clearMusicianPreferencesData(isKeepSkill) {
    const currentSkill = this.currentSkillId
    this.musicians = []
    this.skillMusiciansMappings = {}
    this.currentMusicianPreferenceId = ''
    this.currentSkillId = ''
    this.currentMusicianPreferences = []
    if (isKeepSkill && currentSkill) {
      this.selectSkill(currentSkill)
    }
  }
}
