import _ from 'lodash'
import moment from 'moment'
import { DATE_TIME_FORMAT } from '../constants'

export function convertDataURItoBlob(dataURI) {
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  const byteString = atob(dataURI.split(',')[1])

  // separate out the mime component
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

  // write the bytes of the string to an ArrayBuffer
  const ab = new ArrayBuffer(byteString.length)
  const ia = new Uint8Array(ab)
  for (let i = 0; i < byteString.length; i++) { /* eslint-disable-line */
    ia[i] = byteString.charCodeAt(i)
  }

  return new Blob([ab], { type: mimeString })
}

export function getMenuKeyByPath(pathname) {
  return pathname.replace(/(^\/+|\/+$)/mg, '')
}

export function isEmptyObject(obj) {
  return obj && Object.keys(obj).length === 0 && obj.constructor === Object
}

export function getRandomId() {
  return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
}

export function getFirstNWords(text, n) {
  return text.split(/\s+/).slice(0, n).join(' ')
}

export function groupByKey(data, key, supplier) {
  return _.chain(data)
    .groupBy(key)
    .toPairs()
    .map(currentItem => _.zipObject([key, supplier], currentItem))
    .value()
}

export function convertUTCToLocal(date, dateFormat = DATE_TIME_FORMAT) {
  const newDate = new Date(moment.utc(date).local())
  return moment(newDate, dateFormat)
}

export function displayLocalTimeFromUTC(date, dateFormat = DATE_TIME_FORMAT) {
  return moment.utc(date).local().format(dateFormat)
}

export function groupNItems(list, size) {
  const temp = [...list]
  const result = []
  while (temp.length > 0) {
    result.push(temp.splice(0, size))
  }

  return result
}

export function convertDataURLtoFile(dataUrl, filename) {
  const arr = dataUrl.split(',')
  const mime = arr[0].match(/:(.*?);/)[1]

  const bStr = atob(arr[1])
  let n = bStr.length
  const u8arr = new Uint8Array(n)

  while (n--) { // eslint-disable-line
    u8arr[n] = bStr.charCodeAt(n)
  }

  return new File([u8arr], filename, { type: mime })
}

// Algorithms: https://en.wikipedia.org/wiki/ISO_week_date
const modulo = y => (y + Math.floor(y / 4) - Math.floor(y / 100) + Math.floor(y / 400)) % 7
export const getISOWeeksInYear = year => 52 + ((modulo(year) === 4 || modulo(year - 1) === 3) ?
  1 : 0)

const getDayOfYear = (date) => {
  const start = new Date(date.getFullYear(), 0, 0)
  const diff = date - start
  const oneDay = 1000 * 60 * 60 * 24
  const day = Math.floor(diff / oneDay)

  return day
}

export const toWeekNumber = (date) => {
  const year = date.getFullYear()
  const weekNumber = Math.floor((getDayOfYear(date) - (date.getDay() + 1) + 10) / 7)

  if (weekNumber < 1) { return { week: getISOWeeksInYear(year - 1), year: year - 1 } }

  if (weekNumber > getISOWeeksInYear(year)) { return { week: 1, year: year + 1 } }

  return { week: weekNumber, year }
}

export const fromWeekNumber = (weekNo, year) => {
  const offsetWeek = new Date(year, 0, 4)
  const dayNumber = weekNo * 7 - (offsetWeek.getDay() + 1 + 3)

  const beginOfYear = new Date(year, 0, 1)
  const startDate = new Date(beginOfYear.getTime())
  startDate.setHours(dayNumber * 24)

  const endDate = new Date(beginOfYear.getTime())
  endDate.setHours((dayNumber + 7) * 24)
  return { startDate, endDate }
}
