export const ROLE_TYPES = {
  Undefined: 0,
  Admin: 1,
  SuperUser: 2,
  Normal: 4,
  Support: 8,
  None: 16,
}

export const ROLE_TYPES_DISPLAY = {
  0: 'Undefined',
  1: 'Admin',
  2: 'Manager',
  4: 'Member',
  8: 'Support',
  16: 'None',
}

export const USER_MODES = {
  None: 0,
  // Individual
  Individual: 1, // 0

  // OrgMember
  OrgMember: 2, // 1
  OrgManager: 4, // 2

  // Application Admin
  OrgAdmin: 8, // 3
  Partner: 8, // 3

  // Internal User
  SupportManager: 16, // 4
  Support: 32, // 5
  Admin: 64, // 6
  NoneUser: 128, // 7
  TechSupport: 256, // 8
  DevSupport: 512, // 9
  SysAdmin: 1024, // 10
}

export const ORG_USER_MODES = [USER_MODES.OrgAdmin, USER_MODES.OrgManager, USER_MODES.OrgMember]

export const PLATFORM_USER_MODES = [USER_MODES.Admin, USER_MODES.SupportManager, USER_MODES.Support]

export const SYSTEM_USER_MODES = [USER_MODES.SysAdmin, USER_MODES.TechSupport, USER_MODES.DevSupport]

export const INTERNAL_USER_MODES = [...SYSTEM_USER_MODES, ...PLATFORM_USER_MODES]

export const REGISTERED_USER_MODES = [...INTERNAL_USER_MODES, ...ORG_USER_MODES, USER_MODES.Individual]

export const USER_MODES_DISPLAY = {
  1: 'Individual',
  2: 'Organisation Member',
  4: 'Organisation Admin',
  8: 'Partner',
  16: 'Support Manager',
  32: 'Support',
  64: 'Admin',
  128: 'None User',
  256: 'Tech Support',
  512: 'Dev Support',
  1024: 'System Admin',
}

export const USER_GENDER_TYPES = {
  0: 'Not Specific',
  1: 'Male',
  2: 'Female',
  3: 'Transgender',
  4: 'Other',
}

export const USER_STATUSES = {
  Error: 0,
  Archived: 10, // Long term disabled state - not visible in normal operation
  Disabled: 20, // Actively restricted state - is visible in normal operation
  Suspended: 30, // Temporary state of limited functionality - is visible in normal operation
  Restricted: 40, // Partial features - negative aspect
  Draft: 50, // Locally usable but not externally available
  Transitioning: 53, // In transition state. Might be read only.
  // Partial features - Account not fully activated  <= this is the default for new object creation
  Onboarding: 55,
  Limited: 60, // Partial features - positive aspect
  Enabled: 70, // standard functionality
  Enhanced: 80, // additional functionality
}

export const USER_STATUS_TYPES = {
  Archived: 'Archived', // Long term disabled state - not visible in normal operation
  Disabled: 'Disabled', // Actively restricted state - is visible in normal operation
  Suspended: 'Suspended', // Temporary state of limited functionality - is visible in normal operation
  Restricted: 'Restricted', // Partial features - negative aspect
  Draft: 'Draft', // Locally usable but not externally available
  Transitioning: 'Transitioning', // In transition state. Might be read only.
  Onboarding: 'Onboarding', // Partial features - Account not fully activated  <= this is the default for new object creation
  Limited: 'Limited', // Partial features - positive aspect
  Enabled: 'Enabled', // standard functionality
  Enhanced: 'Enhanced', // additional functionality
}

export const USER_STATUSES_DISPLAY = {
  0: 'Error',
  10: 'Archived', // Long term disabled state - not visible in normal operation
  20: 'Disabled', // Actively restricted state - is visible in normal operation
  30: 'Suspended', // Temporary state of limited functionality - is visible in normal operation
  40: 'Restricted', // Partial features - negative aspect
  50: 'Draft', // Locally usable but not externally available
  53: 'Transitioning', // In transition state. Might be read only.
  55: 'Onboarding', // Partial features - Account not fully activated  <= this is the default for new object creation
  60: 'Limited', // Partial features - positive aspect
  70: 'Enabled', // standard functionality
  80: 'Enhanced', // additional functionality
}

export const ORG_TYPES = {
  Undefined: 0,
  Internal: 1, // for default org
  Individual: 10, // for individual account org
  Company: 20, // for normal compaines
  NGO: 30, // for non-profits and other non-commercial entities
  Institution: 40, // for semi-governmental organisations e.g.
  Government: 50,
  // Any organisation that is not registered in our system (has no owner user e.g. suppliers)
  NonRegistered: 60,
  Other: 100,
}

export const ORG_TYPES_DISPLAY = {
  0: 'Undefined',
  1: 'Internal',
  10: 'Individual',
  20: 'Company',
  30: 'NGO',
  40: 'Institution',
  50: 'Government',
  60: 'Non Registered',
  100: 'Other',
}

export const ORG_VISIBLE_LEVELS = {
  None: 0,
  Public: 10,
  Restricted: 20,
  Limited: 30,
  Member: 40,
  Manager: 50,
  Owner: 100,
}

export const NAME_TITLE_TYPES = {
  0: 'Mr',
  1: 'Miss',
  2: 'Mrs',
  3: 'Ms',
}
