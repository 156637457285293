import React from 'react'
import { Router } from 'flynk.app.web.core.components/router'

import Routes from './Routes'
import MaintenanceRoutes from './MaintenanceRoutes'
import { history } from '../stores'

const router = () => (
  <Router history={history}>
    {window.env.REACT_APP_MAINTENANCE_MODE ? <MaintenanceRoutes /> : <Routes />}
  </Router>
)

export default router
