import { observable, action, runInAction } from 'mobx'
import { persist } from 'mobx-persist'
import { ALERT_TIMER } from '../../constants'
import resettableMixin from '../resettableMixin'

@resettableMixin
class InvoiceStore {
  @persist('list') @observable invoices = []
  @persist('object') @observable invoice = {}

  constructor(args) {
    this.rootStore = args.rootStore
    this.rootAPI = args.rootAPI
  }

  @action.bound
  async getInvoices() {
    try {
      const res = await this.rootStore.invoiceAPI.getInvoices()
      if (res.payload) {
        runInAction(() => {
          this.invoices = res.payload
        })
      }
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    }
  }

  @action.bound
  clearInvoices() {
    this.invoices = []
  }

  @action.bound
  async getInvoiceById(id) {
    try {
      const res = await this.rootStore.invoiceAPI.getInvoiceById(id)
      if (res.payload) {
        runInAction(() => {
          this.invoice = res.payload
        })
      }
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    }
  }

  @action.bound
  async addInvoice(data) {
    try {
      await this.rootStore.invoiceAPI.addInvoice(data)
      this.rootStore.alertStore.success({
        title: 'The invoice has been added successfully!',
        timer: ALERT_TIMER,
      })
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    }
  }

  @action.bound
  async updateInvoice(id, data) {
    try {
      await this.rootStore.invoiceAPI.updateInvoice(id, data)
      this.rootStore.alertStore.success({
        title: 'The invoice has been updated successfully!',
        timer: ALERT_TIMER,
      })
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    }
  }

  @action.bound
  async deleteInvoice(id) {
    try {
      await this.rootStore.invoiceAPI.deleteInvoice(id)
      const index = this.invoices.findIndex(item => item.id === id)
      if (index >= 0) {
        runInAction(() => {
          this.invoices.splice(index, 1)
        })
      }
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    }
  }

  @action.bound
  clearInvoice() {
    this.invoice = {}
  }
}

export default InvoiceStore
