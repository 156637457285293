export default class ProductAPI {
  constructor(args) {
    this.request = args.request
  }

  getClientApps = () => this.request.get('/ClientApps')

  getClientAppById = id => this.request.get(`/ClientApps/${id}`)

  addClientApp = data => this.request.post('/ClientApps', data)

  updateClientApp = data => this.request.put('/ClientApps', data)

  deleteClientApp = id => this.request.del(`/ClientApps/${id}`)
}
