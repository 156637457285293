export const ROLE_TYPES = {
  Undefined: 0,
  Admin: 1,
  SuperUser: 2,
  Normal: 4,
  Support: 8,
  None: 16,
}

export const USER_MODES = {
  // Individual
  Individual: 1,

  // OrgMember
  OrgMember: 2,

  // Application Admin
  OrgAdmin: 4,
  Partner: 8,

  // Internal User
  SupportManager: 16,
  Support: 32,
  Admin: 64,
  NoneUser: 128,
  TechSupport: 256,
  DevSupport: 512,
  SysAdmin: 1024,
}

export const USER_STATUSES = {
  Error: 0,
  Archived: 10, // Long term disabled state - not visible in normal operation
  Disabled: 20, // Actively restricted state - is visible in normal operation
  Suspended: 30, // Temporary state of limited functionality - is visible in normal operation
  Restricted: 40, // Partial features - negative aspect
  Draft: 50, // Locally usable but not externally available
  Transitioning: 53, // In transition state. Might be read only.
  // Partial features - Account not fully activated  <= this is the default for new object creation
  Onboarding: 55,
  Limited: 60, // Partial features - positive aspect
  Enabled: 70, // standard functionality
  Enhanced: 80, // additional functionality
}

export const ORG_TYPES = {
  Undefined: 0,
  Internal: 1, // for default org
  Individual: 10, // for individual account org
  Company: 20, // for normal companies
  NGO: 30, // for non-profits and other non-commercial entities
  Institution: 40, // for semi-governmental organisations e.g.
  Government: 50,
  // Any organisation that is not registered in our system (has no owner user e.g. suppliers)
  NonRegistered: 60,
  OrganisationalUnit: 70, // Business Unit
  LocalUnit: 80, // Store
  Group: 90,
  Other: 100,
}

export const LINK_TYPES = {
  Web: 1,
  Phone: 10,
  Fax: 20,
  Mobile: 30,
  Email: 40,
  Facebook: 50,
  Twitter: 60,
  LinkedIn: 70,
  Other: 100,
}
