export const RESPONSE_TYPES = {
  UNSPECIFIED_ERROR: -100,
  SYSTEM_ERROR: -32,
  NETWORK_ERROR: -31,
  ERROR: -30,
  INSUFFICIENT_DATA: -26,
  DATA_CONFLICT: -25,
  INVALID_REQUEST: -24,
  INVALID_OPERATION: -23,
  INVALID_ARGUMENT: -22,
  INVALID_STATE: -21,
  FAILED: -20,
  EXPIRED: -18,
  HUMAN_TEST_REQUIRED: -17,
  AUTHENTICATION_FAILED: -16,
  SECURE_CHANNEL_REQUIRED: -15,
  NOT_PERMITTED: -14,
  NOT_LOGGED_IN_REQUIRED: -13,
  LOGIN_REQUIRED: -12,
  CRITERIA_NOT_MET: -11,
  DENIED: -10,
  TIMEOUT: -6,
  CANCELLED: -5,
  UNDEFINED: -2,
  NOT_FOUND: -1,
  FOUND: 0,
  NOT_COMPLETE: 0,
  WARNING: 0,
  OK: 1,
  COMPLETE: 2,
  SUCCESS: 3,
}
