import { BREAK_POINTS_NUMBERS } from 'flynk.app.web.core.components/constants'

export const PRIORITY_BAND_SHOW = 7 // SEVEN-PIECE BAND

export const SubmitEnquiryType = {
  OnPerformerPage: 0,
  OnSummarySection: 1,
  OnSummaryPage: 2,
}

export const TaxMode = {
  None: 0,
  Inclusive: 1,
  Exclusive: 2,
}

export const PhoneType = {
  Mobile: '1',
  Other: '2',
}

export const VocalOption = {
  NoVocals: 0,
  MaleVocalsOnly: 1,
  MaleAndFemaleVocals: 2,
  FemaleVocalsOnly: 3,
}

export const BookingQuoteStagesPaths = {
  user: '/booking/user',
  dateTime: '/booking/date-time',
  location: '/booking/location',
  packages: '/booking/packages',
  performers: '/booking/performers',
  success: '/booking/success',
  summary: '/booking/summary',
  newBooking: '/booking',
}

export const WebsiteUrl = {
  AU: 'https://redsoda.com.au',
  CA: 'https://redsoda.ca',
  US: 'https://redsoda.com',
}

export const PhoneWord = {
  AU: 'Mobile',
  CA: 'Cell',
  US: 'Cell',
  Default: 'Mobile',
}

export const EnquiryWord = {
  AU: 'Enquiry',
  CA: 'Inquiry',
  US: 'Inquiry',
}

export const BusinessIdLabel = {
  AU: 'ABN',
  CA: 'GST/HST',
  US: 'SSN',
}

export const BookingStatus = {
  // None: { id: 0, name: 'None', color: 'darkgrey' },
  PlaceHolder: {
    id: 1,
    name: 'PlaceHolder',
    color: '#000000',
  },
  Abandoned: {
    id: 10,
    name: 'Abandoned',
    color: 'darkgrey',
  },
  RequestCallback: {
    id: 15,
    name: 'Callback Requested',
    color: '#f00',
  },
  Estimated: {
    id: 20,
    name: 'Estimated',
    color: '#e056fd',
  },
  Quote: {
    id: 30,
    name: 'Quote',
    color: '#686de0',
  },
  Planning: {
    id: 40,
    name: 'Planning',
    color: '#004CE6',
  },
  Confirmation: {
    id: 50,
    name: 'Confirmation',
    color: '#f9ca24',
  },
  Booked: {
    id: 60,
    name: 'Booked',
    color: '#6ab04c',
  },
  PlanningComplete: {
    id: 70,
    name: 'Planning Completed',
    color: '#39E500',
  },
  Performing: {
    id: 80,
    name: 'Performing',
    color: '#FFCC00',
  },
  PostEvent: {
    id: 90,
    name: 'Post Event',
    color: '#D30000',
  },
  ReadyToPay: {
    id: 100,
    name: 'Ready To Pay',
    color: '#630000',
  },
  MusiciansPaid: {
    id: 110,
    name: 'Musicians Paid',
    color: '#4834d4',
  },
  Finalised: {
    id: 120,
    name: 'Finalised',
    color: 'green',
  },
  Cancelled: {
    id: 130,
    name: 'Cancelled',
    color: 'grey',
  },
  Archived: {
    id: 140,
    name: 'Archived',
    color: 'lightgrey',
  },
  // Error: { id: 150, name: 'Error', color: 'darkgrey' },
}

export const EmptyField = '-'

export const GAOption = {
  Default: {
    Name: 'default',
    Id: window.env ? window.env.REACT_APP_GA_DEFAULT_ID : '',
  },
  AU: {
    Name: 'au',
    Id: window.env ? window.env.REACT_APP_GA_AU_ID : '',
  },
  CA: {
    Name: 'ca',
    Id: window.env ? window.env.REACT_APP_GA_CA_ID : '',
  },
  US: {
    Name: 'us',
    Id: window.env ? window.env.REACT_APP_GA_US_ID : '',
  },
}

export const PerformanceType = {
  Ceremony: 'Ceremony',
  Reception: 'Reception',
}

export const PaymentStatus = {
  NotSpecific: 0,
  Preview: 10,
  Cancelled: 20,
  Init: 30,
  Hanging: 40,
  Completed: 50,
}

export const BookingMenuItems = [
  {
    title: 'Confirmation',
    section: 'confirmation',
  },
  {
    title: 'Musicians',
    section: 'musicians',
  },
  {
    title: 'Event Timeline',
    section: 'timeline',
  },
  {
    title: 'Songs & Music',
    section: 'musics',
  },
  {
    title: 'Musician Payments',
    section: 'payments',
  },
  // {
  //   title: 'Reviews',
  //   section: 'reviews',
  // },
]

export const MusicianBookingMenuItems = [
  {
    title: 'Musicians',
    section: 'musicians',
  },
  {
    title: 'Event Timeline',
    section: 'timeline',
  },
  {
    title: 'Songs & Music',
    section: 'musics',
  },
  // {
  //   title: 'Payments',
  //   section: 'payments',
  //   disabled: true,
  // },
  // {
  //   title: 'Reviews',
  //   section: 'reviews',
  //   disabled: true,
  // },
]

export const BookingSections = {
  details: {
    icon: 'file-invoice',
    id: 'details',
    title: 'Booking Details',
    nextId: 'confirmation',
  },
  confirmation: {
    icon: 'thumbs-up',
    id: 'confirmation',
    index: 0,
    nextId: 'musicians',
    prevId: 'details',
    scrollLeft: 0,
    title: 'Confirmation',
  },
  musicians: {
    icon: 'user-check',
    id: 'musicians',
    nextId: 'timeline',
    prevId: 'confirmation',
    index: 1,
    scrollLeft: 40,
    title: 'Musicians',
  },
  timeline: {
    icon: 'watch',
    id: 'timeline',
    nextId: 'musics',
    prevId: 'musicians',
    index: 2,
    scrollLeft: 80,
    title: 'Event Timeline',
  },
  musics: {
    icon: 'music',
    id: 'musics',
    nextId: 'payments',
    prevId: 'timeline',
    index: 3,
    scrollLeft: 120,
    title: 'Songs & Music',
  },
  payments: {
    icon: 'hand-holding-usd',
    id: 'payments',
    // nextId: 'reviews',
    prevId: 'musics',
    index: 4,
    scrollLeft: 160,
    title: 'Musician Payments',
  },
  // reviews: {
  //   icon: 'clipboard-check',
  //   id: 'reviews',
  //   prevId: 'payments',
  //   index: 5,
  //   scrollLeft: 200,
  //   title: 'Review & Close',
  // },
}

export const MusicianOfferSections = {
  details: {
    icon: 'file-invoice',
    id: 'details',
    title: 'Booking Details',
    nextId: 'musicians',
  },
  musicians: {
    icon: 'user-check',
    id: 'musicians',
    prevId: 'details',
    index: 0,
    scrollLeft: 0,
    title: 'Musicians',
  },
}

export const MusicianBookingSections = {
  myBookings: {
    icon: 'book-spells',
    id: 'myBookings',
    title: 'My Bookings',
  },
  ...MusicianOfferSections,
  musicians: {
    ...MusicianOfferSections.musicians,
    nextId: 'timeline',
  },
  timeline: {
    icon: 'watch',
    id: 'timeline',
    nextId: 'musics',
    prevId: 'musicians',
    index: 1,
    scrollLeft: 40,
    title: 'Event Timeline',
  },
  musics: {
    icon: 'music',
    id: 'musics',
    // nextId: 'payments',
    prevId: 'timeline',
    index: 2,
    scrollLeft: 80,
    title: 'Songs & Music',
  },
  // payments: {
  //   icon: 'hand-holding-usd',
  //   id: 'payments',
  //   nextId: 'reviews',
  //   prevId: 'musics',
  //   index: 4,
  //   scrollLeft: 160,
  //   title: 'Musician Payments',
  // },
  // reviews: {
  //   icon: 'clipboard-check',
  //   id: 'reviews',
  //   prevId: 'payments',
  //   index: 5,
  //   scrollLeft: 200,
  //   title: 'Review & Close',
  // },
}

export const InvoiceLineItemAddMethod = {
  Automated: 0,
  Manually: 10,
}

export const BookingDepositInitialPercent = 30
export const BookingDepositDefault = 990
export const BookingCeremonyDepositDefault = 330

export const BookingDepositOptions = {
  percent: 'deposit-percent',
  fixed: 'deposit-fixed',
  full: 'full-invoice',
}

export const defaultDepositCoefficient = 0.3

export const PERFORMANCE_GROUPS = {
  noCeremony: 0,
  harp: 1,
  acoustic: 2,
  string: 3,
}

export const PERFORMANCE_GROUPS_DISPLAY = {
  0: 'No Ceremony Music',
  1: 'Harp',
  2: 'Acoustic',
  3: 'Strings',
}

export const NO_CEREMONY_OBJECT = {
  id: 'no-ceremony',
  name: 'No Ceremony Music',
  price: 0,
  thumbnailUrl: '/images/no-music.png',
}

export const NO_RECEPTION_OBJECT = {
  id: 'no-reception',
  name: 'No Reception Music',
  order: 0,
  price: 0,
  thumbnailUrl: '/images/no-music.png',
}

export const FIXED_NAVBAR_CLASS = 'fixed_header'
export const FIXED_TABS_CLASS = 'fixed_tabbar'
export const CHOOSE_SINGER_FOR_ME_ID = 'choose-for-me'
export const VIDEOS_ASPECT_RATIO_INDEX = 0.5625

export const QUOTE_BUILDER_BREAKPOINT = BREAK_POINTS_NUMBERS.xl

export const ADD_SONG_OPTIONS = {
  None: 0,
  Existing: 1,
  New: 2,
}

export const GIG_TYPES = {
  // this type just use foor dropdown in add timeline
  Ceremony: 'Ceremony',
  Reception: 'Reception',
}

export const PAYMENT_TITLE = {
  FULL_PAID: 'Fully Paid',
  FULL_PAYMENT_PAID: 'Full Payment Paid',
  DEPOSITED: 'Deposit Paid',
}

export const MOMENT_GROUP_TYPE = {
  None: 0,
  Ceremony: 1,
  Reception: 2,
}

export const PhoneNumberWord = {
  AU: 'mobile',
  CA: 'cell phone',
  US: 'cell phone',
  Default: 'mobile',
}

export const REGIONS = {
  AU: 'AU',
  CA: 'CA',
  US: 'US',
}

export const WebsiteUrlByRegion = {
  AU: '/au/sydney/',
  CA: '/ca/toronto/',
  US: '/us/boston/',
}

export const InitialHourBooking = {
  ceremony: {
    min: 1,
    max: 2,
  },
  reception: {
    min: 5,
    max: 5,
  },
}

export const InitialDistance = 0

export const CeremonyMinHourByDistance = [
  {
    minDistance: 0,
    minHour: 1,
  },
  {
    minDistance: 70,
    minHour: 1.5,
  },
  {
    minDistance: 140,
    minHour: 2,
  },
]

export const BookingType = {
  Warning: 0,
}

export const BookingCode = {
  NotAllowedUpdating: 3000,
}

export const LocationOptionValue = {
  address: '0',
  notSureYet: '1',
}

export const BookingCategory = {
  Adjustment: 'Adjustment',
  FirmPrice: 'Firm Price',
  Surcharge: 'Surcharge',
}

export const BookingInvoiceTitle = {
  Package: 'Package',
  SingerSurcharge: 'Singer Surcharge',
}
