import { getRandomId } from '../helpers'

let payments = [
  {
    id: '1235',
    cardHolder: 'Tien',
    cardNumber: '5123456789012346',
    type: 'MasterCard',
    expiryMonth: '06',
    expiryYear: '21',
    cvv: '123',
  },
  {
    id: '1735',
    cardHolder: 'Nguyen',
    cardNumber: '5123456789012980',
    type: 'Visa',
    expiryMonth: '06',
    expiryYear: '21',
    cvv: '183',
  },
]

export default class PaymentAPI {
  constructor(args) {
    this.request = args.request
  }

  getPayments = () => new Promise((resolve) => {
    setTimeout(() => {
      resolve({ payload: payments })
    }, 500)
  })

  getPaymentById = id => new Promise((resolve) => {
    setTimeout(() => {
      const payment = payments.find(item => item.id === id)
      resolve({ payload: payment })
    }, 500)
  })

  addPayment = data => new Promise((resolve) => {
    setTimeout(() => {
      payments = [...payments, { ...data, id: getRandomId() }]
      resolve()
    }, 500)
  })

  updatePayment = (id, data) => new Promise((resolve) => {
    setTimeout(() => {
      const paymentIndex = payments.findIndex(item => item.id === id)
      if (paymentIndex >= 0) {
        payments[paymentIndex] = {
          ...payments[paymentIndex],
          ...data,
        }
        resolve()
      }
    }, 500)
  })

  deletePayment = id => new Promise((resolve) => {
    setTimeout(() => {
      const paymentIndex = payments.findIndex(item => item.id === id)
      if (paymentIndex >= 0) {
        payments.splice(paymentIndex, 1)
        resolve()
      }
    }, 500)
  })
}
