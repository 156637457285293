export const DATE_FORMAT = 'DD/MM/YYYY'
export const DATE_TIME_FORMAT = 'DD/MM/YYYY, hh:mm A'
export const LONG_DATE_TIME_FORMAT = 'DD/MM/YY, HH:mm'
export const TIME_FORMAT = 'HH:mm'
export const LONG_DATE_FORMAT = 'dddd MMMM D, YYYY'
export const TIME_AMPM_FORMAT = 'hh:mm A'
export const TIME_DURATION_FORMAT = 'H [h.] mm [min.]'

export const DAYS_OF_WEEK = {
  0: 'Sunday',
  1: 'Monday',
  2: 'Tuesday',
  3: 'Wednesday',
  4: 'Thursday',
  5: 'Friday',
  6: 'Saturday',
}
