export default class ProspectAPI {
  constructor(args) {
    this.request = args.request
  }

  getProspects = data => this.request.get('/Bookings/Prospect/Find', {
    params: data,
  })

  getBookingsByPersonId = (personId, data) => this.request.get(`/Customer/${personId}/Bookings`, {
    params: data,
  })

  getBookingById = id => this.request.get(`/Bookings/${id}`)

  getSalesManagersByCityId = (cityId, minStatus) => this.request.get(`/Users/SalesManagers/City/${cityId}/Status/${minStatus}`)

  assignSalesManager = (salesManagerId, data) => this.request.put(`Bookings/SalesManager/${salesManagerId}`, data)

  replaceSalesManager = (oldSalesManagerId, newSalesManagerId) =>
    this.request.put(`Bookings/SalesManager/${newSalesManagerId}/Replace/${oldSalesManagerId}`)

  disableSalesManager = data => this.request.post('/Organisations/DisableSaleManager', data)

  enableSalesManager = data => this.request.post('/Organisations/EnableSaleManager', data)
}
