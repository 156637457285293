import React from 'react'
import { Form, Radio, Select } from 'antd'
import styled from 'styled-components'
import forOwn from 'lodash/forOwn'
import size from 'lodash/size'
import { BREAK_POINTS } from '../constants'

const { Option } = Select

const SelectStyled = styled(Select)`
  @media (min-width: ${BREAK_POINTS.sm}) and (max-width: ${BREAK_POINTS.md}) {
    max-width: 50rem;
  }
`

export const hasErrors = fieldsError => fieldsError.some(field => field.errors.length)

export function createFormField(value) {
  return Form.createFormField({
    value,
  })
}

export function renderSelect(options, configs) {
  // Table filter
  if (configs && configs.isFilter) {
    const list = [
      <Option value="all" key="all">
        Show All
      </Option>,
    ]

    forOwn(options, (value, key) => {
      const option = (
        <Option value={key} key={key}>
          {value}
        </Option>
      )
      list.push(option)
    })
    return <SelectStyled {...configs}>{list}</SelectStyled>
  }

  // Select
  if (configs && configs.type === 'select') {
    const list = []
    forOwn(options, (value, key) => {
      const option = (
        <Option value={key} key={key}>
          {value}
        </Option>
      )
      list.push(option)
    })
    return (
      <SelectStyled
        {...configs}
        getPopupContainer={trigger => trigger.parentNode}
      >
        {list}
      </SelectStyled>
    )
  }

  // Radio
  if (size(options) < 5 || configs.type === 'radio') {
    const list = configs && configs.isFilter ?
      [
        <Radio value="all" key="all">
          Show All
        </Radio>,
      ] :
      []
    forOwn(options, (value, key) => {
      const option = (
        <Radio value={key} key={key}>
          {value}
        </Radio>
      )
      list.push(option)
    })
    return <Radio.Group {...configs}>{list}</Radio.Group>
  }

  const list = []
  forOwn(options, (value, key) => {
    const option = (
      <Option value={key} key={key}>
        {value}
      </Option>
    )
    list.push(option)
  })
  return (
    <SelectStyled
      {...configs}
      getPopupContainer={trigger => trigger.parentNode}
    >
      {list}
    </SelectStyled>
  )
}
