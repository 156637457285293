import React from 'react'
import { Provider } from 'mobx-react'
import ErrorBoundary from 'flynk.app.web.core.components/components/Exception/ErrorBoundary'
import { addUnPackToConsole } from 'flynk.app.web.core.data/helpers/addunpack'

import rootStore from './stores'
import Router from './router'
import './themes/index.less'

addUnPackToConsole()

const App = () => (
  <ErrorBoundary>
    <Provider {...rootStore}>
      <Router />
    </Provider>
  </ErrorBoundary>
)

export default App
