export default class OfferAPI {
  constructor(args) {
    this.request = args.request
  }

  getIncomingOffers = (musicianUserId, data) => this.request.get(`/Bookings/Musician/${musicianUserId}/IncomingOffers`, {
    params: data,
  })

  getFutureBookings = (musicianUserId, data) => this.request.get(`/Bookings/Musician/${musicianUserId}/FutureBookings`, {
    params: data,
  })

  getPreviousBookings = (musicianUserId, data) => this.request.get(`/Bookings/Musician/${musicianUserId}/PreviousBookings`, {
    params: data,
  })

  acceptOffer = offerId => this.request.put(`/Bookings/Offer/${offerId}`)

  acceptOfferGroup = groupOfferId => this.request.put(`/Bookings/GroupOffer/${groupOfferId}/Accept`)

  declineOfferGroup = groupOfferId => this.request.put(`/Bookings/GroupOffer/${groupOfferId}/Decline`)

  confirmAvailability = groupOfferId => this.request.put(`Bookings/GroupOffer/${groupOfferId}/AvailabilityConfirm`)
}
