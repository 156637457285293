import get from 'lodash/get'
import { formatBooking } from './booking'

export const getFormattedOffers = offers => offers
  .map((offer) => {
    const gigs = get(offer, 'gigOffer', [])
    const rest = { ...offer }
    delete rest.gigOffer
    return gigs.map(gig => ({
      ...gig,
      ...rest,
    }))
  })
  .filter(gigs => gigs.length > 0)
  .map(gigs => gigs.map(gig => formatBooking(gig)))

export const checkIsOfferIncoming = offer => !!(offer && offer.length && offer[0].groupOfferId)

export const checkIsMissedOffer = offer => !!(offer && offer.length && offer[0].isCancelled)
