export const CREDIT_CARD_TYPES = {
  Visa: 'Visa',
  MasterCard: 'MasterCard',
}

export const CREDIT_CARD_REGEXS = {
  AmericanExpress: /^(?:3[47][0-9]{13})$/,
  Visa: /^(?:4[0-9]{12}(?:[0-9]{3})?)$/,
  MasterCard: /^(?:5[1-5][0-9]{14})$/,
  Discover: /^(?:6(?:011|5[0-9][0-9])[0-9]{12})$/,
  DinersClub: /^(?:3(?:0[0-5]|[68][0-9])[0-9]{11})$/,
  JCB: /^(?:(?:2131|1800|35\d{3})\d{11})$/,
}
