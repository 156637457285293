import { library } from '@fortawesome/fontawesome-svg-core'
import {
  fal,
  faAddressBook,
  faAlarmClock,
  faArchive,
  faArrowAltFromLeft,
  faArrowAltToLeft,
  faBars,
  faBookSpells,
  faBells,
  faBriefcase,
  faCalendarCheck,
  faCalendarExclamation,
  faChartArea,
  faCheck,
  faCheckCircle,
  faChevronDown,
  faChevronUp,
  faChevronRight,
  faChevronLeft,
  faClipboardCheck,
  faClock,
  faCog,
  faCommentsAlt,
  faCubes,
  faEdit,
  faEnvelope,
  faFileInvoice,
  faFileInvoiceDollar,
  faGripLines,
  faHandHoldingUsd,
  faHomeLgAlt,
  faInfoCircle,
  faListMusic,
  faListOl,
  faLockAlt,
  faMapMarkedAlt,
  faMapMarkerAlt,
  faMicrophone,
  faMicrophoneStand,
  faMoneyBillWave,
  faMusic,
  faPen,
  faPhone,
  faPiggyBank,
  faPlayCircle,
  faPlus,
  faQuestion,
  faQuestionCircle,
  faSignOutAlt,
  faStars,
  faThLarge,
  faThumbsUp,
  faTimes,
  faTimesCircle,
  faToolbox,
  faTrash,
  faUserCheck,
  faUserCircle,
  faUserPlus,
  faUserTie,
  faUser,
  faUserMusic,
  faUsers,
  faUsersCrown,
  faWatch,
  faTrashAlt,
  faStickyNote,
  faExclamationTriangle,
} from '@fortawesome/pro-light-svg-icons'
import {
  fas,
  faBookSpells as fasBookSpells,
  faCommentsAlt as fasCommentsAlt,
  faFileInvoice as fasFileInvoice,
  faHomeLgAlt as fasHomeLgAlt,
} from '@fortawesome/pro-solid-svg-icons'
import {
  far,
  faArrowFromRight,
  faArrowToRight,
  faClipboardList,
  faGlobeAsia,
  faUserAlt,
  faCalendarAlt,
  faRingsWedding,
  faConciergeBell,
} from '@fortawesome/pro-regular-svg-icons'

import {
  fab,
  faInstagram,
  faFacebookSquare,
} from '@fortawesome/free-brands-svg-icons'

library.add(
  fal,
  far,
  fas,
  fab,
  faAddressBook,
  faAlarmClock,
  faArchive,
  faArrowAltFromLeft,
  faArrowAltToLeft,
  faArrowFromRight,
  faArrowToRight,
  faBars,
  faBookSpells,
  faBells,
  faBriefcase,
  faCalendarCheck,
  faCalendarExclamation,
  faChartArea,
  faCheck,
  faCheckCircle,
  faChevronDown,
  faChevronRight,
  faChevronLeft,
  faChevronUp,
  faClipboardCheck,
  faClipboardList,
  faClock,
  faCog,
  faCommentsAlt,
  faCubes,
  faEdit,
  faEnvelope,
  faFileInvoice,
  faFileInvoiceDollar,
  faGlobeAsia,
  faGripLines,
  faHandHoldingUsd,
  faHomeLgAlt,
  faInfoCircle,
  faListMusic,
  faListOl,
  faLockAlt,
  faMapMarkedAlt,
  faMapMarkerAlt,
  faMicrophone,
  faMicrophoneStand,
  faMoneyBillWave,
  faMusic,
  faPen,
  faPlus,
  faPhone,
  faPiggyBank,
  faPlayCircle,
  faQuestion,
  faQuestionCircle,
  faSignOutAlt,
  faStars,
  faStickyNote,
  faThLarge,
  faThumbsUp,
  faTimes,
  faTimesCircle,
  faToolbox,
  faTrash,
  faUserCheck,
  faUserCircle,
  faUserMusic,
  faUserTie,
  faUser,
  faUserPlus,
  faUsers,
  faUsersCrown,
  faWatch,
  fasBookSpells,
  fasCommentsAlt,
  fasFileInvoice,
  fasHomeLgAlt,
  faTrashAlt,
  faUserAlt,
  faCalendarAlt,
  faRingsWedding,
  faConciergeBell,
  faInstagram,
  faFacebookSquare,
  faExclamationTriangle,
)
