import axios from 'axios'

export default class OrganisationAPI {
  constructor(args) {
    this.request = args.request
  }

  getOrganisations = data => this.request.get('/Organisations', {
    params: data,
  })

  getOrganisationById = (orgId, roleId) => {
    if (roleId) {
      return this.request.get(`/Organisation/${orgId}`, {
        headers: {
          ...this.request.getHeaders(),
          RoleId: roleId,
        },
      })
    }

    return this.request.get(`/Organisation/${orgId}`)
  }

  addOrganisation = data => this.request.post('/Organisations/Add', data)

  updateDetailsOrganisation = data => this.request.put('/Organisations/UpdateDetailsProfile', data)

  getOrganisationMembersById = (orgId, roleId, data) => {
    if (roleId) {
      return this.request.get(`/Organisation/${orgId}/Members`, {
        headers: {
          ...this.request.getHeaders(),
          RoleId: roleId,
        },
        params: data,
      })
    }

    return this.request.get(`/Organisation/${orgId}/Members`, {
      params: data,
    })
  }

  getOrganisationMemberById = userId => this.request.get(`/User/${userId}`)

  deleteOrganisationMemberById = data => this.request.post('Organisations/RemoveUser', data)

  getLogoPresignedUrl = (id, data) => this.request.get(`/UploadPostProcessRequest/Organisation/${id}/Logo/PresignedUploadPostProcessUrl?objectName=${data.objectName}`)

  uploadLogo = (url, file, contentType) => axios.put(url, file, {
    headers: {
      'Content-Type': contentType,
    },
  })

  getBankAccount = orgId => this.request.get(`/Organisation/${orgId}/BankAccount`)

  updateBankAccount = (orgId, data) => this.request.put(`/Organisation/${orgId}/BankAccount`, data)

  sendOrganisationInvite = data => this.request.post('/Organisations/Invites', data)

  getOrganisationInvites = params => this.request.get('/Organisations/Invites/Pending', { params })

  resendOrganisationInvite = id => this.request.put(`/Organisations/Invite/${id}/Resend`)

  cancelOrganisationInvite = id => this.request.put(`/Organisations/Invite/${id}/Cancel`)

  getOrganisationInviteData = key => this.request.get(`/Organisations/Invites/LoadByKey?key=${key}`)

  acceptOrganisationInvite = data => this.request.post('/Organisations/Invites/Complete', data)

  acceptOrganisationAndAdminInvite = data => this.request.post('Organisations/Invites/CompleteAndRegister', data)
}
