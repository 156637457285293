import isEqual from 'lodash/isEqual'
import React from 'react'
import { MobXProviderContext } from 'mobx-react'
import { toJS } from 'mobx'
import moment from 'moment'
import HTMLDecoderEncoder from 'html-encoder-decoder'
import { TIME_AMPM_FORMAT } from 'flynk.app.web.core.components/constants'
import { sha512 } from 'js-sha512'
import { USER_STATUSES } from 'flynk.app.web.core.data/constants'

export function useStores() {
  return React.useContext(MobXProviderContext)
}

export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)
  return result
}

export const isListChanged = (preferredPerformers, oldPerformers) => {
  const preferredPerformerIds = preferredPerformers.map(p => p.id)
  const oldPerformerIds = oldPerformers.map(p => p.id)
  return !isEqual(preferredPerformerIds, oldPerformerIds)
}

export const getPerformanceTime = (offer = []) => {
  let { arrivalTime, endTime } = offer[0]?.position

  offer.forEach((item) => {
    if (moment(item?.position?.arrivalTime).isBefore(arrivalTime)) {
      arrivalTime = item?.position?.arrivalTime
    }

    if (moment(item?.position?.endTime).isAfter(endTime)) {
      endTime = item?.position?.endTime
    }
  })

  if (!arrivalTime) {
    const offerWithStartTime = offer.find(el => el.position?.arrivalTime)
    arrivalTime = offerWithStartTime && offerWithStartTime.position.arrivalTime
  }

  if (!endTime) {
    const offerWithEndTime = offer.reverse().find(el => el.position?.endTime)
    endTime = offerWithEndTime && offerWithEndTime.position.endTime
  }

  if (!arrivalTime || !endTime) {
    return []
  }
  return [arrivalTime, endTime]
}

export const getFormattedTimeArray = (arr) => {
  if (Array.isArray(arr)) {
    return arr.map((el) => {
      if (!el || typeof el !== 'string') {
        return el
      }
      return moment(el).format(TIME_AMPM_FORMAT)
    })
  }
  return arr
}

export const checkOverflown = ({
  clientWidth,
  clientHeight,
  scrollWidth,
  scrollHeight,
}) => ({
  v: scrollHeight > clientHeight,
  h: scrollWidth > clientWidth,
})

export const activateScrollBar = () => {
  const scrollables = document.querySelectorAll(
    '.rk-scrollbar-horizontal-table .ant-table',
  );
  [].forEach.call(scrollables, (scrollable) => {
    const isOverfown = checkOverflown(scrollable)
    const activeClass = 'rk-inactive'
    if (isOverfown.h && scrollable.classList.contains(activeClass)) {
      scrollable.classList.remove(activeClass)
    } else if (!isOverfown.h && !scrollable.classList.contains(activeClass)) {
      scrollable.classList.add(activeClass)
    }
  })
}

// for debugging
export const unPack = value => toJS(value, { recurseEverything: true })

export const getLocationSummary = (location) => {
  if (location) {
    return location.name ?
      location.name :
      [location.city, location.state].join(' ')
  }
  return ''
}

export const formatCitiesByRoleStatus = (role, cities) =>
  cities.map((city) => {
    const correspondingRole = role.orgId === city.id
    if (!correspondingRole) return city
    return {
      ...city,
      isRoleActive: role.status === USER_STATUSES.Enabled,
    }
  })

export const conditionedClassName = conditions =>
  Object.entries(conditions)
    .reduce((sum, [key, value]) => {
      if (value) {
        sum.push(key)
      }

      return sum
    }, [])
    .join(' ')

export const isJson = (str) => {
  try {
    JSON.parse(str)
  } catch (e) {
    return false
  }
  return true
}

export const getObjectFromLocalStorage = (key) => {
  const storageItem = localStorage.getItem(key)

  if (isJson(storageItem)) {
    return JSON.parse(storageItem)
  }
  return null
}

export const getFromLocalStorageObjectByKey = (objectName, key) => {
  const object = getObjectFromLocalStorage(objectName)

  if (!object) return null

  if (typeof object === 'object') return object[key]

  return object
}

export const getUserNameDataFromLocalStorage = (username) => {
  try {
    const hash = sha512(username)

    const localStorageData = localStorage.getItem(hash)

    if (!localStorageData) return {}

    const localStorageUserNameData = JSON.parse(localStorageData)

    if (!localStorageUserNameData) return {}

    return localStorageUserNameData
  } catch (e) {
    // eslint-disable-next-line no-console
    console.warn(e)
    return {}
  }
}

export const getHtmlDecoded = text =>
  (text ? HTMLDecoderEncoder.decode(text) : text)
export const getHtmlEncoded = text =>
  (text ? HTMLDecoderEncoder.encode(text) : text)

export const getFullName = (...args) => {
  if (!args?.length) return ''
  let arr = []
  if (typeof args[0] === 'object') {
    const { firstname, firstName, lastname, lastName } = args[0]

    arr = [firstname ?? firstName, lastname ?? lastName]
  } else if (typeof args[0] === 'string') {
    arr = args.splice(0, 2)
  }
  return arr.filter(el => el).join(' ')
}

export const getRegionShortCodeByCityName = (cityName) => {
  switch (cityName) {
    case 'Boston':
      return 'US'
    case 'Toronto':
      return 'CA'
    default:
      return 'AU'
  }
}

export const groupBy = (arr, key) => {
  const initialValue = {}
  return arr.reduce((acc, cval) => {
    const myAttribute = cval[key]
    acc[myAttribute] = [...(acc[myAttribute] || []), cval]
    return acc
  }, initialValue)
}

export const postAMessageFromIframeToParent = (message, value = '') => {
  // incase it's a iframe. post a message to detect button is clicked
  window.parent.postMessage({ message, value }, '*')
}

export const orderByCreatedDate = (array, order = 'desc') =>
  array.slice().sort((a, b) => {
    const dateA = Date.parse(a?.created || 0)
    const dateB = Date.parse(b?.created || 0)

    if (order === 'desc') {
      return dateB - dateA
    } if (order === 'asc') {
      return dateA - dateB
    }
  })

export const toCapitalize = (text) => {
  return text?.charAt(0)?.toUpperCase() + text?.slice(1)
}

export const checkIsDirty = (initialValues, currentValues) => {
  return !isEqual(initialValues, currentValues)
}

export const validateFormFields = async (
  formInstance,
  fields,
) => {
  try {
    await formInstance.validateFields(fields)

    return true
  } catch (error) {
    if (error?.errorFields?.length) {
      return false
    }

    return true
  }
}

export const isNumberGreaterThanZero = value => !value && value !== 0

export const checkIsInIframe = () => {
  try {
    return window.self !== window.top
  } catch (e) {
    return true
  }
}
