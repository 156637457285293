import { action, computed, observable } from 'mobx'
import { persist } from 'mobx-persist'
import get from 'lodash/get'
import { USER_STATUSES } from 'flynk.app.web.core.data/constants'
import { DEFAULT_PAGINATION } from 'flynk.app.web.core.data/constants/pagination'
import resettableMixin from 'flynk.app.web.core.data/stores/resettableMixin'
import { TaxMode } from '../../constants'
import { formatCitiesByRoleStatus, getUserNameDataFromLocalStorage } from '../../helpers/common'

@resettableMixin
class CityStore {
  @persist('list') @observable allCities = []
  @persist('list') @observable cities = []
  @persist('list') @observable regions = []
  @persist('list') @observable saleManagerRoleStatusCities = []
  @observable pagination = DEFAULT_PAGINATION
  @observable getRegionsAndCitiesLoading = false

  @computed get selectedCities() {
    return this.cities.filter(city => city.selected)
  }

  @computed get roleCities() {
    const {
      currentRole: {
        organisation,
        orgId,
      },
    } = this.rootStore.profileStore
    if (organisation && 'lat' in organisation) {
      return this.allCities.filter(c => c.id === orgId)
    }
    return this.allCities
  }

  @computed get selectedCityIds() {
    return this.selectedCities.map(city => city.id)
  }

  @computed get selectedCity() {
    return this.selectedCities[0] || { region: { name: '' } }
  }

  @computed get selectedCityId() {
    return this.selectedCity.id || ''
  }

  @computed get regionId() {
    return this.selectedCity.regionId || ''
  }

  @computed get selectedRegion() {
    return get(this.selectedCity, 'region.name', '')
  }

  @computed get selectedTaxMode() {
    return get(this.selectedCities[0], 'region.taxMode', TaxMode.None)
  }

  @computed get preferredRegions() {
    return this.regions.map(region => region.name)
  }

  @computed get saleManagerRoleStatusCitiesToUpdate() {
    const { currentRole } = this.rootStore.profileStore
    return this.saleManagerRoleStatusCities.reduce((diffs, city, index) => {
      if (city.isRoleActive !==
        formatCitiesByRoleStatus(currentRole, this.selectedCities)[index].isRoleActive) {
        diffs.push(city)
      }
      return diffs
    }, [])
  }

  constructor(args) {
    this.rootStore = args.rootStore
    this.rootAPI = args.rootAPI
  }

  @action.bound
  async getRegionsAndCities(data = {
    skip: 0,
    take: this.pagination.pageSize,
  }) {
    this.getRegionsAndCitiesLoading = true
    try {
      const promises = [
        this.rootAPI.bookingAPI.getRegions(data),
        this.rootAPI.bookingAPI.getCities(data),
      ]

      const [regionsRes, citiesRes] = await Promise.all(promises)
      const regions = get(regionsRes, 'payload.items', [])
      const cities = get(citiesRes, 'payload.items', [])

      if (regions.length > 0) {
        this.regions = regions
        this.setPagination({
          ...this.pagination,
          current: regionsRes.payload.page,
          pageSize: regionsRes.payload.pageSize,
          total: regionsRes.payload.totalItems,
        })
      }

      if (cities.length > 0) {
        const extendedCities = cities
          .map((city) => {
            const region = this.regions.find(r => r.id === city.regionId) || {}

            return {
              ...city,
              region,
            }
          })

        this.allCities = extendedCities

        if (this.rootStore.profileStore.isSalesManager) {
          await this.formatCitiesForSalesManager(extendedCities)
        } else {
          this.cities = extendedCities
        }

        // Select first city if no selected cities
        if (this.selectedCities.length === 0) {
          const {
            preferredRole,
            profile,
          } = this.rootStore.profileStore
          const username = profile.user?.username

          const { selectedCityId } = getUserNameDataFromLocalStorage(username)

          const { orgId } = preferredRole
          const orgCity = this.allCities.find(city => city.id === orgId)

          if (selectedCityId) {
            this.selectCities([selectedCityId])
          } else if (!orgCity) {
            this.selectCities([this.cities[0].id])
          } else {
            this.selectCities([orgId])
          }
        }
      }
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    } finally {
      this.getRegionsAndCitiesLoading = false
    }
  }

  @action.bound
  setPagination = (pagination) => {
    this.pagination = pagination
  }

  @action.bound
  setCities(cities) {
    this.cities = cities
  }

  @action.bound
  selectCities(cityIds) {
    this.cities = this.allCities.map(city => ({
      ...city,
      selected: cityIds.some(id => id === city.id),
    }))
  }

  @action.bound
  setSaleManagerRoleStatusCities(cities) {
    this.saleManagerRoleStatusCities = cities
  }

  @action.bound
  async formatCitiesForSalesManager(cities) {
    try {
      const { currentRole } = this.rootStore.profileStore
      const filteredCities = cities.filter(
        city => currentRole.orgId === city.id,
      )

      const tasks = await filteredCities
        .map(async city =>
          this.rootAPI.prospectAPI.getSalesManagersByCityId(city.id, USER_STATUSES.Disabled))

      const res = await Promise.all(tasks)

      if (res?.length > 0) {
        const salesManagers = res.map(r => r.payload
          .map((salesManager) => {
            const status = get(salesManager, 'roles[0].status', USER_STATUSES.Error)
            return {
              ...salesManager.user,
              status,
              selected: status > USER_STATUSES.Disabled,
            }
          })
          .filter(salesManager => salesManager.id === this.rootStore.profileStore.userId)[0])

        const extendCity = (city, index) => ({
          ...city,
          selected: salesManagers[index]?.selected || false,
          userId: salesManagers[index]?.id,
        })

        this.allCities = cities.map(extendCity)
        this.cities = filteredCities.map(extendCity)
      }
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    }
  }
}

export default CityStore
