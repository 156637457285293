import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import { init } from './startup'
import { migrate } from './migration'
import './libs/font-awesome'

function startApp() {
  ReactDOM.render(
    <App />,
    document.getElementById('root'),
  )
  init()
}

migrate().then(startApp).catch(startApp)
