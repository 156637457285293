import get from 'lodash/get'
import { GENDER_TYPES, ORG_TYPES } from '../constants'

export const formatName = (customer) => {
  let name = ''

  if (customer.name) {
    return customer.name
  }

  if (customer.firstname) {
    name += customer.firstname
  }

  if (customer.middleNames) {
    name += ` ${customer.middleNames}`
  }

  if (customer.lastname) {
    name += ` ${customer.lastname}`
  }

  return name
}

export const formatUser = (userResponse) => {
  let type = -1
  const roles = get(userResponse, 'userContext.roles', [])
  if (roles && roles.length > 0) {
    const types = roles.filter(r => r.orgType !== ORG_TYPES.Individual).map(role => role.type)
    type = Math.min(...types)
  }

  const user = get(userResponse, 'userContext.user', {})

  return {
    id: userResponse.id,
    userId: userResponse.userId,
    type,
    username: user.username || '',
    name: formatName(user),
    email: user.email || '',
    mobile: user.mobile || '',
    created: user.created || '',
  }
}

export const formatUserRole = (userResponse) => {
  let type = -1
  const roles = get(userResponse, 'roles', [])
  if (roles && roles.length > 0) {
    const types = roles.map(role => role.type)
    type = Math.max(...types)
  }

  return {
    id: userResponse.id,
    userId: userResponse.Id,
    type,
    username: userResponse.username || '',
    name: formatName(userResponse),
    email: userResponse.email || '',
    mobile: userResponse.mobile || '',
    created: userResponse.created || '',
  }
}

export const formatNameWithSurcharge = (performer, isLong = true) => {
  const { alias, subject, surcharge } = performer
  if (alias) {
    if (Number(surcharge) > 0) {
      return isLong ? `${alias} ($${Number(surcharge)} surcharge)` :
        `${alias} *`
    }
    return alias
  }
  if (subject) {
    const { firstname } = subject
    if (firstname) {
      if (Number(surcharge) > 0) {
        return isLong ? `${firstname} ($${Number(surcharge)} surcharge)` :
          `${firstname} *`
      }
      return firstname
    }
  }
  return ''
}

export const filterPerformerSkills = (performerSkills, gender) => {
  if (!performerSkills.length) return performerSkills
  switch (Number(gender)) {
    case GENDER_TYPES.Male: {
      return performerSkills.filter(skill => !/\bfemale\b/i.test(skill.name))
    }
    case GENDER_TYPES.Female: {
      return performerSkills.filter(skill => !/\bmale\b/i.test(skill.name))
    }
    default: return performerSkills
  }
}

export const GetHighestRoleFromProfile = (useProfile) => {
  let type = -1
  const roles = get(useProfile, 'roles', [])
  if (roles && roles.length > 0) {
    const types = roles.map(role => role.type)
    type = Math.min(...types)
  }
  return type
}
