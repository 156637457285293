import { action, computed, observable, runInAction } from 'mobx'
import { persist } from 'mobx-persist'
import { notification } from 'antd'

export default class VenuesStore {
  constructor(args) {
    this.rootStore = args.rootStore
    this.rootAPI = args.rootAPI
  }

  @observable mapObject = {}
  @observable loading = false
  @observable getTravelChargesByCityIdLoading = false
  @observable venuesTravelCharges = []
  @observable currentVenue = {}
  @observable searchQuery = ''
  @persist('object') @observable cityIdsVenuesCache = {}

  @computed get selectedCityId() {
    return this.rootStore.cityStore.selectedCityId
  }

  @computed get selectedVenues() {
    return this.cityIdsVenuesCache[this.selectedCityId] || []
  }

  @action.bound
  selectedVenueByPlaceId = (placeId) => {
    const venues = this.selectedVenues
    const indexOf = venues.findIndex(el => el.place_id === placeId)
    if (indexOf === -1) {
      notification.error({
        message: 'Failed!',
        description: 'Place not found!',
        placement: 'bottomRight',
      })
      return
    }
    const [venueDetails] = venues.splice(indexOf, 1)

    runInAction(() => {
      this.currentVenue = venueDetails
      this.searchQuery = venueDetails.formatted_address
      this.cityIdsVenuesCache[this.selectedCityId] = [
        venueDetails,
        ...venues.splice(0, 6),
      ]
    })
  }

  @action.bound
  addSelectedVenue = async (venue) => {
    this.loading = true
    try {
      let venueDetails
      const venues = this.selectedVenues
      const indexOf = venues.findIndex(el => el.place_id === venue.place_id)
      if (indexOf !== -1) {
        [venueDetails] = venues.splice(indexOf, 1)
      } else {
        const { payload } = await this.rootAPI.venuesAPI.createVenue({
          jsonData: JSON.stringify(venue),
          cityId: this.rootStore.cityStore.selectedCityId,
        })
        venueDetails = { ...venue, ...payload }
      }

      runInAction(() => {
        this.currentVenue = venueDetails
        this.searchQuery = venueDetails.formatted_address
        this.cityIdsVenuesCache[this.selectedCityId] = [
          venueDetails,
          ...venues.splice(0, 6),
        ]
      })
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    }

    this.loading = false
  }

  @action.bound
  setMapObject(mapObject) {
    this.mapObject = mapObject
  }

  @action.bound
  async saveVenue(data) {
    this.loading = true
    try {
      const venueDetails = { ...this.currentVenue, ...data }
      const res = await this.rootAPI.venuesAPI.updateVenue(venueDetails.id, data)
      if (res) {
        this.currentVenue = venueDetails
        this.cityIdsVenuesCache[this.selectedCityId][0] = venueDetails
      }
    } catch (err) {
      if (err.code !== 0) {
        this.rootStore.errorsStore.addError(err)
      }
    }

    this.loading = false
  }

  @action.bound
  async getTravelChargesByCityId(cityId = this.selectedCityId) {
    this.getTravelChargesByCityIdLoading = true
    try {
      const { payload } = await this.rootAPI.venuesAPI.getTravelChargesByCityId(cityId)
      if (payload) {
        payload.sort((a, b) => (a.minKm - b.minKm))

        this.venuesTravelCharges = payload
      }
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    } finally {
      this.getTravelChargesByCityIdLoading = false
    }
  }

  @action.bound
  setSearchQuery(searchQuery) {
    this.searchQuery = searchQuery
  }

  @action.bound
  clearCurrentVenue() {
    this.searchQuery = ''
    this.currentVenue = {}
  }
}
