import { observable, action, runInAction } from 'mobx'
import { ALERT_TIMER, DEFAULT_PAGINATION } from '../../constants'

import resettableMixin from '../resettableMixin'

@resettableMixin
class InvitationStore {
  @observable invites = []
  @observable invitesPagination = DEFAULT_PAGINATION
  @observable invitesLoading = false
  @observable resendInviteLoadingId = null
  @observable cancelInviteLoadingId = null

  @observable registrationInviteLoading = false
  @observable completeRegistrationInviteLoading = false
  @observable inviteUserModalVisible = false
  @observable inviteInternalUserModalVisible = false
  @observable inviteUserLoading = false
  @observable registrationData = {}
  @observable acceptRoleInviteLoading = false
  @observable sendRegistrationInviteLoading = false

  @observable registrationInvites = []
  @observable registrationInvitesPagination = DEFAULT_PAGINATION
  @observable registrationInvitesLoading = false
  @observable resendRegistrationInviteLoadingId = null
  @observable cancelRegistrationInviteLoadingId = null

  constructor(args) {
    this.rootStore = args.rootStore
    this.rootAPI = args.rootAPI
  }

  @action.bound
  setInviteUserModalVisible(value) {
    this.inviteUserModalVisible = value
  }

  @action.bound
  async inviteUser(data) {
    const { userAPI } = this.rootAPI
    const {
      alertStore, errorsStore, profileStore, organisationStore,
    } = this.rootStore

    const orgId = organisationStore.organisationId || profileStore.currentOrganisationId

    const requestData = {
      ...data,
      orgId,
    }

    this.inviteUserLoading = true

    try {
      await userAPI.inviteUser(requestData)

      this.setInviteUserModalVisible(false)

      this.fetchInvites()
      this.fetchRegistrationInvites()

      alertStore.success({
        title: 'Success!',
        content: 'Invitation successfully sent.',
        timer: ALERT_TIMER,
      })
    } catch (error) {
      errorsStore.addError(error)
    } finally {
      this.inviteUserLoading = false
    }
  }

  @action.bound
  async fetchInvites(params = {
    skip: 0,
    take: this.invitesPagination.pageSize,
  }) {
    const { errorsStore, organisationStore, profileStore } = this.rootStore
    const { userAPI } = this.rootAPI

    const orgId = profileStore.currentOrganisationId || organisationStore.organisationId

    this.invitesLoading = true

    try {
      const { payload } = await userAPI.fetchInvites(orgId, params)

      this.invites = payload.items
      this.setInvitesPagination({
        ...this.invitesPagination,
        total: payload.totalItems,
        totalPages: payload.totalPages,
        currentPage: payload.currentPage,
        pageSize: payload.pageSize,
        hasPreviousPage: payload.hasPreviousPage,
        hasNextPage: payload.hasNextPage,
      })
    } catch (error) {
      errorsStore.addError(error)
    } finally {
      this.invitesLoading = false
    }
  }

  @action.bound
  setRegistrationInvitesPagination(pagination) {
    this.registrationInvitesPagination = pagination
  }

  @action.bound
  async fetchRegistrationInvites(params = {
    skip: 0,
    take: this.registrationInvitesPagination.pageSize,
  }) {
    const { errorsStore, organisationStore, profileStore } = this.rootStore
    const { userAPI } = this.rootAPI

    const orgId = profileStore.currentOrganisationId || organisationStore.organisationId

    this.registrationInvitesLoading = true

    try {
      const { payload } = await userAPI.fetchRegistrationInvites({
        ...params,
        orgId,
      })

      this.registrationInvites = payload.items
      this.setRegistrationInvitesPagination({
        ...this.registrationInvitesPagination,
        total: payload.totalItems,
        totalPages: payload.totalPages,
        currentPage: payload.currentPage,
        pageSize: payload.pageSize,
        hasPreviousPage: payload.hasPreviousPage,
        hasNextPage: payload.hasNextPage,
      })
    } catch (error) {
      errorsStore.addError(error)
    } finally {
      this.registrationInvitesLoading = false
    }
  }

  @action.bound
  setInvite(invite) {
    this.invite = invite
  }

  @action.bound
  clearInvite() {
    this.setInvite({})
  }

  @action.bound
  async resendInvite(id) {
    const { userAPI } = this.rootAPI
    const { errorsStore, alertStore } = this.rootStore

    this.resendInviteLoadingId = id

    try {
      await userAPI.resendUserInvite(id)

      this.clearInvite()

      alertStore.success({
        title: 'Success!',
        content: 'The invitation has been resent.',
        timer: ALERT_TIMER,
      })
    } catch (error) {
      errorsStore.addError(error)
    } finally {
      this.resendInviteLoadingId = null
    }
  }

  @action.bound
  async cancelInvite(id) {
    const { userAPI } = this.rootAPI
    const { errorsStore, alertStore } = this.rootStore

    this.cancelInviteLoadingId = id

    try {
      await userAPI.cancelUserInvite(id)
      this.fetchInvites()

      alertStore.success({
        title: 'Success!',
        content: 'The invitation has been canceled.',
        timer: ALERT_TIMER,
      })
    } catch (error) {
      errorsStore.addError(error)
    } finally {
      this.cancelInviteLoadingId = null
    }
  }

  @action.bound
  async resendRegistrationInvite(id) {
    const { userAPI } = this.rootAPI
    const { errorsStore, alertStore } = this.rootStore

    this.resendRegistrationInviteLoadingId = id

    try {
      await userAPI.resendUserRegistrationInvite(id)

      alertStore.success({
        title: 'Success!',
        content: 'The invitation has been resent.',
        timer: ALERT_TIMER,
      })
    } catch (error) {
      errorsStore.addError(error)
    } finally {
      this.resendRegistrationInviteLoadingId = null
    }
  }

  @action.bound
  async cancelRegistrationInvite(id) {
    const { userAPI } = this.rootAPI
    const { errorsStore, alertStore } = this.rootStore

    this.cancelRegistrationInviteLoadingId = id

    try {
      await userAPI.cancelUserRegistrationInvite(id)
      this.fetchRegistrationInvites()

      alertStore.success({
        title: 'Success!',
        content: 'The invitation has been canceled.',
        timer: ALERT_TIMER,
      })
    } catch (error) {
      errorsStore.addError(error)
    } finally {
      this.cancelRegistrationInviteLoadingId = null
    }
  }

  @action.bound
  setInvitesPagination(pagination) {
    this.invitesPagination = pagination || DEFAULT_PAGINATION
  }

  @action.bound
  async loadRegistrationInvite(key) {
    const { userAPI } = this.rootAPI
    const { errorsStore } = this.rootStore

    this.registrationInviteLoading = true

    try {
      const { payload } = await userAPI.loadRegistrationInvite(key)

      this.setRegistrationData(payload)
    } catch (error) {
      errorsStore.addError(error)
    } finally {
      this.registrationInviteLoading = false
    }
  }

  @action.bound
  setRegistrationData(data) {
    this.registrationData = data
  }

  @action.bound
  async completeRegistrationInvite(data) {
    const { userAPI } = this.rootAPI
    const { errorsStore, authStore, routingStore } = this.rootStore

    this.completeRegistrationInviteLoading = true

    try {
      await userAPI.completeRegistrationInvite(data)

      await authStore.login({
        username: data.userName,
        password: data.password,
      })

      routingStore.push('/dashboard')
    } catch (error) {
      errorsStore.addError(error)
    } finally {
      this.completeRegistrationInviteLoading = false
    }
  }

  @action.bound
  async acceptRoleInvite(key) {
    const { userAPI } = this.rootAPI
    const { errorsStore, alertStore } = this.rootStore

    this.acceptRoleInviteLoading = true

    try {
      await userAPI.acceptRoleInvite(key)

      alertStore.success({
        title: 'Success!',
        content: 'Role invitation has been successfully accepted.',
        timer: ALERT_TIMER,
      })
    } catch (error) {
      errorsStore.addError(error)
    } finally {
      this.acceptRoleInviteLoading = false
    }
  }

  @action.bound
  setInviteInternalUserModalVisible(value) {
    this.inviteInternalUserModalVisible = value
  }

  @action.bound
  async sendRegistrationInvite(data) {
    const { invitationStore } = this.rootStore
    let success = false
    this.sendRegistrationInviteLoading = true

    try {
      await this.rootAPI.userAPI.sendRegistrationInvite(data)

      runInAction(() => {
        this.rootStore.alertStore.success({
          title: 'Invitation has been sent!',
          timer: ALERT_TIMER,
        })

        this.setInviteInternalUserModalVisible(false)

        invitationStore.fetchInvites()
        invitationStore.fetchRegistrationInvites()

        success = true
      })
    } catch (err) {
      if (err.code === 1) {
        if (data.orgRoleType) {
          err.description = 'This User already has an Admin role in this organisation.'
        } else if (data.businessUnitRoleType) {
          err.description = 'This User already has a Manager role in this business unit.'
        } else if (data.storeRoleType) {
          err.description = 'This User already has a Manager role in this store.'
        } else {
          err.description = 'This User already has a Member role in this organisation.'
        }
      }
      this.rootStore.errorsStore.addError(err)
    } finally {
      this.sendRegistrationInviteLoading = false
    }

    return success
  }
}

export default InvitationStore
