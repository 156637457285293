export default class SupplierAPI {
  constructor(args) {
    this.request = args.request
  }

  getSuppliers = () => this.request.get('/Suppliers')

  getSupplierById = id => this.request.get(`/Suppliers/${id}`)

  addSupplier = data => this.request.post('/Suppliers', data)

  updateSupplier = data => this.request.put('/Suppliers', data)
}
