export const validateCognitoPassword = (value) => {
  if (!value) {
    return {
      isValid: false,
      message: 'Password is required',
    }
  } if (value.length < 8) {
    return {
      isValid: false,
      message: 'Password must be at least 8 characters',
    }
  } if (value.length > 99) {
    return {
      isValid: false,
      message: 'Password cannot be longer than 99 characters',
    }
  } if (!(new RegExp('(?=.*[a-z])')).test(value)) {
    return {
      isValid: false,
      message: 'Password must contain at least 1 lowercase alphabetical character',
    }
  } if (!(new RegExp('(?=.*[A-Z])')).test(value)) {
    return {
      isValid: false,
      message: 'Password must contain at least 1 uppercase alphabetical character',
    }
  } if (!(RegExp('(?=.*[0-9])')).test(value)) {
    return {
      isValid: false,
      message: 'Password must contain at least 1 numeric character',
    }
  } else if (!(new RegExp('(?=.*[\\^$*.\\[\\]{}\\(\\)?\\-"!@#%&\\/,><\\\':;|_~`])')).test(value)) { // eslint-disable-line
    return {
      isValid: false,
      message: 'Password must contain at least 1 special character',
    }
  }
  return {
    isValid: true,
    message: '',
  }
}

export const validatePassword = (value) => {
  if (window.env.REACT_APP_IS_AUTH_BY_COGNITO) {
    return validateCognitoPassword(value)
  }

  if (!value) {
    return {
      isValid: false,
      message: 'Password is required',
    }
  } if (value.length < 8) {
    return {
      isValid: false,
      message: 'Password must be at least 8 characters',
    }
  } if (value.length > 100) {
    return {
      isValid: false,
      message: 'Password cannot be longer than 100 characters',
    }
  } if (!(new RegExp('(?=.*[a-z])')).test(value)) {
    return {
      isValid: false,
      message: 'Password must contain at least 1 lowercase alphabetical character',
    }
  } if (!(new RegExp('(?=.*[A-Z])')).test(value)) {
    return {
      isValid: false,
      message: 'Password must contain at least 1 uppercase alphabetical character',
    }
  } if (!(RegExp('(?=.*[0-9])')).test(value)) {
    return {
      isValid: false,
      message: 'Password must contain at least 1 numeric character',
    }
  } else if (!(new RegExp('(?=.*[_!@#\$%\^&\*])')).test(value)) { // eslint-disable-line
    return {
      isValid: false,
      message: 'Password must contain at least 1 special character',
    }
  }
  return {
    isValid: true,
    message: '',
  }
}
