import forOwn from 'lodash/forOwn'
import { create } from 'mobx-persist'
import clientPersist from 'client-persist'
import rootStore from '../stores'

clientPersist.setDriver(clientPersist.SESSIONSTORAGE)

export async function migrate() {
  // Clear local storage & force log out when deploy a new version
  const currentVersion = process.env.REACT_APP_LOCALSTORAGE_VERSION
  const oldVersion = localStorage.getItem('version')

  if (!oldVersion || oldVersion !== currentVersion) {
    localStorage.clear()
    localStorage.setItem('version', currentVersion)
    window.location.reload()
  }

  // Hydrate local storage
  const hydrateFactory = (storage = localStorage) => create({ storage })

  const Stores = {
    authStore: rootStore.authStore,
    bookingStore: rootStore.bookingStore,
    bookingAdminStore: rootStore.bookingAdminStore,
    navStore: rootStore.navStore,
    profileStore: rootStore.profileStore,
    cityStore: rootStore.cityStore,
    offerStore: rootStore.offerStore,
    venuesStore: rootStore.venuesStore,
  }

  const promises = []

  forOwn(Stores, async (value, key) => {
    if (key === 'bookingStore') {
      promises.push(hydrateFactory(clientPersist)(key, value))
    } else {
      promises.push(hydrateFactory(localStorage)(key, value))
    }
  })

  if (promises.length > 0) {
    await Promise.all(promises)
  }
}
