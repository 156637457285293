export default class IssueAPI {
  constructor(args) {
    this.request = args.request
  }

  getIssues = userId => this.request.get(`/Users/${userId}/Issues`)

  getIssueById = id => this.request.get(`/Issues/${id}`)

  addIssue = data => this.request.post('/Issues', data)

  updateIssue = data => this.request.put('/Issues', data)

  deleteIssue = id => this.request.del(`/Issues/${id}`)

  getIssueCommentsByMode = id => this.request.get(`/Issues/${id}/CommentsByMode`)

  addIssueComment = (id, data) => this.request.post(`/Issues/${id}/Comments`, data)
}
