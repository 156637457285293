export default class BankAPI {
  constructor(args) {
    this.request = args.request
  }

  getBanks = () => this.request.get('/Banks')

  getBankById = id => this.request.get(`/Banks/${id}`)

  addBank = data => this.request.post('/Banks', data)

  updateBank = data => this.request.put('/Banks', data)
}
