import BaseUserAPI from '../flynk.app.web.core.data/api/UserAPI'

export default class UserAPI extends BaseUserAPI {
  sendRegistrationInviteForSalesManger = data => this.request.post('/Users/SendRegistrationInviteForSalesManager', data)

  loadRegistrationInvite = data => this.request.get('/Users/LoadRegistrationInvite', {
    params: data,
  })

  completeRegistrationInvite = data => this.request.post('/Users/CompleteRegistrationInvite', data)

  sendRegistrationInviteForMusician = (data, roleId) => {
    if (roleId) {
      return this.request.post('/Users/SendRegistrationInviteForMusician', data, {
        headers: {
          ...this.request.getHeaders(),
          RoleId: roleId,
        },
      })
    }

    return this.request.post('/Users/SendRegistrationInviteForMusician', data)
  }

  getMusicianById = (id, cityId) => this.request.get(`/Users/Musician/${id}`, {
    params: {
      cityId,
    },
  })

  updateMusician = (id, data) => this.request.put(`/Users/Musician/${id}`, data)

  getMusicianPreferences = (userId, data) => this.request.get(`User/${userId}/MusicianPreferences`, {
    params: data,
  })

  addMusicianPreferences = (userId, data) => this.request.post(`User/${userId}/MusicianPreferences`, data)

  getMusicianPreference = musicianPreferenceId => this.request
    .get(`/Users/MusicianPreference/${musicianPreferenceId}`)

  updateMusicianPreference = (musicianPreferenceId, data) => this.request
    .put(`/Users/MusicianPreference/${musicianPreferenceId}`, data)

  deleteMusicianPreference = musicianPreferenceId => this.request
    .del(`/Users/MusicianPreference/${musicianPreferenceId}`)

  getMusiciansList = (minStatus, params) => this.request
    .get(`/Users/Musician/Status/${minStatus}`, { params })

  unsubscribeMarketingMail = data => this.request.get('/Users/UnsubscribeMarketingMail', {
    params: data,
  })

  findUserByEmailOrMobile = data => this.request.get('Users/FindUserByEmailOrMobile', {
    params: data,
  })

  inviteToAddRole = data => this.request.post('Users/InviteToAddRole', data)

  acceptRole = data => this.request.post(`Users/AcceptRole?key=${data.key}`)

  getOpenRegistrationInvites = data => this.request.get('Musicians/OpenRegistrationInvites', {
    params: data,
  })

  updateOpenRegistrationInviteById = (inviteId, data) => this.request.put(`Musicians/RegistrationInvite/${inviteId}`, data)

  deleteOpenRegistrationInviteById = inviteId => this.request.del(`Musicians/RegistrationInvite/${inviteId}`)

  resendOpenRegistrationInvites = () => this.request.get('Musicians/RegistrationInvite/Resend')

  hideMusicianGuideline = id => this.request.put(`Users/Musician/${id}/HideGuideline`)

  updateMusicianBio = (id, data) => this.request.put(`Users/Musician/${id}/Bio`, data)

  updateMusicianPayment = (id, data) => this.request.put(`Users/Musician/${id}/Payment`, data)

  updateMusicianEquipment = (id, data) => this.request.put(`Users/Musician/${id}/Information/equipment`, data)

  updateMusicianMeal = (id, data) => this.request.put(`Users/Musician/${id}/Information/meal`, data)

  getInformationPreferences = () => this.request.get('Users/InformationPreferences')

  getUserTermsAndConditionsAcceptanceList = id => this.request.get(`User/${id}/TermsAndConditions`)

  userAcceptTCs = (id, data) => this.request.post(`User/${id}/AgreedTCs`, data)

  getUserAgreementDocumentUrl = (userId, cityId, isMusician) => this.request.get(`/User/${userId}/UserTermsAndConditions/${cityId}?isMusician=${isMusician}`)
}
