import {
  ORG_TYPES as orgTypes,
  ROLE_TYPES as roleTypes,
} from '../flynk.app.web.core.components/constants'

export const UserDisplay = {
  1: 'Admin',
  4: 'Sales Manager',
}

export const GENDER_TYPES = {
  Male: 1,
  Female: 2,
}

export const ORG_TYPES = {
  ...orgTypes,
  City: 45,
}

export const ROLE_TYPES = {
  ...roleTypes,
  Musician: 32,
}

export const ROLE_TYPE_NAMES = {
  Normal: 'Normal',
  Musician: 'Musician',
}

export const USER_MODES = {
  None: 0,
  // Individual
  Individual: 1,

  // OrgMember
  OrgMember: 2,

  // Application Admin
  OrgAdmin: 4,
  Partner: 8,

  // Internal User
  SupportManager: 16,
  Support: 32,
  SysAdmin: 64,

  // Specific User
  NonUser: 128,
  Musician: 256,
}

export const USER_GENDER_TYPES = {
  1: 'Male',
  2: 'Female',
}

export const VisibilityLevels = {
  None: 0,
  Public: 10,
  Restricted: 20,
  Limited: 30,
  Member: 40,
  Manager: 50,
  Owner: 100,
}
