export default class InvoiceAPI {
  constructor(args) {
    this.request = args.request
  }

  getInvoices = () => this.request.get('/Bills/Properties')

  getInvoiceById = id => this.request.get(`/Bills/${id}`)

  addInvoice = data => this.request.post('/Bills', data)

  updateInvoice = data => this.request.put('/Bills', data)

  deleteInvoice = id => this.request.del(`/Bills/${id}`)
}
