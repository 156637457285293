import React from 'react'
import PropTypes from 'prop-types'
import { Spin } from 'antd'
import styled from 'styled-components'

const OverlayStyled = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  width: 100vw;
  z-index: 1000;
`

const LoadingWrapperStyled = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  z-index: 1001;
`

const Loading = ({ loadingWrapperClassName }) => (
  <OverlayStyled>
    <LoadingWrapperStyled className={loadingWrapperClassName}>
      <Spin size="large" tip="Loading..." />
    </LoadingWrapperStyled>
  </OverlayStyled>
)

Loading.propTypes = {
  loadingWrapperClassName: PropTypes.string,
}

Loading.defaultProps = {
  loadingWrapperClassName: '',
}

export default Loading
