import { observable, action, runInAction } from 'mobx'
import { persist } from 'mobx-persist'
import { ALERT_TIMER } from '../../constants'
import resettableMixin from '../resettableMixin'

@resettableMixin
class PaymentStore {
  @persist('list') @observable payments = []
  @persist('object') @observable payment = {}

  constructor(args) {
    this.rootStore = args.rootStore
    this.rootAPI = args.rootAPI
  }

  @action.bound
  async getPayments() {
    try {
      const res = await this.rootAPI.paymentAPI.getPayments()
      runInAction(() => {
        this.payments = res.payload
      })
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    }
  }

  @action.bound
  clearPayments() {
    this.payments = []
  }

  @action.bound
  async getPaymentById(id) {
    try {
      const res = await this.rootAPI.paymentAPI.getPaymentById(id)
      if (res.payload) {
        runInAction(() => {
          this.payment = res.payload
        })
      }
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    }
  }

  @action.bound
  async addPayment(data) {
    try {
      await this.rootAPI.paymentAPI.addPayment(data)
      this.rootStore.alertStore.success({
        title: 'The payment has been added successfully!',
        timer: ALERT_TIMER,
      })
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    }
  }

  @action.bound
  async updatePayment(id, data) {
    try {
      await this.rootAPI.paymentAPI.updatePayment(id, data)
      this.rootStore.alertStore.success({
        title: 'The payment has been updated successfully!',
        timer: ALERT_TIMER,
      })
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    }
  }

  @action.bound
  async deletePayment(id) {
    try {
      await this.rootAPI.paymentAPI.deletePayment(id)
      const index = this.payments.findIndex(item => item.id === id)
      if (index >= 0) {
        runInAction(() => {
          this.payments.splice(index, 1)
        })
      }
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    }
  }

  @action.bound
  clearPayment() {
    this.payment = {}
  }
}

export default PaymentStore
