export default class BookingAPI {
  constructor(args) {
    this.request = args.request
  }

  getRegions = data => this.request.get('/Venues/Regions', {
    params: data,
    headers: {},
  })

  getCities = data => this.request.get('/Venues/Cities', {
    params: data,
    headers: {},
  })

  createContext = data => this.request.post('/Context', data, {
    headers: {},
  })

  getContext = cityId => this.request.get(`/Context/City/${cityId}`, {
    headers: {},
  })

  createBooking = data => this.request.post('/Bookings', data, {
    headers: {},
  })

  createBookingAdmin = data => this.request.post('/Bookings/Add', data)

  updateBooking = (bookingId, data) => this.request.put(`/Booking/${bookingId}/Customer`, data, {
    headers: {},
  })

  updateBookingNote = (bookingId, data) => this.request.put(`/Booking/${bookingId}/Note`, data, {
    headers: {},
  })

  getBookingById = bookingId => this.request.get(`/Booking/${bookingId}`)

  updateEventTime = (eventId, data) => this.request.put(`/Bookings/Event/${eventId}/Time`, data, {
    headers: {},
  })

  updateGigVenue = data => this.request.put('/Bookings/GigVenues', data, {
    headers: {},
  })

  updateGigNotSureWhere = data => this.request.put('/Bookings/GigNotSureWhere', data, {
    headers: {},
  })

  getEventGroups = data => this.request.get('/Bookings/EventGroups', {
    params: data,
    headers: {},
  })

  getAvailablePackages = data => this.request.get('/Bookings/AvailablePackages', {
    params: data,
    headers: {},
  })

  selectPackage = data => this.request.put('/Bookings/SelectPackage', {}, {
    params: data,
    headers: {},
  })

  deselectPackage = data => this.request.put('/Bookings/DeselectPackage', {}, {
    params: data,
    headers: {},
  })

  getPerformances = (data, requestBody) => this.request.post(`/Bookings/Performances?performanceTypeId=${data.performanceTypeId}&duration=${data.duration}&cityId=${data.cityId}`, requestBody)

  selectPerformance = data => this.request.put('/Bookings/SelectPerformance', data, {
    headers: {},
  })

  deselectPerformance = data => this.request.put('/Bookings/DeSelectPerformance', data, {
    headers: {},
  })

  getPerformers = params => this.request.get('/Bookings/Performers', {
    params,
    headers: {},
  })

  updateSelectedPerformers = data => this.request.put('/Bookings/EventPerformerPreferences', data, {
    headers: {},
  })

  getSelectedPerformers = params => this.request
    .get('/Bookings/EventPerformerPreferences', { params })

  updateChooseForMe = data => this.request.put('/Bookings/ChooseForMe', {}, {
    params: data,
    headers: {},
  })

  updateGigDuration = (gigId, data) => this.request.put(`/Gig/${gigId}/Duration`, data, {
    headers: {},
  })

  submitEnquiry = data => this.request.post('/Bookings/SubmitEnquiry', data, {
    headers: {},
  })

  getBookingSheet = bookingId => this.request.get(`Booking/${bookingId}/BookingSheet`, {
    headers: {},
  })
}
