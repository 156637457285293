import React from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import { useStores } from 'flynk.app.web.core.components/helpers'
import Footer from '../components/Footer'

const FooterContainer = ({
  hasStickyNav,
}) => {
  const {
    bookingStore: {
      selectedCity,
      websiteUrl,
    },
  } = useStores()

  return (
    <Footer
      hasStickyNav={hasStickyNav}
      selectedCity={selectedCity}
      websiteUrl={websiteUrl}
    />
  )
}

FooterContainer.propTypes = {
  hasStickyNav: PropTypes.bool,
}

FooterContainer.defaultProps = {
  hasStickyNav: false,
}

export default observer(FooterContainer)
