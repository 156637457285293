export const DEFAULT_PAGE_SIZE = 20

export const DEFAULT_PAGE_SIZE_OPTIONS = ['20', '30', '40', '50']

export const DEFAULT_PAGINATION = {
  current: 1,
  currentPage: 1,
  pageSize: DEFAULT_PAGE_SIZE,
  pageSizeOptions: DEFAULT_PAGE_SIZE_OPTIONS,
  showQuickJumper: true,
  showSizeChanger: true,
}

export const SetDefaultPagination = (pagination, res) => ({
  ...pagination,
  current: res.payload.currentPage,
  currentPage: res.payload.currentPage,
  hasNextPage: res.payload.hasNextPage,
  hasPreviousPage: res.payload.hasPreviousPage,
  pageSize: res.payload.pageSize,
  total: res.payload.totalItems,
  totalItems: res.payload.totalItems,
  totalPages: res.payload.totalPages,
})
