import {
  isValidNumber,
  Metadata,
  parsePhoneNumber,
  validatePhoneNumberLength
} from 'libphonenumber-js'
import minMetadata from 'libphonenumber-js/metadata.min'

export const validatePhoneNumber = (number) => {
  let valid
  try {
    valid = isValidNumber(number)
  } catch (e) {
    valid = false
  }

  return valid
}

export const formatPhoneNumber = (number, region) => {
  try {
    const parsed = parsePhoneNumber(number, region.toUpperCase())
    return parsed.number
  } catch (e) {
    return null
  }
}

// get number without the country code like +61 and etc
export const getNationalNumber = (number, region) => {
  try {
    const parsed = parsePhoneNumber(number, region.toUpperCase())

    return parsed.format('NATIONAL', { nationalPrefix: true }).replace(' ', '')
  } catch (e) {
    return null
  }
}

export const getInternationalNumber = (number, region) => {
  try {
    const parsed = parsePhoneNumber(number, region.toUpperCase())

    return parsed.format('INTERNATIONAL')
  } catch (e) {
    return null
  }
}

export const hasMoreDigits = (number, country) => {
  const formattedNumber = number.replace(/[^\d;]/g, '')
  const metadata = new Metadata(minMetadata)
  // Select a country.
  metadata.country(country.toUpperCase()) // get data about numbers in current country
  const possibleDigits = metadata.numberingPlan.possibleLengths() // get possible length of numbers
  const maxDigits = Math.max(...possibleDigits) // get max possible length

  return formattedNumber.length > maxDigits
}

export const customPhoneValidation = (number = '', country) => {
  const reg = /([^\d|\s]|\(|\))+/g
  if (validatePhoneNumberLength(number, country.toUpperCase()) === 'TOO_LONG') {
    return number.replace(reg, '')
      .slice(0, number.length - 1)
  }
  return number.replace(reg, '') || ''
}
