import React from 'react'
import { MobXProviderContext } from 'mobx-react'

export function useStores() {
  return React.useContext(MobXProviderContext)
}

export const getTicks = dateObject => ((dateObject.getTime() * 10000) + 621355968000000000)

export const conditionedClassName = conditions => Object
  .entries(conditions)
  .reduce((sum, [key, value]) => {
    if (value) {
      sum.push(key)
    }

    return sum
  }, [])
  .join(' ')
