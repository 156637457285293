import { isProd } from './env'

const Debug = {
  log(content) {
    let loggedContent = content
    if (isProd) return
    if (typeof content === 'object') {
      loggedContent = JSON.stringify(content)
    }
    console.log(`[RS] ${loggedContent}`) // eslint-disable-line no-console
  },

  error(err) {
    if (isProd) return
    console.error(err) // eslint-disable-line no-console
  },
}

export default Debug
