import { observable, action, runInAction } from 'mobx'
import { persist } from 'mobx-persist'
import { ALERT_TIMER } from '../../constants'
import resettableMixin from '../resettableMixin'

@resettableMixin
class IssueStore {
  @persist('list') @observable issues = []
  @persist('object') @observable issue = {}
  @persist('list') @observable comments = []

  constructor(args) {
    this.rootStore = args.rootStore
    this.rootAPI = args.rootAPI
  }

  @action.bound
  async getIssues(userId) {
    try {
      const res = await this.rootAPI.issueAPI.getIssues(userId)
      runInAction(() => {
        this.issues = res.payload
      })
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    }
  }

  @action.bound
  clearIssues() {
    this.issues = []
  }

  @action.bound
  async getIssueById(id) {
    try {
      const res = await this.rootAPI.issueAPI.getIssueById(id)
      if (res.payload) {
        runInAction(() => {
          this.issue = res.payload
        })
      }
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    }
  }

  @action.bound
  async addIssue(data) {
    try {
      await this.rootAPI.issueAPI.addIssue(data)
      this.rootStore.alertStore.success({
        title: 'The issue has been added successfully!',
        timer: ALERT_TIMER,
      })
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    }
  }

  @action.bound
  async updateIssue(data) {
    try {
      await this.rootAPI.issueAPI.updateIssue(data)
      this.rootStore.alertStore.success({
        title: 'The issue has been updated successfully!',
        timer: ALERT_TIMER,
      })
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    }
  }

  @action.bound
  async deleteIssue(id) {
    try {
      await this.rootAPI.issueAPI.deleteIssue(id)
      const index = this.issues.findIndex(item => item.id === id)
      if (index >= 0) {
        runInAction(() => {
          this.issues.splice(index, 1)
        })
      }
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    }
  }

  @action.bound
  clearIssue() {
    this.issue = {}
  }

  @action.bound
  async getIssueCommentsByMode(id) {
    try {
      const res = await this.rootAPI.issueAPI.getIssueCommentsByMode(id)
      if (res.payload) {
        runInAction(() => {
          this.comments = res.payload
        })
      }
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    }
  }

  @action.bound
  async addIssueComment(id, data) {
    try {
      await this.rootAPI.issueAPI.addIssueComment(id, data)
      await this.getIssueCommentsByMode(id)
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    }
  }

  @action.bound
  clearComments() {
    this.comments = []
  }
}

export default IssueStore
