import { RouterStore, syncHistoryWithStore } from 'mobx-react-router'
import { createBrowserHistory } from 'history'

import AlertStore from 'flynk.app.web.core.data/stores/Alert/AlertStore'
import BankStore from 'flynk.app.web.core.data/stores/Bank/BankStore'
import ClientAppFeatureStore from 'flynk.app.web.core.data/stores/ClientApp/ClientAppFeatureStore'
import ClientAppShortlistStore from 'flynk.app.web.core.data/stores/ClientApp/ClientAppShortlistStore'
import ClientAppStore from 'flynk.app.web.core.data/stores/ClientApp/ClientAppStore'
import ConfigStore from 'flynk.app.web.core.data/stores/Config/ConfigStore'
import ErrorsStore from 'flynk.app.web.core.data/stores/Errors/ErrorsStore'
import InvitationStore from 'flynk.app.web.core.data/stores/User/InvitationStore'
import InvoiceStore from 'flynk.app.web.core.data/stores/Invoice/InvoiceStore'
import IssueStore from 'flynk.app.web.core.data/stores/Issue/IssueStore'
import ModalsStore from 'flynk.app.web.core.data/stores/Modals/ModalsStore'
import NavStore from 'flynk.app.web.core.data/stores/Navigation/NavStore'
import OrganisationStore from 'flynk.app.web.core.data/stores/Organisation/OrganisationStore'
import PaymentStore from 'flynk.app.web.core.data/stores/Payment/PaymentStore'
import ProductFeatureStore from 'flynk.app.web.core.data/stores/Product/ProductFeatureStore'
import ProductShortlistStore from 'flynk.app.web.core.data/stores/Product/ProductShortlistStore'
import ProductStore from 'flynk.app.web.core.data/stores/Product/ProductStore'
import SupplierStore from 'flynk.app.web.core.data/stores/Supplier/SupplierStore'

import AuthStore from './Auth/AuthStore'
import BookingAdminStore from './Booking/BookingAdminStore'
import BookingStore from './Booking/BookingStore'
import CityStore from './City/CityStore'
import OfferStore from './Offer/OfferStore'
import PerformerStore from './Performer/PerformerStore'
import PreferencesStore from './Preferences/PreferencesStore'
import ProfileStore from './Profile/ProfileStore'
import ProspectStore from './Prospect/ProspectStore'
import ReportStore from './Report/ReportStore'
import RootAPI from '../api'
import UserStore from './User/UserStore'
import VenuesStore from './Venues/VenuesStore'

const browserHistory = createBrowserHistory()
const routingStore = new RouterStore()

export const history = syncHistoryWithStore(browserHistory, routingStore)

class RootStore {
  constructor() {
    const rootAPI = new RootAPI({ rootStore: this })

    this.alertStore = new AlertStore({ rootStore: this, rootAPI })
    this.authStore = new AuthStore({ rootStore: this, rootAPI })
    this.bankStore = new BankStore({ rootStore: this, rootAPI })
    this.bookingStore = new BookingStore({ rootStore: this, rootAPI })
    this.bookingAdminStore = new BookingAdminStore({ rootStore: this, rootAPI })
    this.cityStore = new CityStore({ rootStore: this, rootAPI })
    this.clientAppStore = new ClientAppStore({ rootStore: this, rootAPI })
    this.clientAppFeatureStore = new ClientAppFeatureStore({
      rootStore: this,
      rootAPI,
    })
    this.clientAppShortlistStore = new ClientAppShortlistStore({
      rootStore: this,
      rootAPI,
    })
    this.configStore = new ConfigStore({ rootStore: this, rootAPI })
    this.errorsStore = new ErrorsStore({ rootStore: this, rootAPI })
    this.invoiceStore = new InvoiceStore({ rootStore: this, rootAPI })
    this.invitationStore = new InvitationStore({ rootStore: this, rootAPI })
    this.issueStore = new IssueStore({ rootStore: this, rootAPI })
    this.modalsStore = new ModalsStore({ rootStore: this, rootAPI })
    this.navStore = new NavStore({ rootStore: this, rootAPI })
    this.offerStore = new OfferStore({ rootStore: this, rootAPI })
    this.organisationStore = new OrganisationStore({ rootStore: this, rootAPI })
    this.paymentStore = new PaymentStore({ rootStore: this, rootAPI })
    this.performerStore = new PerformerStore({ rootStore: this, rootAPI })
    this.productStore = new ProductStore({ rootStore: this, rootAPI })
    this.productFeatureStore = new ProductFeatureStore({ rootStore: this, rootAPI })
    this.productShortlistStore = new ProductShortlistStore({
      rootStore: this,
      rootAPI,
    })
    this.preferencesStore = new PreferencesStore({ rootStore: this, rootAPI })
    this.profileStore = new ProfileStore({ rootStore: this, rootAPI })
    this.prospectStore = new ProspectStore({ rootStore: this, rootAPI })
    this.supplierStore = new SupplierStore({ rootStore: this, rootAPI })
    this.userStore = new UserStore({ rootStore: this, rootAPI })
    this.venuesStore = new VenuesStore({ rootStore: this, rootAPI })
    this.reportStore = new ReportStore({ rootStore: this, rootAPI })
    this.routingStore = routingStore
  }
}

export default new RootStore()
