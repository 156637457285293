import { observable, action } from 'mobx'
import { persist } from 'mobx-persist'
import resettableMixin from '../resettableMixin'

@resettableMixin
class ModalsStore {
  @persist @observable avatarModalVisible = false

  constructor(args) {
    this.rootStore = args.rootStore
    this.rootAPI = args.rootAPI
  }

  @action.bound
  toggleAvatarModal({ visible }) {
    this.avatarModalVisible = visible
  }
}

export default ModalsStore
