import { action, computed, observable, runInAction } from 'mobx'
import get from 'lodash/get'
import { persist } from 'mobx-persist'
import { notification } from 'antd'
import BaseUserStore from 'flynk.app.web.core.data/stores/User/UserStore'
import { ALERT_TIMER, DEFAULT_PAGINATION, USER_STATUSES } from 'flynk.app.web.core.data/constants'
import { setCorrectContentTypes } from '../../helpers/booking'
import { VisibilityLevels } from '../../constants'

export default class UserStore extends BaseUserStore {
  @observable salesManagerInvitationModalVisible = false
  @observable musicianInvitationModalVisible = false
  @observable musicianEditModalVisible = false
  @observable sendRegistrationInviteForSalesMangerLoading = false
  @observable sendRegistrationInviteForMusicianLoading = false
  @observable musician = {}
  @observable updateMusicianLoading = false
  @observable getMusicianByIdLoading = false
  @observable unsubscribeMarketingMailLoading = false
  @observable unsubscribeMarketingMailError = {}
  @observable findUserByEmailOrMobileLoading = false
  @observable inviteToAddRoleLoading = false
  @observable acceptRoleLoading = false
  @observable acceptRoleError = {}
  @observable userPreferences = {}
  @observable getUserPreferencesLoading = false
  @observable updatePaymentLoading = false
  @observable updateMusicianEquipmentLoading = false
  @observable updateMusicianMealLoading = false
  @observable updateMusicianBioLoading = false
  @observable tcAcceptanceListModalVisible = false
  @observable downloadUserAgreementLoading = false

  @persist @observable unsubscribedMarketingMail = false
  @persist @observable roleAccepted = false

  @action.bound
  async getInformationPreferences() {
    if (this.getUserPreferencesLoading) {
      return
    }
    this.getUserPreferencesLoading = true

    try {
      const res = await this.rootAPI.userAPI.getInformationPreferences()
      if (res) {
        this.userPreferences = res.payload
      }
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    } finally {
      this.getUserPreferencesLoading = false
    }
  }

  @action.bound
  async updateMusicianPayment(id, data) {
    if (this.updatePaymentLoading) {
      return
    }
    this.updatePaymentLoading = true

    try {
      const res = await this.rootAPI.userAPI.updateMusicianPayment(id, data)
      if (res) {
        notification.success({
          message: 'Success!',
          description: 'Update musician payment success',
          placement: 'bottomRight',
        })
      }
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    } finally {
      this.updatePaymentLoading = false
    }
  }

  @action.bound
  async updateMusicianEquipment(id, data) {
    if (this.updateMusicianEquipmentLoading) {
      return
    }
    this.updateMusicianEquipmentLoading = true

    try {
      const res = await this.rootAPI.userAPI.updateMusicianEquipment(id, data)
      if (res) {
        notification.success({
          message: 'Success!',
          description: 'Update Equipment success',
          placement: 'bottomRight',
        })
      }
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    } finally {
      this.updateMusicianEquipmentLoading = false
    }
  }

  @action.bound
  async getUploadPreviewImageUrl(id, data) {
    this.updateMusicianLoading = true
    try {
      const { payload } = await this.rootAPI.performerAPI.GetPerformerPreviewImageUrl(id, data)
      if (payload) {
        const { file } = data
        const contentType = setCorrectContentTypes(file.name)
        await this.rootAPI.performerAPI.uploadPreviewImageUrl(
          payload.signedUrl,
          file,
          contentType,
        )
      }
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    } finally {
      this.updateMusicianLoading = false
    }
  }

  @action.bound
  async updateMusicianBio(data, fileList) {
    if (this.updateMusicianLoading) {
      return
    }

    this.updateMusicianLoading = true

    try {
      const performers = [...this.musician?.performers]
      performers[0].bio = data.bio
      performers[0].videoUrl = data.videoUrl
      performers[0].contentName = data.contentName
      performers[0].contentAuthor = data.contentAuthor
      performers[0].alias = data.alias
      performers[0].surcharge = data.surcharge
      performers[0].artistFee = data.artistFee
      performers[0].visibility =
        data.visibility ? VisibilityLevels.Public : VisibilityLevels.Manager
      const formattedMusician = { ...this.musician, performers }
      const formatted = {
        ...formattedMusician,
        skillIds: data.skillIds,
        canLead: data.canLead,
        privateNote: data.privateNote,
      }
      const res = await this.rootAPI.userAPI.updateMusicianBio(formatted.id, formatted)
      if (res) {
        if (res.type === 0 && res.message) {
          this.rootStore.errorsStore.addError({
            description: res.message,
          })
        } else {
          if (Array.isArray(fileList) && fileList.length > 0) {
            const promises = fileList.map(file =>
              this.getUploadPreviewImageUrl(
                performers[0].id,
                {
                  objectName: file.name,
                  file,
                },
              ))
            await Promise.all(promises)
          }
          notification.success({
            message: 'Success!',
            description: 'The musician information has been updated successfully!',
            placement: 'bottomRight',
          })
          this.getMusicianById(formatted.id)
        }
      }
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    } finally {
      this.updateMusicianLoading = false
    }
  }

  @action.bound
  async uploadMusicianAvatar(musicianId, data) {
    const res = await this.rootAPI.profileAPI.getAvatarPresignedUrl(musicianId, data)

    if (res && res.payload) {
      const { file, contentType } = data

      await this.rootAPI.profileAPI.uploadAvatar(res.payload.signedUrl, file, contentType)
    }
  }

  @action.bound
  async updateMusicianPersonalInformation(data) {
    if (this.updateMusicianLoading) {
      return
    }
    this.updateMusicianLoading = true
    const musician = { ...this.musician }
    try {
      if (data.avatar && data.avatar.objectName && data.avatar.file) {
        await this.uploadMusicianAvatar(musician.id, data.avatar)
        if (data.croppedAvatar) {
          musician.avatar = data.croppedAvatar
        }
      }

      const formattedMusician = { ...musician, ...data.user }
      const res =
        await this.rootAPI.userAPI.updateMusicianBio(formattedMusician.id, formattedMusician)
      if (res) {
        if (res.type === 0 && res.message) {
          this.rootStore.errorsStore.addError({
            description: res.message,
          })
        } else {
          notification.success({
            message: 'Success!',
            description: 'The musician information has been updated successfully!',
            placement: 'bottomRight',
          })
          this.getMusicianById(formattedMusician.id)
        }
      }
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    } finally {
      this.updateMusicianLoading = false
    }
  }

  @action.bound
  async updateMusicianMeal(id, data) {
    if (this.updateMusicianMealLoading) {
      return
    }
    this.updateMusicianMealLoading = true

    try {
      const res = await this.rootAPI.userAPI.updateMusicianMeal(id,
        { InformationPreferences: data })
      if (res) {
        notification.success({
          message: 'Success!',
          description: 'Update Meal success',
          placement: 'bottomRight',
        })
      }
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    } finally {
      this.updateMusicianMealLoading = false
    }
  }

  @action.bound
  toggleSalesManagerInvitationModal({ visible }) {
    this.salesManagerInvitationModalVisible = visible
  }

  @action.bound
  toggleMusicianInvitationModal({ visible }) {
    this.musicianInvitationModalVisible = visible
  }

  @action.bound
  toggleMusicianEditModal({ visible }) {
    this.musicianEditModalVisible = visible
  }

  @action.bound
  async sendRegistrationInviteForSalesManger(data) {
    this.sendRegistrationInviteForSalesMangerLoading = true

    try {
      const res = await this.rootAPI.userAPI.sendRegistrationInviteForSalesManger(data)
      if (res) {
        if (res.type === 0 && res.message) {
          this.rootStore.errorsStore.addError({
            description: res.message,
          })
        } else {
          this.rootStore.alertStore.success({
            title: 'Your invitation has been sent!',
            timer: ALERT_TIMER,
          })
          this.toggleSalesManagerInvitationModal({ visible: false })
        }
      }
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    } finally {
      this.sendRegistrationInviteForSalesMangerLoading = false
    }
  }

  @action.bound
  async sendRegistrationInviteForMusician(data) {
    this.sendRegistrationInviteForMusicianLoading = true

    try {
      const { currentRoleId, isSalesManager, roleInOrgOfSalesManager } = this.rootStore.profileStore
      const roleId = isSalesManager ? roleInOrgOfSalesManager.id : currentRoleId
      const res = await this.rootAPI.userAPI.sendRegistrationInviteForMusician(data, roleId)

      if (res) {
        if (res.type === 0 && res.message) {
          this.rootStore.errorsStore.addError({
            description: res.message,
          })
        } else {
          this.rootStore.alertStore.success({
            title: 'Your invitation has been sent!',
            timer: ALERT_TIMER,
          })
          this.toggleMusicianInvitationModal({ visible: false })
        }
      }
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    } finally {
      this.sendRegistrationInviteForMusicianLoading = false
    }
  }

  @action.bound
  async getMusicianById(id) {
    this.getMusicianByIdLoading = true

    try {
      const res = await this.rootAPI.userAPI.getMusicianById(id,
        this.rootStore.cityStore.selectedCityId)

      if (res && res.payload) {
        const musician = res.payload
        const skillIds = get(musician, 'skills', [])
          .map(skill => skill.id)
        const avatar = get(musician, 'avatarSet.thumbnails[0].url', '')

        this.musician = {
          ...musician,
          skillIds,
          avatar,
        }
      }
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    } finally {
      this.getMusicianByIdLoading = false
    }
  }

  @action.bound
  async updateMusician(data) {
    if (this.updateMusicianLoading) {
      return
    }

    this.updateMusicianLoading = true

    try {
      const formatted = { ...this.musician, ...data }

      const res = await this.rootAPI.userAPI.updateMusician(formatted.id, formatted)
      if (res) {
        if (res.type === 0 && res.message) {
          this.rootStore.errorsStore.addError({
            description: res.message,
          })
        } else {
          this.rootStore.alertStore.success({
            title: 'The musician information has been updated successfully!',
            timer: ALERT_TIMER,
          })
          this.toggleMusicianEditModal({ visible: false })
          this.getMusicianById(formatted.id)
        }
      }
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    } finally {
      this.updateMusicianLoading = false
    }
  }

  @action.bound
  clearMusician() {
    this.musician = {}
  }

  /*    Artists    */

  @observable getMusiciansListLoading = false
  @observable musiciansUserList = []
  @observable currentMusician = {}
  @persist('object') @observable pagination = DEFAULT_PAGINATION

  @computed get currentSelectedMusicianBio() {
    return this.musiciansUserList
      .find(el => el.selected)
  }

  @action.bound
  setPagination = (pagination) => {
    this.pagination = pagination
  }

  @action.bound
  async getMusiciansList(minStatus = USER_STATUSES.Onboarding, params = {
    skip: 0,
    take: this.pagination.pageSize,
  }) {
    this.getMusiciansListLoading = true
    try {
      const { payload } = await this.rootAPI.userAPI
        .getMusiciansList(minStatus, params)
      if (payload) {
        runInAction(() => {
          this.musiciansUserList = payload.items
          this.setPagination({
            ...this.pagination,
            currentPage: payload.currentPage || payload.page,
            current: payload.currentPage || payload.page,
            pageSize: payload.pageSize,
            total: payload.totalItems,
          })

          const { history } = this.rootStore.routingStore
          history.push({
            search: `?skip=${params.skip}&take=${payload.pageSize}`,
          })
        })
      }
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    } finally {
      this.getMusiciansListLoading = false
    }
  }

  @action.bound
  async selectMusicianBio(id) {
    try {
      this.musiciansUserList = this.musiciansUserList.map((el) => {
        if (el.id === id) {
          return {
            ...el,
            selected: true,
          }
        }
        return {
          ...el,
          selected: false,
        }
      })
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    }
  }

  @action.bound
  async unsubscribeMarketingMail(params) {
    this.unsubscribeMarketingMailLoading = true
    try {
      await this.rootAPI.userAPI.unsubscribeMarketingMail(params)

      runInAction(() => {
        this.unsubscribedMarketingMail = true
      })
    } catch (err) {
      this.unsubscribeMarketingMailError = err
    } finally {
      this.unsubscribeMarketingMailLoading = false
    }
  }

  @action.bound
  async findUserByEmailOrMobile(data) {
    this.findUserByEmailOrMobileLoading = true

    try {
      const res = await this.rootAPI.userAPI.findUserByEmailOrMobile(data)

      if (res && res.payload) {
        return res.payload
      }
    } catch (err) {
      if (err.type < -1) {
        this.rootStore.errorsStore.addError(err)
      }
    } finally {
      this.findUserByEmailOrMobileLoading = false
    }
    return null
  }

  @action.bound
  async inviteToAddRole(data) {
    this.inviteToAddRoleLoading = true

    try {
      const res = await this.rootAPI.userAPI.inviteToAddRole(data)

      if (res) {
        runInAction(() => {
          this.toggleMusicianInvitationModal({ visible: false })
          this.toggleSalesManagerInvitationModal({ visible: false })
        })
      }
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    } finally {
      this.inviteToAddRoleLoading = false
    }
  }

  @action.bound
  async acceptRole(data) {
    this.acceptRoleLoading = true

    try {
      await this.rootAPI.userAPI.acceptRole(data)

      runInAction(() => {
        this.roleAccepted = true
      })
    } catch (err) {
      this.acceptRoleError = err
    } finally {
      this.acceptRoleLoading = false
    }
  }

  @observable openMusiciansInvitesModalVisible = false
  @observable getOpenRegistrationInvitesLoading = false
  @observable editedInvite = {}
  @observable updateOpenRegistrationInviteByIdLoading = false
  @observable deleteOpenRegistrationInviteByIdLoading = false
  @observable loadingUserTermAndConditionsAcceptanceList = false
  @observable loadingUserAcceptTermsAndConditions = false
  @persist('object') @observable openMusiciansInvitesPagination = DEFAULT_PAGINATION
  @observable openMusiciansInvites = []

  @action.bound
  toggleOpenInvitesModal({ visible }) {
    this.openMusiciansInvitesModalVisible = visible
  }

  @action.bound
  setEditedInvite(invite) {
    this.editedInvite = invite
  }

  @action.bound
  clearEditedInvite() {
    this.setEditedInvite({})
  }

  @action.bound
  setOpenInvitesPagination = (pagination) => {
    this.openMusiciansInvitesPagination = pagination
  }

  @action.bound
  async getOpenRegistrationInvites(data = {
    skip: 0,
    take: this.openMusiciansInvitesPagination.pageSize,
  }) {
    this.getOpenRegistrationInvitesLoading = true

    try {
      const { payload } = await this.rootAPI.userAPI.getOpenRegistrationInvites(data)

      if (payload && payload.items) {
        runInAction(() => {
          this.openMusiciansInvites = payload.items
          this.setOpenInvitesPagination({
            ...this.openMusiciansInvitesPagination,
            currentPage: payload.currentPage || payload.page,
            current: payload.currentPage || payload.page,
            pageSize: payload.pageSize,
            total: payload.totalItems,
          })
        })
      }
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    } finally {
      this.getOpenRegistrationInvitesLoading = false
    }
  }

  @action.bound
  async updateOpenRegistrationInviteById(inviteId, data) {
    this.updateOpenRegistrationInviteByIdLoading = true
    try {
      await this.rootAPI.userAPI.updateOpenRegistrationInviteById(inviteId, data)

      runInAction(async () => {
        this.openMusiciansInvites = this.openMusiciansInvites
          .map((invite) => {
            if (invite.id === inviteId) {
              return {
                ...invite,
                payload: {
                  ...invite.payload,
                  ...data,
                },
              }
            }
            return invite
          })

        this.rootStore.alertStore.success({
          title: 'The user has been updated successfully!',
          timer: ALERT_TIMER,
        })

        const { current, pageSize } = this.openMusiciansInvitesPagination
        const take = pageSize
        const skip = (current - 1) * pageSize
        const { payload } = await this.rootAPI.userAPI.getOpenRegistrationInvites({
          skip,
          take,
        })
        if (payload && payload.items) {
          this.openMusiciansInvites = payload.items
        }
      })
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    } finally {
      this.updateOpenRegistrationInviteByIdLoading = false
    }
  }

  @action.bound
  async deleteOpenRegistrationInviteById(inviteId) {
    this.deleteOpenRegistrationInviteByIdLoading = true

    try {
      await this.rootAPI.userAPI.deleteOpenRegistrationInviteById(inviteId)

      runInAction(() => {
        this.openMusiciansInvites = this.openMusiciansInvites
          .filter(invite => invite.id !== inviteId)
        this.rootStore.alertStore.success({
          title: 'The user has been deleted successfully!',
          timer: ALERT_TIMER,
        })
      })
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    } finally {
      this.deleteOpenRegistrationInviteByIdLoading = false
    }
  }

  @action.bound
  toggleTCAcceptanceListModal() {
    this.tcAcceptanceListModalVisible = !this.tcAcceptanceListModalVisible
  }

  @action.bound
  async getUserTermsAndConditionsAcceptanceList(id) {
    this.loadingUserTermAndConditionsAcceptanceList = true

    try {
      const { payload } = await this.rootAPI.userAPI.getUserTermsAndConditionsAcceptanceList(id)
      return payload
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    } finally {
      this.loadingUserTermAndConditionsAcceptanceList = false
    }
  }

  @action.bound
  async userAcceptTCs(id, data) {
    try {
      this.loadingUserAcceptTermsAndConditions = true
      await this.rootAPI.userAPI.userAcceptTCs(id, data)
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    } finally {
      this.loadingUserAcceptTermsAndConditions = false
      if (this.rootStore.profileStore.isMusician) {
        this.rootStore.routingStore.push('/musician/offers')
      } else {
        this.rootStore.routingStore.push('/prospects')
      }
    }
  }

  @action.bound
  async getUserAgreementDocumentUrl(userId, cityId, isMusician = true) {
    try {
      const { payload } = await this.rootAPI.userAPI.getUserAgreementDocumentUrl(
        userId,
        cityId,
        isMusician,
      )
      return payload
    } catch (error) {
      this.rootStore.errorsStore.addError(error)
    }
  }

  @action.bound
  async downloadUserAgreement(userId) {
    try {
      this.downloadUserAgreementLoading = true

      const cityId = this.rootStore.profileStore.isMusician ?
        this.rootStore.profileStore.currentOrganisationId :
        this.rootStore.cityStore.selectedCityId

      if (this.rootStore.profileStore.isSalesManager) {
        // sales manager call download user agreement 2 times
        // one time agree for musician
        const musicianDocumentUrl = await this.getUserAgreementDocumentUrl(userId, cityId, true)
        if (musicianDocumentUrl) {
          window.open(musicianDocumentUrl)
        }
        // one time agree for sale manager
        const saleManagerDocumentUrl = await this.getUserAgreementDocumentUrl(userId, cityId, false)
        if (saleManagerDocumentUrl) {
          window.open(saleManagerDocumentUrl)
        }
      } else if (this.rootStore.profileStore.isMusician) {
        const musicianDocumentUrl = await this.getUserAgreementDocumentUrl(userId, cityId, true)
        if (musicianDocumentUrl) {
          window.open(musicianDocumentUrl)
        }
      }
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    } finally {
      this.downloadUserAgreementLoading = false
    }
  }
}
