import { action, computed, observable, runInAction } from 'mobx'
import { persist } from 'mobx-persist'
import { get, find } from 'lodash'
import { LINK_TYPES, USER_STATUSES } from 'flynk.app.web.core.data/constants'
import BaseProfileStore from 'flynk.app.web.core.data/stores/Profile/ProfileStore'
import { ORG_TYPES, ROLE_TYPES, USER_MODES } from '../../constants'

export default class ProfileStore extends BaseProfileStore {
  @observable preferredRole = {}
  @observable musicianGuideModalVisible = false
  @persist('list') @observable isNotAcceptedTCs = []

  @computed get isValidUser() {
    return this.isInternalUser || this.isOrgAdmin ||
      this.isSalesManager || this.isIndividual || this.isMusician
  }

  @computed
  get isInternalUser() {
    return [
      USER_MODES.SupportManager,
      USER_MODES.Support,
      USER_MODES.SysAdmin,
    ].includes(this.currentMode)
  }

  @computed
  get isSysAdmin() {
    return this.currentMode === USER_MODES.SysAdmin
  }

  @computed
  get isSupportManager() {
    return this.currentMode === USER_MODES.SupportManager
  }

  @computed
  get isSupport() {
    return this.currentMode === USER_MODES.Support
  }

  @computed get isSalesManager() {
    return (this.roleType & ROLE_TYPES.Normal) > 0
  }

  @computed get isMusician() {
    return (this.roleType & ROLE_TYPES.Musician) > 0
  }

  @computed get isAdmin() {
    return (this.roleType & ROLE_TYPES.Admin) > 0
  }

  @computed get currentOrganisationId() {
    if (this.isSalesManager) {
      return this.roleInOrgOfSalesManager.orgId || ''
    }
    return get(this.currentRole, 'orgId', '')
  }

  @computed get skills() {
    const positions = get(this.profile, 'positions', [])
    return positions.filter(role => role.type === ROLE_TYPES.None)
  }

  @computed get roleInOrgOfSalesManager() {
    if (this.isSalesManager) {
      const roles = get(this.rootStore.profileStore.profile, 'roles', [])
      if (roles && roles.length) {
        const roleInOrg = roles.find(r => r.type === ROLE_TYPES.Normal && !!get(r, 'organisation.id'))
        if (roleInOrg) {
          return roleInOrg
        }
      }
    }

    return {}
  }

  @computed
  get currentRole() {
    const { roles } = this.profile
    const currentRoleId = get(this.permissions, `${this.userId}.roles.current`, '')
    if (roles?.length > 0) {
      const currentRole = currentRoleId ? find(roles, { id: currentRoleId }) :
        find(roles, { id: this.preferredRole.id })
      if (currentRole) {
        return currentRole
      }
      return roles[roles.length - 1]
    }

    return {}
  }

  @action.bound
  setMusicianGuideModalVisible(visible) {
    this.musicianGuideModalVisible = visible
  }

  @action.bound
  updateMusicianGuideVisible() {
    const userId = get(this.profile, 'user.id', '')
    this.rootAPI.userAPI.hideMusicianGuideline(userId)
  }

  @action.bound
  async getContext() {
    try {
      const res = await this.rootAPI.authAPI.getContext()

      const {
        organisations: contextOrgs, preferredMode, preferredRoleId, roles, user,
      } = res.payload

      runInAction(() => {
        this.profile = {
          ...res.payload,
        }

        this.preferredRole = roles.find(el => el.id === preferredRoleId)
        const isShowGuideline = get(user, 'isShowGuideline', false)
        this.musicianGuideModalVisible =
          this.isMusician && isShowGuideline
      })
      let info = {}

      const {
        cityStore: { getRegionsAndCities },
        bookingAdminStore: { getPerformanceTypes },
      } = this.rootStore

      await getRegionsAndCities()

      // if (preferredMode === USER_MODES.Musician) {
      //   info = {
      //     organisations: contextOrgs,
      //     roles,
      //   }
      // } else
      if (roles?.length) {
        const organisations = []
        const rolePromises = await roles
          // hardcode to remove 'Normal' users
          // .filter(role => role.name.toLowerCase() !== 'normal')
          .map(async (role) => {
            const r = { ...role }
            const currentRoleOrg = contextOrgs.find(org => org.id === r.orgId)

            if (currentRoleOrg.orgType === ORG_TYPES.City) {
              const city = this.rootStore.cityStore.allCities.find(c => r.orgId === c.id)
              city.displayName = city.name
              r.organisation = city

              organisations.push({
                ...city,
                roleId: role.id,
              })
              return r
            }

            const orgRes = await this.rootAPI.organisationAPI
              .getOrganisationById(role.orgId, role.id)

            const organisation = orgRes.payload
            r.organisation = organisation

            organisations.push({
              ...organisation,
              roleId: role.id,
            })
            return r
          })

        try {
          const rs = await Promise.all(rolePromises)
          info = {
            roles: rs,
            organisations,
          }
        } catch (err) {
          this.rootStore.errorsStore.addError(err)
          info = {
            roles: null,
            organisation: null,
          }
        }
      }

      if (info.roles && info.organisations) {
        this.profile = {
          ...res.payload,
          ...info,
          avatar: get(user, 'avatarSet.thumbnails[0].url', ''),
        }
        this.setupPermission(preferredRoleId, preferredMode)
        await getPerformanceTypes()

        const { cities, selectCities } = this.rootStore.cityStore

        if (preferredMode === USER_MODES.Musician) { // isMusician
          const city = cities.find(c => roles.some(r => r.orgId === c.id))

          if (city != null) {
            selectCities([city.id])
          }
        }
        if (!this.rootStore.authStore ||
          !this.rootStore.authStore.isJustCompeleteRegistrationInvite) {
          const userNewestCityTCsAgreement = get(this.profile, 'user.userNewestCityTCsAgreement', [])
          const isNotAgreedNewestTCs = userNewestCityTCsAgreement.filter(tc => !tc.isAgreed)
          this.isNotAcceptedTCs = isNotAgreedNewestTCs
          if (isNotAgreedNewestTCs?.length) {
            this.rootStore.routingStore.push('/reaccept-tc')
          }
        }
      }
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    }
  }

  @action.bound
  async getOnboardUser(isRechecked) {
    if (isRechecked) {
      this.getOnboardUserLoading = true
    }

    const userStatus = get(this.profile, 'user.status', 0)
    if (this.userId && userStatus < USER_STATUSES.Enabled) {
      let email = this.profile.email ? { ...this.profile.email } : {}
      let mobile = this.profile.mobile ? { ...this.profile.mobile } : {}
      const user = this.profile.user ? { ...this.profile.user } : {}

      try {
        const res = await this.rootAPI.userAPI.getOnboardUser(this.userId)
        const invalidatedLinks = get(res, 'payload.invalidatedLinks', [])
        if (invalidatedLinks.length) {
          const invalidatedEmail = invalidatedLinks.find(item => item.type === LINK_TYPES.Email)
          const invalidatedMobile = invalidatedLinks.find(item => item.type === LINK_TYPES.Mobile)

          email = invalidatedEmail || {
            ...email,
            isValidated: true,
          }
          mobile = invalidatedMobile || {
            ...email,
            isValidated: true,
          }
        } else {
          email.isValidated = true
          mobile.isValidated = true
          user.status =
            userStatus > USER_STATUSES.Enabled ? userStatus : USER_STATUSES.Enabled
        }

        if (user.status >= USER_STATUSES.Enabled && this.rootStore.cityStore.cities.length === 0) {
          await this.rootStore.cityStore.getRegionsAndCities()
        }

        runInAction(() => {
          this.profile = {
            ...this.profile,
            email,
            mobile,
            user,
          }
        })
      } catch (err) {
        this.rootStore.errorsStore.addError(err)
      } finally {
        if (isRechecked) {
          this.getOnboardUserLoading = false
        }
      }
    }
  }
}
