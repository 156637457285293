// for debugging
import { toJS } from 'mobx'

export const unPack = value => toJS(value, { recurseEverything: true })

export const addUnPackToConsole = () => {
  console.unp = (args, name = null) => { // eslint-disable-line
    if (!args) {
      console.log('wrong arguments:', args) // eslint-disable-line
      return
    }

    const argsArray = Object.entries(args)
    const argsArrayLength = argsArray.length
    const options = []

    argsArray
      .forEach((el, i) => {
        const [key, val] = el
        options.push(`${key}:`)
        if (val?.nodeType) {
          options.push(val)
        } else {
          options.push(unPack(val))
        }
        if (i !== argsArrayLength - 1) {
          options.push('\n')
        }
      })
    if (name) console.log(`<-- ${name} -->`) // eslint-disable-line
    console.log(...options) // eslint-disable-line
  }
}
