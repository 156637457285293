import { observable, action } from 'mobx'
import { persist } from 'mobx-persist'
import resettableMixin from '../resettableMixin'
import { SCREEN_ORIENTATIONS } from '../../constants'

@resettableMixin
class NavStore {
  @persist @observable sidebarCollapsed = false
  @observable drawerCollapsed = true
  @observable orientation = null

  constructor(args) {
    this.rootStore = args.rootStore
    this.rootAPI = args.rootAPI
  }

  @action.bound
  setSidebarCollapsed(value) {
    this.sidebarCollapsed = value
  }

  @action.bound
  setOrientation() {
    if (window.screen?.orientation?.type === 'portrait-primary') {
      this.orientation = SCREEN_ORIENTATIONS.PORTRAIT

      // this.setSidebarCollapsed(true)
    }

    if (window.screen?.orientation?.type === 'landscape-primary') {
      this.orientation = SCREEN_ORIENTATIONS.LANDSCAPE
    }
  }

  @action.bound
  setDrawerCollapsed(value) {
    this.drawerCollapsed = value
    if (!this.drawerCollapsed) {
      this.setSidebarCollapsed(false)
    }
  }

  @action.bound
  handleOrientationChange() {
    const isPortrait = window.matchMedia('(orientation: portrait)').matches

    if (isPortrait) {
      this.orientation = SCREEN_ORIENTATIONS.PORTRAIT

      this.setSidebarCollapsed(true)
    } else {
      this.orientation = SCREEN_ORIENTATIONS.LANDSCAPE
    }
  }
}

export default NavStore
