const Colors = {
  BrownGold: '#b7964e',
  BrownGoldTransparent: 'rgba(183, 150, 78, 0.35)',
  Text: '#777',
  White: '#fff',
  DeartyWhite: '#FAFAFA',
  Dark: '#eee',
  Black: '#000',
  TransparentBlack: '#595959',
  LightGrey: '#faf9f9',
  Grey: '#e6e6e6',
  DarkGrey: '#9b9b9b',
  DarkGrey2: '#C8C8C8',
  Light: '#f6f6f6',
  QuoteBuilderSection: '#161616',
  Sidebar: '#434343',
  LightYellow: '#fff4dd',
  LightYellow2: '#c4ae74',
  Yellow: '#ffd373',
  Blue: '#4A90E2',
  Green: '#89D733',
  Red: '#B74E4E',
  CustomizeAntText: '#2f2f2f',
}

export const Theme = {
  Colors: {
    QuoteBuilderSection: Colors.QuoteBuilderSection,
    Primary: Colors.BrownGold,
    TransparentPrimary: Colors.BrownGoldTransparent,
    Text: Colors.Text,
    Dark: Colors.Dark,
    Black: Colors.Black,
    Blue: Colors.Blue,
    Green: Colors.Green,
    Red: Colors.Red,
    White: Colors.White,
    LightGrey: Colors.LightGrey,
    DarkGrey: Colors.DarkGrey,
    DarkGrey2: Colors.DarkGrey2,
    Grey: Colors.Grey,
    Light: Colors.Light,
    Sidebar: Colors.Sidebar,
    MainBg: Colors.White,
    AlternateBG: Colors.DeartyWhite,
    UsualBorders: Colors.Grey,
    PendingStatus: Colors.Yellow,
    LightYellow: Colors.LightYellow,
    LightYellow2: Colors.LightYellow2,
    Yellow: Colors.Yellow,
    BookingHeading: Colors.TransparentBlack,
    TransparentBlack: Colors.TransparentBlack,
    BookingHeadingArrows: Colors.DarkGrey,
  },
  Border: {
    Radius: 0,
  },
  Anim: {
    EaseInExpo: '.3s cubic-bezier(0.95, 0.05, 0.795, 0.035)',
  },
  FontSizes: {
    UsualBookingFZ: '1.4rem',
    UsualMobileFZ: '1.2rem',
  },
}
