export const getAddressFromGooglePlace = (place) => {
  const address = {}

  if (place.address_components) {
    const addressComponents = place.address_components
    if (addressComponents.find(e => e.types[0] === 'country')) {
      address.country = addressComponents.find(e => e.types[0] === 'country').long_name
    }

    if (addressComponents.find(e => e.types[0] === 'locality')) {
      address.city = addressComponents.find(e => e.types[0] === 'locality').long_name
    }

    if (addressComponents.find(e => e.types[0] === 'postal_code')) {
      address.code = addressComponents.find(e => e.types[0] === 'postal_code').short_name
    }

    if (addressComponents.find(e => e.types[0] === 'administrative_area_level_1')) {
      address.state = addressComponents.find(e => e.types[0] === 'administrative_area_level_1').short_name
    }

    let streetNumber = ''
    if (addressComponents.find(e => e.types[0] === 'street_number')) {
      streetNumber = addressComponents.find(e => e.types[0] === 'street_number').long_name
    }
    let street = ''
    if (addressComponents.find(e => e.types[0] === 'route')) {
      street = addressComponents.find(e => e.types[0] === 'route').long_name
    }
    let subpremise = ''
    if (addressComponents.find(e => e.types[0] === 'subpremise')) {
      subpremise = addressComponents.find(e => e.types[0] === 'subpremise').long_name
    }
    if (subpremise !== '') {
      address.line1 = `${subpremise}/${streetNumber} ${street}`
    } else if (streetNumber !== '') {
      address.line1 = `${streetNumber} ${street}`
    } else {
      address.line1 = place.placeName
    }
  }

  if (!!place.geometry && !!place.geometry.location) {
    const { lng } = place.geometry.location
    const { lat } = place.geometry.location

    address.long = typeof lng === 'function' ? lng() : lng
    address.lat = typeof lat === 'function' ? lat() : lat
  }

  return address
}

export function hasStreetNumber(place) {
  if (place.address_components) {
    const addressComponents = place.address_components
    if (addressComponents.find(e => e.types[0] === 'street_number')) {
      return true
    }

    const parts = place.placeName.split(' ', 2)
    return !Number.isNaN(parts[0])
  }
  return false
}

export const addressToString = (address) => {
  let addressString = ''

  if (address) {
    const name = address.name ? address.name : ''
    const city = address.city ? `, ${address.city}` : ''
    const state = address.state ? ` ${address.state}` : ''
    const country = address.country ? `, ${address.country}` : ''

    addressString = name + city + state + country
  }

  return addressString
}
