import { Modal } from 'antd'

export default class AlertStore {
  constructor(args) {
    this.rootStore = args.rootStore
    this.rootAPI = args.rootAPI
    this.defaultOptions = {
      maskClosable: true,
    }
  }

  _baseFn = type => (options) => {
    const modal = Modal[type]({
      ...this.defaultOptions,
      ...options,
    })

    if (options.timer) {
      setTimeout(() => {
        modal.destroy()
      }, options.timer)
    }
  }

  info = this._baseFn('info')
  success = this._baseFn('success')
  error = this._baseFn('error')
  warning = this._baseFn('warning')
  confirm = this._baseFn('confirm')
}
