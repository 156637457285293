import { action, toJS } from 'mobx'
import forOwn from 'lodash/forOwn'

const resettableMixin = Base => (class extends Base {
  _superInitialState

  constructor(args) {
    super(args)

    this._args = args
    this._superInitialState = toJS(this)
  }

  @action.bound
  reset() {
    if (!this._superInitialState) {
      return
    }

    const initialState = { ...this._superInitialState }

    forOwn(initialState, (value, key) => {
      if (key !== '_args' && !this._args[key]) {
        this[key] = initialState[key]
      }
    })
  }
})

export default resettableMixin
