export const RegExps = {
  OrganisationUsersPath: /organisations\/[\d\w-]+\/users/,
  UsersPath: /users/,
  MusicianBios: /musician_bios/,
  BookingDetailPath: /bookings\/[\d\w-]+/,
  BookingListPath: /bookings/,
  MusiciansPath: /musicians/,
  Reports: /reports/,
  IllegalCharacters: /[|&;$%@"<>()+,]/g,
  ABN: /^\d{2}\s\d{3}\s\d{3}\s\d{3}$/, // 00 000 000 000
  SSN: /^\d{3}-\d{2}-\d{4}$/, // 000-00-0000
  BusinessNumber: /^\d{9}$/, // 000000000
  SIN: /^\d{3}\s\d{3}\s\d{3}$/, // 000 000 000
  SydneyAccountNumber: /^\d{4,9}$/,
  BostonAccountNumber: /^.{1,17}$/,
  TorontoAccountNumber: /^\d{7,21}$/,
  BSB: /^\d{6}$/,
  ACHRoutingNumber: /^\d{9}$/,
  InstitutionNumber: /^\d{3}$/,
  TransitNumber: /^\d{5}$/,
  AccountName: /^.{2,}/,
  OneToTwoCharacters: /.{1,2}/g,
  OneToThreeCharacters: /.{1,3}/g,
  OneToFourCharacters: /.{1,4}/g,
  Hyphen: /-/g,
  WhiteSpace: /\s/g,
  DollarSignAndComma: /\$\s?|(,*)/g,
}

export const TABLE_DATE_FORMAT = 'ddd D MMM, YYYY'
export const MOMENT_DATE_FORMAT = 'YYYY-MM-DD'
export const TIME_FULL_FORMAT = 'HH:mm:ss'
export const MOMENT_DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm a'
export const EVENT_DATE_TIME_FORMAT = 'DD MMMM, dddd YYYY'
export const PAYMENT_DATE_TIME_FORMAT = 'DD/MM/YYYY'
export const PAYMENT_DATE_TIME_FORMAT_MOBILE = 'DD/MM/YY'
export const MONTH_DATE_TIME_FORMAT = 'MMMM D HH:mm'

export const URLS_NOT_SAVE_TO_LOCALSTORAGE = [
  /^\/$/,
  /\/login$/,
  /\/booking$/,
  /\/booking\//,
  /\/static-singers$/,
  /\/static-reception$/,
  /\/static-ceremony$/,
  /\w+\.\w+$/, // for file extentions
  /\/rp$/,
  /\/cmr$/,
  /\/csmr$/,
  /\/quote_builder$/,
  /\/ve$/,
  /\/vm$/,
  /\/ar$/,
  /\/unsbmke$/,
  /\/quote$/,
  /\/quote\//,
]
