import { action, computed, observable, runInAction } from 'mobx'
import queryString from 'query-string'
import moment from 'moment'
import { persist } from 'mobx-persist'
import FileSaver from 'file-saver'
import { MOMENT_DATE_FORMAT } from '../../constants'

class ReportStore {
  constructor(args) {
    this.rootStore = args.rootStore
    this.rootAPI = args.rootAPI
  }

  @persist('list') @observable bookedMusicians = []

  @observable getBookedMusiciansLoading = false
  @observable downloadAirWallexPaymentsLoading = false
  @observable gigRosterDate = moment()
    .format()

  @computed get selectedCityId() {
    return this.rootStore.cityStore.selectedCityId
  }

  @action.bound
  setGigRosterDate = (date) => {
    this.gigRosterDate = date
  }

  @action.bound
  getBookedMusicians = async (
    cityId = this.selectedCityId,
    date = moment(this.gigRosterDate)
      .format(MOMENT_DATE_FORMAT),
  ) => {
    try {
      this.getBookedMusiciansLoading = true
      const { payload } = await this.rootAPI.reportAPI
        .getBookedMusicians({
          cityId,
          date,
        })

      if (payload) {
        runInAction(() => {
          this.bookedMusicians = payload
        })
      }
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    } finally {
      this.getBookedMusiciansLoading = false
    }
  }

  @action.bound
  downloadMusicianPaysheet = async (data) => {
    try {
      const { isAllCities, ...params } = data
      if (!isAllCities) {
        params.cityId = this.rootStore.cityStore.selectedCityIds
      }

      const query = queryString.stringify(params)
      const fileName = `MusicianPayout_${moment(data.fromDate)
        .format('DD MMM YYYY')}-${moment(data.toDate)
        .format('DD MMM YYYY')}.csv`
      await this.rootAPI.reportAPI.downloadMusicianPaysheet(query, fileName)
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    }
  }

  @action.bound
  downloadAirWallexPayments = async (params) => {
    this.downloadAirWallexPaymentsLoading = true

    try {
      const { payload } = await this.rootAPI.reportAPI.downloadAirWallexPayments(params)
      const decodedData = atob(payload)
      const blob = new Blob([decodedData], { type: 'text/csv;charset=utf-8' })
      FileSaver.saveAs(blob, 'download.csv')
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    } finally {
      this.downloadAirWallexPaymentsLoading = false
    }
  }
}

export default ReportStore
