import { observable, action, runInAction } from 'mobx'
import { persist } from 'mobx-persist'
import { ALERT_TIMER } from '../../constants'
import resettableMixin from '../resettableMixin'

@resettableMixin
class ClientAppStore {
  @persist('list') @observable clientApps = []
  @persist('object') @observable clientApp = {}

  constructor(args) {
    this.rootStore = args.rootStore
    this.rootAPI = args.rootAPI
  }

  @action.bound
  async getClientApps() {
    try {
      const res = await this.rootAPI.clientAppAPI.getClientApps()
      runInAction(() => {
        this.clientApps = res.payload
      })
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    }
  }

  @action.bound
  async getClientAppById(id) {
    try {
      const res = await this.rootAPI.clientAppAPI.getClientAppById(id)
      if (res.payload) {
        runInAction(() => {
          this.clientApp = res.payload
        })
      }
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    }
  }

  @action.bound
  async addClientApp(data) {
    try {
      await this.rootAPI.clientAppAPI.addClientApp(data)
      this.rootStore.alertStore.success({
        title: 'The client app has been added successfully!',
        timer: ALERT_TIMER,
      })
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    }
  }

  @action.bound
  async updateClientApp(data) {
    try {
      await this.rootAPI.clientAppAPI.updateClientApp(data)
      this.rootStore.alertStore.success({
        title: 'The client app has been updated successfully!',
        timer: ALERT_TIMER,
      })
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    }
  }

  @action.bound
  async deleteClientApp(id) {
    try {
      await this.rootAPI.clientAppAPI.deleteClientApp(id)
      this.rootStore.alertStore.success({
        title: 'The client app has been deleted successfully!',
        timer: ALERT_TIMER,
      })
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    }
  }

  @action.bound
  clearClientApp() {
    this.clientApp = {}
  }
}

export default ClientAppStore
