import axios from 'axios'

export default class ProfileAPI {
  constructor(args) {
    this.request = args.request
  }

  updateProfile = data => this.request.put('/Users/UpdateCurrentUser', data)

  getAvatarPresignedUrl = (id, data) => this.request.get(`/UploadPostProcessRequest/User/${id}/PresignedUploadPostProcessUrl?objectName=${data.objectName}`)

  uploadAvatar = (url, file, contentType) => axios.put(url, file, {
    headers: {
      'Content-Type': contentType,
    },
  })
}
