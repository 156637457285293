import numeral from 'numeral'
import { getCurrencySymbolByCode } from 'flynk.app.web.core.data/helpers'

export const formatPrice = (price) => {
  const undefinedPrice = '0.00'
  const valueFormat = '0,0.00'
  const parsedPrice = parseInt(price, 10)

  if (typeof parsedPrice === 'number' && !Number.isNaN(parsedPrice)) {
    // if price is a number
    return numeral(price).format(valueFormat)
  } if (price instanceof Object) {
    // if price is an object comprises of amount and currency
    const { amount, currency } = price

    const currencySymbol = getCurrencySymbolByCode(currency)
    const value = Number(amount)

    if (typeof value === 'number') {
      const symbol = currencySymbol || ''

      return `${symbol}${numeral(value).format(valueFormat)}`
    }
    return undefinedPrice
  }
  return undefinedPrice
}
