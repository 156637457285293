import { observable, action, runInAction } from 'mobx'
import { persist } from 'mobx-persist'
import { ALERT_TIMER } from '../../constants'
import resettableMixin from '../resettableMixin'

@resettableMixin
class ClientAppFeatureStore {
  @persist('list') @observable features = []
  @persist('object') @observable feature = {}

  constructor(args) {
    this.rootStore = args.rootStore
    this.rootAPI = args.rootAPI
  }

  @action.bound
  async getClientAppFeatures() {
    try {
      const res = await this.rootAPI.clientAppFeatureAPI.getClientAppFeatures()
      runInAction(() => {
        this.features = res.payload
      })
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    }
  }

  @action.bound
  async getClientAppFeatureById(id) {
    try {
      const res = await this.rootAPI.clientAppFeatureAPI.getClientAppFeatureById(id)
      if (res.payload) {
        runInAction(() => {
          this.feature = res.payload
        })
      }
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    }
  }

  @action.bound
  async addClientAppFeature(data) {
    try {
      await this.rootAPI.clientAppFeatureAPI.addClientAppFeature(data)
      this.rootStore.alertStore.success({
        title: 'The client app feature has been added successfully!',
        timer: ALERT_TIMER,
      })
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    }
  }

  @action.bound
  async updateClientAppFeature(data) {
    try {
      await this.rootAPI.clientAppFeatureAPI.updateClientAppFeature(data)
      this.rootStore.alertStore.success({
        title: 'The client app feature has been updated successfully!',
        timer: ALERT_TIMER,
      })
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    }
  }

  @action.bound
  async deleteClientAppFeature(id) {
    try {
      await this.rootAPI.clientAppFeatureAPI.deleteClientAppFeature(id)
      this.rootStore.alertStore.success({
        title: 'The client app feature has been deleted successfully!',
        timer: ALERT_TIMER,
      })
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    }
  }

  @action.bound
  clearClientAppFeature() {
    this.feature = {}
  }
}

export default ClientAppFeatureStore
