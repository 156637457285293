export default class ClientAppShortlistAPI {
  constructor(args) {
    this.request = args.request
  }

  getClientAppShortlists = () => this.request.get('/Shortlist')

  addClientAppShortlist = propertyId => this.request.post('/Shortlist', {
    params: {
      propertyId,
    },
  })

  deleteClientAppShortlist = id => this.request.del(`/Shortlist/${id}`)
}
