import { observable, action, runInAction } from 'mobx'
import { persist } from 'mobx-persist'
import { ALERT_TIMER } from '../../constants'
import resettableMixin from '../resettableMixin'

@resettableMixin
class BankStore {
  @persist('list') @observable banks = []
  @persist('object') @observable bank = {}

  constructor(args) {
    this.rootStore = args.rootStore
    this.rootAPI = args.rootAPI
  }

  @action.bound
  async getBanks() {
    try {
      const res = await this.rootAPI.bankAPI.getBanks()
      runInAction(() => {
        this.banks = res.payload
      })
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    }
  }

  @action.bound
  clearBanks() {
    this.banks = []
  }

  @action.bound
  async getBankById(id) {
    try {
      const res = await this.rootAPI.bankAPI.getBankById(id)
      if (res.payload) {
        runInAction(() => {
          this.bank = res.payload
        })
      }
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    }
  }

  @action.bound
  async addBank(data) {
    try {
      await this.rootAPI.bankAPI.addBank(data)
      this.rootStore.alertStore.success({
        title: 'The bank has been added successfully!',
        timer: ALERT_TIMER,
      })
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    }
  }

  @action.bound
  async updateBank(data) {
    try {
      await this.rootAPI.bankAPI.updateBank(data)
      this.rootStore.alertStore.success({
        title: 'The bank has been updated successfully!',
        timer: ALERT_TIMER,
      })
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    }
  }

  @action.bound
  clearBank() {
    this.bank = {}
  }
}

export default BankStore
