export default class VenuesAPI {
  constructor(args) {
    this.request = args.request
  }

  getVenues = placeId => this.request.get('/Venues', {
    params: {
      placeId,
    },
  })

  createVenue = data => this.request.post('/Venues', data)

  updateVenue = (id, data) => this.request.put(`/Venue/${id}`, data)

  getVenueById = id => this.request.get(`/Venue/${id}`)

  getTravelChargesByCityId = cityId => this.request.get(`/Venues/City/${cityId}/TravelCharges`)

  getVenuesDistance = (cityId, data) => this.request.post(`/Venues/Distance?cityId=${cityId}`,
    data)
}
