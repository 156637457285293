export default class PerformerAPI {
  constructor(args) {
    this.request = args.request
  }

  getPerformerSkills = () => this.request.get('Performers/Skills')

  getPerformers = cityId => this.request.get(`Performers?cityId=${cityId}`)

  getPerformersByBookingId = bookingId => this.request.get(`Booking/${bookingId}/Performers`)

  updatePerformer = data => this.request.put('Performers/UpdatePerformerAndPerson', data)

  uploadPreviewImageUrl = (url, file, contentType) => this.request.put(url, file, {
    headers: {
      'Content-Type': contentType,
    },
  })

  GetPerformerPreviewImageUrl = (performerId, data) => this.request.get(`UploadPostProcessRequest/Performer/${performerId}/PresignedUploadPostProcessUrl?objectName=${data.objectName}`)
}
