import { Modal } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import React from 'react'
import forOwn from 'lodash/forOwn'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'
import moment from 'moment'
import { isEmptyObject } from 'flynk.app.web.core.data/helpers'
import { LONG_DATE_FORMAT, TIME_AMPM_FORMAT } from 'flynk.app.web.core.components/constants'
import { formatName } from './user'
import { getAddressSummary, getFullAddressFormatted } from './address'
import {
  BookingCeremonyDepositDefault,
  BookingDepositDefault,
  BookingDepositOptions,
  BookingMenuItems,
  BookingSections,
  BookingStatus,
  defaultDepositCoefficient,
  MusicianBookingMenuItems,
  MusicianBookingSections,
  MusicianOfferSections,
  OfferType,
  TaxMode,
  Theme,
  VocalOption,
  PhoneNumberWord,
  PhoneWord,
  MOMENT_DATE_FORMAT,
  LocationOptionValue,
} from '../constants'
import { reorder } from './common'

export const mergeVocalOptions = ({
  ceremony,
  reception,
}) => {
  if (!ceremony && !reception) {
    return VocalOption.NoVocals
  }

  if (!ceremony) {
    return reception
  }

  if (!reception) {
    return ceremony
  }

  if (ceremony === reception) {
    return ceremony
  }

  if (
    (ceremony === VocalOption.MaleVocalsOnly &&
      reception === VocalOption.NoVocals) ||
    (ceremony === VocalOption.NoVocals &&
      reception === VocalOption.MaleVocalsOnly)
  ) {
    return VocalOption.MaleVocalsOnly
  }

  if (
    (ceremony === VocalOption.FemaleVocalsOnly &&
      reception === VocalOption.NoVocals) ||
    (ceremony === VocalOption.NoVocals &&
      reception === VocalOption.FemaleVocalsOnly)
  ) {
    return VocalOption.FemaleVocalsOnly
  }

  if (
    (ceremony === VocalOption.MaleVocalsOnly &&
      reception === VocalOption.FemaleVocalsOnly) ||
    (ceremony === VocalOption.FemaleVocalsOnly &&
      reception === VocalOption.MaleVocalsOnly)
  ) {
    return VocalOption.MaleAndFemaleVocals
  }

  if (
    ceremony === VocalOption.MaleAndFemaleVocals ||
    reception === VocalOption.MaleAndFemaleVocals
  ) {
    return VocalOption.MaleAndFemaleVocals
  }

  return VocalOption.NoVocals
}

export const formatBooking = (booking) => {
  let title = get(booking, 'event.title', '')
  const gigs = get(booking, 'event.gigs', [])
  let venues = [
    {
      formattedAddress: '',
      formattedFullAddress: [],
    },
    {
      formattedAddress: '',
      formattedFullAddress: [],
    },
  ]
  let packages = [
    { performanceName: '' },
    { performanceName: '' },
  ]
  let performers = []
  let assignee = {}

  const previousAssignee = get(booking, 'previousAssigneeUser')

  const assigneePerson = get(booking, 'customer.assigneeUser', {
    id: '',
    name: '',
  })

  if (previousAssignee?.id) {
    assignee = {
      id: previousAssignee.id,
      name: formatName(previousAssignee),
    }
  } else if (assigneePerson.id) {
    assignee = {
      id: assigneePerson.id,
      name: formatName(assigneePerson),
    }
  }

  if (gigs.length > 0) {
    if (!title) {
      if (gigs.find(gig => gig.performanceId)) {
        title = 'Custom'
      }
    }

    venues = gigs.map((gig) => {
      const venue = get(gig, 'venue', {})
      const type = get(gig, 'performance.type.name', '')
      let formattedAddress
      let formattedFullAddress
      try {
        const address = get(venue, 'address', '')
        formattedAddress = getAddressSummary(address)// get(address, 'fullAddress', '')
        formattedFullAddress = getFullAddressFormatted(address)
      } catch (err) {
        formattedAddress = ''
        formattedFullAddress = []
      }

      return {
        ...venue,
        type,
        formattedAddress,
        formattedFullAddress,
        notSureWhere: gig.notSureWhere,
        gigId: gig.id,
        performanceTypeId: gig.performanceTypeId,
      }
    })

    packages = gigs.map((gig) => {
      const performance = get(gig, 'performance', {})
      const performanceName = get(performance, 'name', '')
      const type = get(gig, 'performance.type.name', '')

      return {
        ...performance,
        performanceName,
        type,
        gigId: gig.id,
      }
    })
  }

  const performersPreferences = get(booking, 'event.eventPerformerPreferences', [])
  if (performersPreferences.length > 0) {
    performers = performersPreferences.map(preference => ({
      lastUpdated: preference.lastUpdated,
      ...preference.performer,
    } || {}))
  }

  const mobile = get(booking, 'customer.mobile', '')
  const landline = get(booking, 'customer.landline', '')

  const customer = {
    created: get(booking, 'created', ''),
    id: get(booking, 'customer.id', ''),
    name: formatName(booking.customer || {}),
    email: get(booking, 'customer.email', ''),
    mobileRegion: get(booking, 'customer.region', ''),
    phone: mobile || landline,
    mobile,
    landline,
    assignee,
  }

  let primaryIndex = 0
  const customerContacts = get(booking, 'customerContacts', [])
    .map((contact, index) => {
      const id = get(contact, 'id')
      const customerId = get(contact, 'customerId', '')
      const customerMobile = get(contact, 'mobile', '')
      const customerLandline = get(contact, 'landline', '')
      const isPrimary = get(contact, 'isPrimary', false)
      if (isPrimary) {
        primaryIndex = index
      }
      return {
        created: get(contact, 'created', ''),
        id,
        customerId,
        name: get(contact, 'name', ''),
        email: get(contact, 'email', ''),
        mobileRegion: get(contact, 'region', ''),
        phone: customerMobile || customerLandline,
        mobile: customerMobile,
        landline: customerLandline,
        relationship: get(contact, 'relationship', ''),
        isPrimary,
      }
    })
  if (primaryIndex !== 0) {
    [customerContacts[0], customerContacts[primaryIndex]] = [
      customerContacts[primaryIndex],
      customerContacts[0],
    ]
  }

  return {
    ...booking,
    id: get(booking, 'id', ''),
    created: get(booking, 'created', ''),
    status: get(booking, 'status', ''),
    title,
    customer,
    customerContacts,
    relationship: get(booking, 'relationship', ''),
    date: {
      value: get(booking, 'event.eventDate', ''),
      notSureWhen: get(booking, 'event.notSureWhen', false),
    },
    venues,
    packages,
    performers,
    chooseSingerForMe: get(booking, 'event.chooseSingerForMe', false),
    price: {
      price: get(booking, 'estimatedPrice', 0),
      discount: {
        valueIncludeTax: get(booking, 'estimatedDiscountIncludeTax', 0),
        valueExcludeTax: get(booking, 'estimatedDiscountExcludeTax', 0),
        rate: get(booking, 'estimatedDiscountRate', 0),
      },
      surcharge: {
        valueIncludeTax: get(booking, 'estimatedSurchargeIncludeTax', 0),
        valueExcludeTax: get(booking, 'estimatedSurchargeExcludeTax', 0),
        rate: get(booking, 'estimatedSurchargeRate', 0),
      },
      tax: get(booking, 'estimatedTax', 0),
    },
    note: get(booking, 'note', ''),
  }
}

export const formatBookingForMusician = (booking, offerGroup) => {
  const formattedBooking = formatBooking(booking)
  if (!offerGroup || !offerGroup.length) return formattedBooking
  const {
    earning, tax, taxRate, surcharge, taxRegistered,
  } = offerGroup[0]
  const getCorrespondingOfferPosition = (gigId) => {
    const correspondingOffer = offerGroup.find(offer => offer?.gig?.id === gigId)
    return correspondingOffer ? correspondingOffer.position : {}
  }

  return ({
    ...formattedBooking,
    earning,
    tax,
    taxRate,
    surcharge,
    taxRegistered,
    venues: formattedBooking.venues
      .map(el => ({
        ...el,
        position: getCorrespondingOfferPosition(el.gigId),
      })),
  })
}

export const formatQuotePrice = (
  quote, taxMode, booking = {}, performanceTypes = [],
) => {
  const {
    gigPrices,
    price,
    surcharge,
    discount,
    tax,
  } = quote

  const gigPricesExtended = gigPrices
    ?.map((p) => {
      const currGig = get(booking, 'event.gigs', [])
        .find(gig => gig.id === p.gigId)

      const performanceType = performanceTypes
        .find(type => type.id === currGig?.performanceTypeId)

      return {
        ...p,
        name: performanceType ?
          performanceType.name :
          '',
      }
    }) || []

  if (gigPricesExtended.length > 0) {
    gigPricesExtended
      .sort((a, b) => a.name.localeCompare(b.name))
  }

  const discountValue = get(discount, 'valueIncludeTax', 0)
  const discountRate = Number.parseInt(get(discount, 'rate', 0) * 100, 10)
  const surchargeValue = get(surcharge, 'valueIncludeTax', 0)
  const surchargeRate = Number.parseInt(get(surcharge, 'rate', 0) * 100, 10)
  const total = price

  let subtotal = 0
  if (gigPricesExtended?.length > 0) {
    subtotal = gigPricesExtended.reduce((sum, gigPrice) => gigPrice.price + sum, 0)
  } else {
    const priceExcludeTax = taxMode === TaxMode.Inclusive ? total : total - tax
    subtotal = priceExcludeTax + (discountValue - surchargeValue)
  }

  return {
    discountRate,
    discountValue,
    subtotal,
    surchargeRate,
    surchargeValue,
    tax,
    total,
    ...quote,
    gigPrices: gigPricesExtended,
  }
}

export const getAllStatuses = () => {
  const statuses = []
  forOwn(BookingStatus, (value) => {
    statuses.push(value)
  })

  return statuses.sort((a, b) => a.id - b.id)
}

export const getStatusListByStatus = (status) => {
  let formattedStatus = status
  if (typeof formattedStatus !== 'number') {
    formattedStatus = Number(formattedStatus)
  }
  const allStatuses = getAllStatuses()
  let statuses = []

  switch (formattedStatus) {
    case BookingStatus.Abandoned.id:
      return [
        BookingStatus.Abandoned,
        BookingStatus.Quote,
        BookingStatus.Archived,
        BookingStatus.Cancelled,
      ]

    case BookingStatus.RequestCallback.id:
      return [
        BookingStatus.RequestCallback,
        BookingStatus.Archived,
        BookingStatus.Quote,
      ]

    case BookingStatus.Estimated.id:
      return [
        BookingStatus.Estimated,
        BookingStatus.Quote,
        BookingStatus.Cancelled,
      ]

    case BookingStatus.Quote.id:
      return [
        BookingStatus.Quote,
        BookingStatus.Planning,
        BookingStatus.Cancelled,
      ]

    case BookingStatus.Planning.id:
      return [
        BookingStatus.Planning,
        BookingStatus.Cancelled,
      ]

    case BookingStatus.Confirmation.id:
      return [
        BookingStatus.Confirmation,
        BookingStatus.Cancelled,
      ]

    case BookingStatus.Booked.id:
      return [
        BookingStatus.Booked,
        BookingStatus.PlanningComplete,
        BookingStatus.Cancelled,
      ]

    case BookingStatus.PlanningComplete.id:
      return [
        BookingStatus.PlanningComplete,
        BookingStatus.Cancelled,
      ]

    case BookingStatus.Performing.id:
      return [
        BookingStatus.Performing,
        BookingStatus.Cancelled,
      ]

    case BookingStatus.PostEvent.id:
      return [
        BookingStatus.PostEvent,
        BookingStatus.ReadyToPay,
        BookingStatus.Cancelled,
      ]

    case BookingStatus.ReadyToPay.id:
      return [
        BookingStatus.ReadyToPay,
        BookingStatus.MusiciansPaid,
      ]

    case BookingStatus.MusiciansPaid.id:
      return [
        BookingStatus.MusiciansPaid,
        BookingStatus.Finalised,
      ]

    default:
      statuses = allStatuses.filter(statusObj => statusObj.id === formattedStatus)
      break
  }

  return statuses
}

export const getProspectStatuses = () => [
  BookingStatus.PlaceHolder,
  BookingStatus.Abandoned,
  BookingStatus.RequestCallback,
  BookingStatus.Estimated,
  BookingStatus.Quote,
]

export const getCustomerRowBookingStatus = () => [
  BookingStatus.Abandoned,
  BookingStatus.RequestCallback,
  BookingStatus.Estimated,
  BookingStatus.Quote,
]

export const getBookingStatuses = (showAll) => {
  const allStatuses = getAllStatuses()
  const bookingStatuses = allStatuses.filter((statusObj) => {
    const { id } = statusObj
    return ![
      BookingStatus.Abandoned.id,
      BookingStatus.Quote.id,
      BookingStatus.Estimated.id,
      BookingStatus.RequestCallback.id,
    ].includes(id)
  })
  const defaultBookingStatuses = bookingStatuses.filter((statusObj) => {
    const { id } = statusObj
    return ![
      BookingStatus.Finalised.id,
      BookingStatus.Cancelled.id,
      BookingStatus.Archived.id,
    ].includes(id)
  })

  return showAll ? bookingStatuses : defaultBookingStatuses
}

export const getSingerAvailabilityLabel = (gigOffer) => {
  const {
    isAccepted, isAvailable, isReserved, isSent, isCancelled,
  } = gigOffer

  if (isCancelled) {
    return {
      text: 'Cancelled',
      color: Theme.Colors.TransparentBlack,
    }
  }

  if (isAccepted) {
    return {
      text: 'Booked',
      color: Theme.Colors.Primary,
    }
  }

  if (isReserved) {
    return {
      text: 'Date Hold',
      color: Theme.Colors.LightYellow2,
    }
  }

  if (isAvailable) {
    return {
      text: 'Available',
      color: Theme.Colors.Green,
    }
  }

  if (isAvailable === false) {
    return {
      text: 'Unavailable',
      color: Theme.Colors.LightGrey,
    }
  }

  if (!isSent) {
    return {
      text: 'Manual',
      color: Theme.Colors.Green,
    }
  }

  if (isAvailable === undefined) {
    return {
      text: 'Waiting Response',
      color: Theme.Colors.PendingStatus,
    }
  }

  return {
    text: '',
    color: '',
  }
}

export const isShowKeyMusician = (gigOffers = [], currentOffer = []) => {
  const performanceTypeIds = currentOffer.map(offer => offer?.gig?.performanceTypeId)
  return gigOffers.some(gigOffer => gigOffer?.isAccepted !== false &&
    performanceTypeIds.includes(gigOffer?.gig?.performanceTypeId))
}

export const formatTime = time => moment(time)
  .format(TIME_AMPM_FORMAT)

export const formatPerformancePositions = performancePositions =>
  performancePositions.map((performancePosition) => {
    const positions = performancePosition.positions.map((position) => {
      if (position.user) {
        return {
          ...position,
          status: 'accepted',
          id: position.id,
          name: position.name,
          musicianName: position.user.firstname + (position.user.lastname ? ` ${position.user.lastname}` : ''),
          arrivalTime: position.arrivalTime ? formatTime(position.arrivalTime) : '',
          startTime: position.startTime ? formatTime(position.startTime) : '',
          endTime: position.endTime ? formatTime(position.endTime) : '',
          skill: position.skill.id,
        }
      }

      if (position.gigOffers) {
        if (position.gigOffers.length === 1 && position.gigOffers[0].type === OfferType.Offer) {
          return {
            ...position,
            status: 'exclusive',
            id: position.id,
            name: position.name,
            arrivalTime: position.arrivalTime ? formatTime(position.arrivalTime) : '',
            startTime: position.startTime ? formatTime(position.startTime) : '',
            endTime: position.endTime ? formatTime(position.endTime) : '',
            skill: position.skill.id,
          }
        }

        return {
          ...position,
          status: 'openOffer',
          id: position.id,
          openOffers: position.gigOffers.length,
          name: position.name,
          arrivalTime: position.arrivalTime ? formatTime(position.arrivalTime) : '',
          startTime: position.startTime ? formatTime(position.startTime) : '',
          endTime: position.endTime ? formatTime(position.endTime) : '',
          skill: position.skill.id,
        }
      }

      return {
        ...position,
        id: position.id,
        status: 'template',
        name: position.name,
        skill: position.skill.id,
      }
    })

    return {
      ...performancePosition,
      positions,
    }
  })

export const formatKeySongsFiles = (keySongsFiles) => {
  const formattedKeySongsFiles = {}

  Object.keys(keySongsFiles)
    .forEach((key) => {
      const el = keySongsFiles[key]
      formattedKeySongsFiles[key] = {
        ...el,
        files: el.files.map(file => ({
          ...file,
          name: file.label,
        })),
      }
    })
  return formattedKeySongsFiles
}

export const setCorrectContentTypes = (fileName) => {
  const RE = /\.\w+\d?$/
  let ext = fileName.match(RE)
  ext = ext[0] ? ext[0] : null
  switch (ext) {
    case '.json':
      return 'application/json'
    case '.txt':
      return 'text/plain; charset=utf-8'
    case '.mp4':
      return 'video/mp4'
    case '.png':
      return 'image/png'
    case '.jpg':
    case '.jpeg':
      return 'image/jpeg'
    case '.mp3':
      return 'audio/mpeg'
    case '.m4a':
      return 'audio/mp4'
    case '.pdf':
      return 'application/pdf'
    case '.csv':
    case '.tsv':
      return 'application/octet-stream'
    default:
      return 'text/plain; charset=utf-8'
  }
}

export const makeCollapsedColumnsWide = (
  currSection,
  directionToRight,
  initialMaxWidthValue,
  isMusician,
  moveLengthValue, // how far will each column move collapsing
  openedScrollableColumnsNumber,
  sectionId,
  totalCollapsedSectionsCount,
) => {
  let moveToLeft = -moveLengthValue * currSection.index
  const sections = isMusician ? MusicianBookingSections : BookingSections
  if (initialMaxWidthValue < 640) {
    if (currSection.index < sections[sectionId].index) {
      moveToLeft -= moveLengthValue
    }
    return {
      isWide: currSection.id === sectionId,
      moveToLeft,
    }
  }
  const { index, nextId, prevId } = sections[sectionId]
  let isWide
  if (index > 0) {
    if (directionToRight) {
      if (openedScrollableColumnsNumber === 3) {
        isWide = index < 2 ?
          currSection.id === sectionId ||
          currSection.id === prevId ||
          currSection.id === nextId :
          currSection.id === sectionId ||
          currSection.id === prevId ||
          currSection.id === sections[prevId].prevId
      } else {
        isWide = currSection.id === sectionId || currSection.id === prevId
      }
      if (isWide) {
        moveToLeft = openedScrollableColumnsNumber < 3 ?
          -moveLengthValue * (sections[sectionId].index - 1) :
          0
        if (index - openedScrollableColumnsNumber >= 0) {
          moveToLeft = -moveLengthValue * (index - (openedScrollableColumnsNumber - 1))
        }
      } else {
        if (currSection.index < sections[prevId].index) {
          moveToLeft -= moveLengthValue
        }
        if (currSection.index > sections[prevId].index) {
          moveToLeft = (currSection.index - (openedScrollableColumnsNumber - 1)) *
            (-moveLengthValue)
        }
      }
    } else {
      if (openedScrollableColumnsNumber === 3) {
        isWide = index > totalCollapsedSectionsCount - 3 ?
          currSection.id === sectionId ||
          currSection.id === prevId ||
          currSection.id === nextId :
          currSection.id === sectionId ||
          currSection.id === nextId ||
          currSection.id === sections[nextId].nextId
      } else {
        isWide = currSection.id === sectionId || currSection.id === nextId
      }
      if (isWide) {
        moveToLeft = openedScrollableColumnsNumber < 3 ?
          -moveLengthValue * index :
          -moveLengthValue * 2
        if (index < 2) {
          moveToLeft = -moveLengthValue
        }
      } else {
        if (currSection.index < sections[sectionId].index) {
          moveToLeft -= moveLengthValue
        }
        if (currSection.index > sections[nextId].index) {
          moveToLeft = (currSection.index - (openedScrollableColumnsNumber - 1)) *
            (-moveLengthValue)
        }
      }
    }
  } else {
    isWide = openedScrollableColumnsNumber === 2 ?
      currSection.id === sectionId ||
      currSection.id === nextId :
      currSection.id === sectionId ||
      currSection.id === nextId ||
      currSection.id === sections[nextId].nextId
    if (isWide) {
      if (currSection.id === sectionId) {
        moveToLeft = -moveLengthValue * (currSection.index)
      }
      if (currSection.id === nextId) {
        moveToLeft = -moveLengthValue * (currSection.index - 1)
      }
      if (currSection.id === sections[nextId].nextId) {
        moveToLeft = -moveLengthValue * (currSection.index - 2)
      }
    } else {
      if (currSection.index < sections[sectionId].index) {
        moveToLeft -= moveLengthValue
      }
      if (currSection.index > sections[sectionId].index) {
        moveToLeft = (currSection.index - (openedScrollableColumnsNumber - 1)) * (-moveLengthValue)
      }
    }
  }

  return {
    isWide,
    moveToLeft,
  }
}

export const CheckOtherPhoneValidity = value => value && /[a-zA-Z]/g.test(value)

export const reduceGigArray = offer => offer.reduce((arr, next) => {
  if (arr.length === 0 ||
    !arr.some(e => e?.gig?.performance?.type?.name === next?.gig?.performance?.type?.name)
  ) {
    const newArr = [...arr]
    newArr.push(next)
    return newArr
  }
  return arr
}, [])

export const getCurrentSections = (isMusician = false, currentOffer = []) => {
  let sections
  if (isMusician) {
    sections = currentOffer.length && currentOffer[0].groupOfferId ?
      MusicianOfferSections :
      MusicianBookingSections
  } else {
    sections = BookingSections
  }
  return sections
}

export const getCurrentBookingUrl = (isMusician = false, currentOffer = []) => {
  let url
  if (isMusician) {
    url = currentOffer.length && currentOffer[0].groupOfferId ?
      '/musician/offers' :
      '/musician/bookings'
  } else {
    url = '/bookings'
  }
  return url
}

export const getPaymentMode = (
  firmPrice,
  isReception,
  currentDepositDefault = isReception ?
    BookingDepositDefault :
    BookingCeremonyDepositDefault,
) => {
  const percentDeposit = firmPrice.payable * defaultDepositCoefficient

  return percentDeposit > currentDepositDefault ?
    BookingDepositOptions.percent :
    BookingDepositOptions.fixed
}

export const isPaymentsEnabled = bookingStatus => (
  bookingStatus >= BookingStatus.PostEvent.id &&
  bookingStatus !== BookingStatus.Cancelled.id &&
  bookingStatus !== BookingStatus.Archived.id
)

export const getCurrentMenuItems = (isMusician, bookingStatus) => {
  const menus = isMusician ? MusicianBookingMenuItems : BookingMenuItems

  const paymentItem = menus.find(x => x.section === 'payments')

  if (paymentItem) {
    paymentItem.disabled = !isPaymentsEnabled(bookingStatus)
  }

  menus[menus.length - 1].isLast = true
  return menus
}

export const checkEventDate = (booking) => {
  let { date: { value: date } } = booking
  date = new Date(date).getTime()
  const now = new Date().getTime()
  const duration = date - now
  const day = 1000 * 60 * 60 * 24
  return duration < day
}

export const getTotalCollapsedSectionsCount = (isMusician, bookingStatus) =>
  Object.entries(getCurrentMenuItems(isMusician, bookingStatus))
    .filter(el => !el[1].disabled)
    .length

export const resetSection = (isMusician, bookingStatus, sectionId) => {
  const currBookings = getCurrentSections(isMusician)
  const iPE = isPaymentsEnabled(bookingStatus)
  return !iPE && sectionId === currBookings.payments.id ?
    currBookings.confirmation.id :
    sectionId
}

export const filterAsLimited = (value, limit = 100) => {
  const thisValue = Number(value)
  if (!thisValue && thisValue !== 0) {
    return ''
  } if (value < 0) {
    return 0
  } if (value > limit) return limit
  return value
}

export const getTaxDisplayLabel = (booking, cities) => {
  const bookingCity = cities.find(city => city.id === booking.cityId)
  if (bookingCity == null || bookingCity.region == null) {
    return ''
  }

  return bookingCity.region.serviceTaxRegistrationDisplayName
}

export const checkBookingExist = (booking, id) => booking?.id && id && booking.id === id

export const showQuoteBuilderNotification = options => Modal.error({
  className: 'qb-notification',
  // eslint-disable-next-line react/jsx-filename-extension
  icon: <ExclamationCircleOutlined
    className="qb-notification-icon"
  />,
  title: 'Notification',
  width: 487,
  ...options,
})

export const handleQuoteBuilderError = (error) => {
  if (error) {
    if (error && error.description && error.description !== 'Role not found') {
      showQuoteBuilderNotification({
        content: error.description,
        maskClosable: true,
      })
    }
  }
}

export const getRegionByCity = selectedCity =>
  (selectedCity.region && selectedCity.region.name) || 'AU'

export const getUpdateSelectedPerformersOptions = (eventId, selectedPerformers) => {
  if (selectedPerformers.length === 0) {
    return {
      eventId,
      performers: selectedPerformers,
    }
  }

  const performerIds = selectedPerformers
    .map(performer => performer.id)

  return {
    eventId,
    performerIds,
  }
}

export const reorderItems = (array, callback) => (result) => {
  // dropped outside the list
  if (!result.destination) {
    return
  }

  const items = reorder(
    array,
    result.source.index,
    result.destination.index,
  )

  const preferredPerformerIds = items.map(item => item.id)
  const oldPerformerIds = array.map(performer => performer.id)

  if (!isEqual(preferredPerformerIds, oldPerformerIds)) {
    callback(items)
  }
}

export const isShowQuoteBuilderWarning = (booking, condition, ceremonyObj, receptionObj) =>
  booking?.id &&
  condition &&
  ((ceremonyObj !== null &&
    !isEmptyObject(ceremonyObj) &&
    ceremonyObj?.id !== 'no-ceremony') ||
    (receptionObj !== null &&
      !isEmptyObject(receptionObj) &&
      receptionObj?.id !== 'no-reception'))

export const getUniqueKeySongFiles = arr => Array.from(arr)
  .reduce((sum, el) => {
    if (!sum.some(fel => fel.name === el.name)) {
      sum.push(el)
    }
    return sum
  }, [])

export const getPhoneWordsByCountry = (contry) => {
  const formattedContry = contry.toUpperCase()
  const phoneWord = PhoneNumberWord[formattedContry]
  if (!phoneWord) {
    return PhoneNumberWord.Default
  }
  return phoneWord
}

export const getPhoneSimpleWordsByCountry = (contry) => {
  const formattedContry = contry.toUpperCase()
  const phoneWord = PhoneWord[formattedContry]
  if (!phoneWord) {
    return PhoneWord.Default
  }
  return phoneWord
}

// Helper for add prospect modal
export const getRequestForUpdatingBooking = (formValues, bookingData) => {
  const {
    eventDate,
    ceremonyOption,
    ceremonyDuration,
    ceremonyLocationRawData,
    ceremonyNotSureWhere,
    receptionOption,
    receptionDuration,
    receptionNotSureWhere,
    receptionLocationRawData,
    performers,
  } = formValues || {}
  const request = {}
  const { gigs = [] } = bookingData?.event || {}

  if (eventDate) {
    request.eventDateInfo = {
      eventDate,
      notSureWhen: !eventDate,
    }
  }

  if (ceremonyOption || receptionOption) {
    request.gigUpdateInfo = {
      gigs: [],
    }
    if (ceremonyOption) {
      const ceremonyGig = (gigs || []).find(p => get(p, 'performance.type.name') === 'Ceremony') || gigs[0] || {}
      request.gigUpdateInfo.gigs.push({
        duration: ceremonyDuration,
        gigId: ceremonyGig?.id,
        location: ceremonyLocationRawData,
        notSureWhere: ceremonyNotSureWhere === LocationOptionValue.notSureYet,
        performanceId: ceremonyOption,
        performanceType: 'Ceremony',
      })
    }

    if (receptionOption) {
      const receptionGig = (gigs || []).find(p => get(p, 'performance.type.name') === 'Reception') || gigs[1] || {}
      request.gigUpdateInfo.gigs.push({
        duration: receptionDuration,
        gigId: receptionGig?.id,
        location: receptionLocationRawData,
        notSureWhere: receptionNotSureWhere === LocationOptionValue.notSureYet,
        performanceId: receptionOption,
        performanceType: 'Reception',
      })
    }
  }

  if (performers) {
    request.singerPreferredInfo = {
      preferredPerformerIds: performers,
    }
  }

  return request
}

export const getPayloadForAddingBooking =
  (formData, { selectedCityId, ceremonyTypeId, receptionTypeId }) => {
    if (!formData) return

    const {
      isPlaceHolder,
      ceremonyLocationRawData,
      receptionLocationRawData,
      ceremonyOption,
      ceremonyDuration,
      ceremonyNotSureWhere,
      receptionOption,
      receptionDuration,
      receptionNotSureWhere,
      eventDate,
      notSureWhen,
      name,
      email,
      mobile,
      landline,
      mobileRegion,
      relationship,
      performers,
      status,
    } = formData

    const bookingData = {
      name,
      email,
      status,
      relationship,
      mobile,
      landline,
      isPlaceHolder,
      mobileRegion,
      performers,
      eventTime: {},
      performances: [],
      cityId: selectedCityId,
      isRequestCallback: false,
      note: '',
    }
    const date = eventDate ?
      moment(eventDate, LONG_DATE_FORMAT).format(
        `${MOMENT_DATE_FORMAT}T00:00:00`,
      ) :
      null
    bookingData.eventTime = {
      eventDate: date,
      notSureWhen: notSureWhen || !date,
    }

    if (ceremonyOption) {
      bookingData.performances.push({
        performanceId: ceremonyOption,
        performanceTypeId: ceremonyTypeId,
        venue: {
          location: ceremonyLocationRawData,
          notSureWhere: ceremonyNotSureWhere === LocationOptionValue.notSureYet,
          cityId: selectedCityId,
        },
        duration: ceremonyDuration,
      })
    }
    if (receptionOption) {
      bookingData.performances.push({
        performanceId: receptionOption,
        performanceTypeId: receptionTypeId,
        venue: {
          location: receptionLocationRawData,
          notSureWhere: receptionNotSureWhere === LocationOptionValue.notSureYet,
          cityId: selectedCityId,
        },
        duration: receptionDuration,
      })
    }

    return bookingData
  }
