export default class ClientAppFeatureAPI {
  constructor(args) {
    this.request = args.request
  }

  getClientAppFeatures = () => this.request.get('/ClientApp/Features')

  getClientAppFeatureById = id => this.request(`/ClientApp/Features/${id}`)

  addClientAppFeature = data => this.request.post('/ClientApp/Features', data)

  updateClientAppFeature = data => this.request.put('/ClientApp/Features', data)

  deleteClientAppFeature = id => this.request.del(`/ClientApp/Features/${id}`)
}
