import { action, observable } from 'mobx'
import { persist } from 'mobx-persist'

export default class ConfigStore {
  @persist @observable apiEndpoint = ''
  @persist @observable authEndpoint = ''
  @persist @observable version = '1.0'
  @persist @observable clientId = ''
  @persist @observable grantTypePassword = 'password'
  @persist @observable grantTypeRefreshToken = 'refresh_token'
  @persist @observable appName = ''
  @persist @observable supportEmail = ''

  constructor(args) {
    this.rootStore = args.rootStore
    this.rootAPI = args.rootAPI
  }

  @action.bound
  init({
    apiEndpoint,
    authEndpoint,
    version,
    clientId,
    grantTypePassword,
    grantTypeRefreshToken,
    appName,
    supportEmail,
  }) {
    this.apiEndpoint = apiEndpoint
    this.authEndpoint = authEndpoint
    this.version = version
    this.clientId = clientId
    this.grantTypePassword = grantTypePassword
    this.grantTypeRefreshToken = grantTypeRefreshToken
    this.appName = appName
    this.supportEmail = supportEmail
  }
}
