import React from 'react'
import loadable from '@loadable/component'
import { PublicRoute, Switch } from '../flynk.app.web.core.components/router'
import MaintenanceLayout from '../layouts/MaintenanceLayout'

const Maintenance = loadable(() => import('../components/Maintenance'))

const MaintenanceRoutes = () => (
  <Switch>
    <PublicRoute
      layout={MaintenanceLayout}
      path="*"
      component={Maintenance}
      docTitle="Maintenance"
    />
  </Switch>
)

export default MaintenanceRoutes
