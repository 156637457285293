import { NumberRegExps } from '../constants'

export const filterValueAsNumber = value => String(value)
  .replace(NumberRegExps.NumbersDots, '')

export const filterValueAsNumberOrHyphen = value => String(value)
  .replace(NumberRegExps.NumbersDotsHyphen, '')

export const filterValueAsOnlyOneDot = value => filterValueAsNumber(value)
  .replace(NumberRegExps.NumbersOnlyOneDot, '$1$2')

export const filterValueAsDividedToThousands = (value, divider = ',') => filterValueAsNumberOrHyphen(value)
  .replace(NumberRegExps.NumberSplitBy, divider)

export const filterValueToThousandsAndMillions = (value) => {
  const int = Math.floor(value)
  if (int < Math.pow(10, 3) || int > Math.pow(10, 9)) {
    return filterValueAsNumberOrHyphen(value)
      .replace(NumberRegExps.NumberSplitBy, ',')
  } else if (int < Math.pow(10, 6)) {
    const thousands = Math.floor(int / Math.pow(10, 3))
    const dozens = Math.floor((int - thousands * Math.pow(10, 3)) / Math.pow(10, 2))

    return `${thousands}${dozens ? '.' + dozens : ''}k`
  } else if (int < Math.pow(10, 9)) {
    const millions = Math.floor(int / Math.pow(10, 6))
    const dozens = Math.floor((int - millions * Math.pow(10, 6)) / Math.pow(10, 5))

    return `${millions}${dozens ? '.' + dozens : ''}M`
  }
}

export const filterAsLimited = (value, limit = 100) => {
  const thisValue = Number(value)
  if (!thisValue && thisValue !== 0) {
    return ''
  }
  if (value < 0) {
    return 0
  }
  if (value > limit) return limit
  return value
}

export const roundToDecimal = (num, decimals = 2) => {
  const hasPointInTheEnd = String(num)
    .match(/\.0*$/)

  if (hasPointInTheEnd) {
    if (hasPointInTheEnd[0].length <= decimals + 1) return num
    return `${Number(num)}${hasPointInTheEnd[0].slice(0, decimals + 1)}`
  }

  let coef = '1'

  for (let i = 0; i < decimals; i += 1) {
    coef += '0'
  }

  coef = Number(coef)

  return (Math.round((Number(num) + Number.EPSILON) * coef) / coef)
    .toString(10)
}
