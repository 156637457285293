import { action, observable } from 'mobx'
import { persist } from 'mobx-persist'

export default class ErrorsStore {
  @persist @observable error

  constructor(args) {
    this.rootStore = args.rootStore
    this.rootAPI = args.rootAPI
  }

  @action.bound
  addError(error) {
    if (error) {
      if (error && error.description && error.description !== 'Role not found') {
        this.rootStore.alertStore.error({
          title: 'Error!',
          content: error.description,
        })
      }

      if (error.status === 401) {
        this.rootStore.authStore.logout()
      }
    }
  }
}
